import React, {useEffect, useState} from 'react';
import 'antd/dist/antd.css';
import './InputUpload.css';
import {
    Form,
    Upload,
    Modal,
    message
  } from 'antd';

export const InputUpload = ( props )=> {
    const [data, setData] = useState({previewVisible: false,
        previewImage: '',
        previewTitle: '',
        fileList:[],
        maxCount:props.max?props.max:1})
    
    const {handleInputChange, indextp,pregunta, cambioDatos}  = props   

    const handleCancel = () => {
        setData({...data,
            previewVisible: false,
            previewImage: '',
            previewTitle: '',
            fileList:data.fileList
        })
    }

    const getBinaryFiles = (e) => {
        let arrayArchivos = []
        data.fileList && data.fileList.map(el => {
            const reader = new FileReader();
            reader.readAsDataURL(el.originFileObj)

            reader.onload = e => {
                let x=reader.result;
               
                let base= x.split('64,');
                let a = {
                    nombre:el.name,
                    data:base[1],
                }
                arrayArchivos.push(a)
            };
            return null
        })
        handleInputChange(pregunta,indextp,{target:arrayArchivos})
        cambioDatos()
    }

    useEffect(()=>{
        getBinaryFiles()
    }, [data.fileList]) 
    
    useEffect(()=>{
        if (props.cleanUpload===true){
            setData({...data,
                previewVisible: false,
                previewImage: '',
                previewTitle: '',
                fileList:[],
            })
        }        
    }, [props.cleanUpload, data]) 
    
    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });
    }

    const handlePreview = async file => {
        if (!file.url && !file.preview) {
          file.preview = await getBase64(file.originFileObj);
        }
        setData({...data,
          previewImage: file.url || file.preview,
          previewVisible: true,
          previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
          fileList:data.fileList
        });
    };
    
    const  onChangeImg = async ({ fileList }) =>{ 
        if(((fileList[fileList.length-1] && fileList[fileList.length-1].size) && ((fileList[fileList.length-1].size/1024/1024))> 8)){
            message.error('El archivo debe ser menor a 8M');
        }else if((fileList[fileList.length-1] && fileList[fileList.length-1].type === 'application/x-msdownload') || (fileList[fileList.length-1] && fileList[fileList.length-1].type === '')){
            message.error('El formato del archivo no es permitido');
        }else{
            setData({...data,fileList})
        }   
    }

    const iconRender = (file) => {
        const isTXT = file.type === 'text/plain'
        const isPDF = file.type === 'application/pdf' 
        const isCSV = file.type === 'text/csv'
        const isXLSX = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        const isPWP = file.type === 'application/pdf'
        const isWORD = file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        const isZIP = file.type === 'application/x-zip-compressed'
        const isMP4 = file.type === 'video/mp4'
        if(isTXT){
            return (<i className="fas inpuload fa-file-alt fa-2xl justify-content-center"/>)
        }
        if(isPDF){
            return (<i className="fas inpuload fa-file-pdf fa-2xl justify-content-center"/>)
        }
        if(isXLSX){
            return (<i className="fas inpuload fa-file-excel fa-2xl justify-content-center"/>)
        }
        if(isPWP){
            return (<i className="fas inpuload fa-file-powerpoint fa-2xl justify-content-center"/>)
        }
        if(isWORD){
            return (<i className="fas inpuload fa-file-word fa-2xl justify-content-center"/>)
        }
        if(isCSV){
            return (<i className="fas inpuload fa-file-csv fa-2xl justify-content-center"/>)
        }
        if(isZIP){
            return (<i className="fas inpuload fa-file-archive fa-2xl justify-content-center"/>)
        }
        if(isMP4){
            return (<i className="fas inpuload fa-file-video fa-2xl"/>)
        }
        if(!isTXT && !isZIP && !isCSV && !isWORD && !isPWP && !isXLSX && !isPDF && !isMP4){
            return (<i className="fas inpuload fa-file-alt fa-2xl justify-content-center"/>)
        }
    }

    const beforeUpload = (file) => {      
        return false;
    }

    return (
        <>
            <Form.Item  >
                <Upload fileList={data.fileList} maxCount={data.maxCount} onChange={onChangeImg}  onPreview={handlePreview} iconRender={iconRender}  beforeUpload={beforeUpload} uploading={false} listType="picture-card" >
                {data.fileList.length < data.maxCount &&  <div> <span><i className="fas fa-paperclip"></i> Adjuntar</span> </div>}  
                </Upload>
                <Modal visible={data.previewVisible} title={data.previewTitle} footer={null} onCancel={handleCancel}>
                    <img alt="example" style={{ width: '100%' }} src={data.previewImage} />
                </Modal>
            </Form.Item>
        </>
    )
}