import React, {useEffect, useState} from 'react';
import { MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';
import 'antd/dist/antd.css';
import './FormDinamic.css';
import {
    Form,
    Select,
    Button,
    Input,
    DatePicker, Space, TimePicker,
    ConfigProvider 
} from 'antd';

import moment from 'moment';

import Spinner from '../base/spiner.js';

import Swal from 'sweetalert2';

import Call from '../../config/Call';

import es_ES from 'antd/lib/locale/es_ES';
import {Firma} from './Firma';

import {useForm} from './useFormDinamic';
import {InputUpload} from './InputUpload';

export const FormDinamic = (props)=> {
    const tarea = props.tarea_id? props.tarea_id:null
    const data = props.formData
    const showButtonEnviar = props && props.showButtonEnviar? props.showButtonEnviar :false
    const showButtonLimpiar = props && props.showButtonLimpiar? props.showButtonLimpiar :false
    const [formData, setFormData] = useState(data);
    const [cleanUpload, setCleanUpload] = useState(false);
    const [cleanFirma, setcleanFirma] = useState(false);
    const [sp, setSp]= useState(false)
    const [preguntas, handleInputChange, handleInputClear, handleAddClick, handleRemoveClick, handleInputClearAllInputs, setValues] = useForm(data.pregunta_formulario);
    useEffect(() => {    
        setValues(data.pregunta_formulario)
        setFormData(props.formData)
    }, [data, setValues,handleInputChange, setFormData]);

    useEffect(() => { 
        if (props && props.formularioRef){
            props.formularioRef.current = {ValidForm:ValidForm,RecopileDataForm:RecopileDataForm }  
        }
    }, []);

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      })

    const carga =(activar)=> {
        if (activar) {
          setSp(true)
        } else {
          setSp(false)
        }
    };

    const handleInputDependiente = async(pregunta, option, {target}) =>{
        let select_dependientes = pregunta.select_dependientes
        
        let opcion = pregunta.tipo_preguntas_pregunta[option].id

        for (let prgta in select_dependientes){
            let id_pregunta =  select_dependientes[prgta].id
            const res = await Call('GET', '/api/formularios/pregunta/opciones/filtro/'+id_pregunta+'?opcion='+opcion, true, null, false)
            data.pregunta_formulario.forEach((element, i) => {
                if (element.id===id_pregunta){
                    data.pregunta_formulario[i].tipo_preguntas_pregunta = res.data.data
                    data.pregunta_formulario[i].value = null
                    for(let it in data.pregunta_formulario[i].tipo_preguntas_pregunta){
                        if(data.pregunta_formulario[i].tipo_preguntas_pregunta[it].is_selected === true){
                            data.pregunta_formulario[i].value = data.pregunta_formulario[i].tipo_preguntas_pregunta[it].valor_opcion
                            break
                        }
                    }
                    setValues([...data.pregunta_formulario])
                }
            });
        }
    }

    
    const RecopileDataForm = () => {
        let respuesta = []
        let inputs_types = ['text','number','textarea']
        let datepickers_type = ['datepicker','datetimepicker','timepicker']
        let checkbox_type = ['checkbox only','checkbox many']
        preguntas.map((pregunta, indexP)=>{
            pregunta.tipo_preguntas_pregunta.map((tipo_pregunta, indexTP)=>{
                let rp = null
                if (inputs_types.includes(pregunta.tipo_input) || datepickers_type.includes(pregunta.tipo_input) || pregunta.tipo_input === 'filefield' || pregunta.tipo_input === 'firma' ){
                    if (inputs_types.includes(pregunta.tipo_input) && pregunta.dinamic_input===true){
                        rp = {
                            id_pregunta:pregunta.id,
                            id_opcion:tipo_pregunta.id,
                            respuesta:tipo_pregunta.valor_opcion,
                            id_clone:tipo_pregunta.id_clone?tipo_pregunta.id_clone:tipo_pregunta.id,
                        }
                    }else{
                        rp = {
                            id_pregunta:pregunta.id,
                            id_opcion:tipo_pregunta.id,
                            respuesta:tipo_pregunta.valor_opcion
                        }
                    }
                    
                    respuesta.push(rp)
                }else if(pregunta.tipo_input==='select' && tipo_pregunta.is_selected === true){
                    if (pregunta.origen_data==='querysql' && pregunta.origen_data_sql===true) {
                        rp = {
                            id_pregunta:pregunta.id,
                            id_opcion:tipo_pregunta.id,
                            respuesta:tipo_pregunta.valor_opcion,
                            is_selected:tipo_pregunta.is_selected,
                            peso_respuesta:tipo_pregunta.peso_respuesta
                        }

                    }else{
                        rp = {
                            id_pregunta:pregunta.id,
                            id_opcion:tipo_pregunta.id,
                            respuesta:tipo_pregunta.valor_opcion,
                            is_selected:tipo_pregunta.is_selected
                        }
                    }
                    
                    respuesta.push(rp)

                }else if(checkbox_type.includes(pregunta.tipo_input)){
                    if (pregunta.origen_data==='querysql' && pregunta.origen_data_sql===true) {
                        rp = {
                            id_pregunta:pregunta.id,
                            id_opcion:tipo_pregunta.id,
                            respuesta:tipo_pregunta.valor_opcion,
                            is_checked:tipo_pregunta.is_checked,
                            peso_respuesta:tipo_pregunta.peso_respuesta
                        }

                    }else{ 
                        rp = {
                            id_pregunta:pregunta.id,
                            id_opcion:tipo_pregunta.id,
                            respuesta:tipo_pregunta.valor_opcion,
                            is_checked:tipo_pregunta.is_checked
                        }
                    
                    }
                    respuesta.push(rp)
                }

                return null
            })
            return null
        })
        if(props.setPreguntas){
            props.setPreguntas(respuesta)
        }
        return respuesta
    }

    const ClearForm = ()=>{
        handleInputClearAllInputs(preguntas)
        setCleanUpload(true)
        setcleanFirma(true)
        setInterval(() => {
            setCleanUpload(false)
            setcleanFirma(false)
        }, 1000);
       
    }


    const ValidForm = ()=>{
        let isValid = true
        let inputs_types = ['text','number','textarea']
        let datepickers_type = ['datepicker','datetimepicker','timepicker']
        let checkbox_type = ['checkbox only','checkbox many']
        // || datepickers_type.includes(pregunta.tipo_input) || pregunta.tipo_input === 'filefield' || pregunta.tipo_input === 'firma'
        preguntas.map((pregunta, indexP)=>{
            if (inputs_types.includes(pregunta.tipo_input) || datepickers_type.includes(pregunta.tipo_input) || pregunta.tipo_input === 'firma' ) {
                pregunta.tipo_preguntas_pregunta.map((tipo_pregunta, indexTP)=>{
                    if ( tipo_pregunta.requerido===true && (tipo_pregunta.valor_opcion ==="" || tipo_pregunta.valor_opcion ===undefined || tipo_pregunta.valor_opcion === null)){
                        pregunta.tipo_preguntas_pregunta[indexTP].alert = true
                        preguntas.map(el => (el.id !== pregunta.id ? {...el, pregunta} : el))
                        setValues([...preguntas]);
                        isValid = false
                    }
                    return null
                })
            }else if(checkbox_type.includes(pregunta.tipo_input)){
                let bandera = false
                pregunta.tipo_preguntas_pregunta.map((tipo_pregunta, indexTP)=>{
                    if ( (pregunta.requerido===true && tipo_pregunta.is_checked === true)||(pregunta.requerido===false)){
                        bandera =  true
                    }
                    return null
                })
                if (bandera=== false){
                    pregunta.alert = true
                    preguntas.map(el => (el.id !== pregunta.id ? {...el, pregunta} : el))
                    setValues([...preguntas]);
                    isValid = false
                }
            }else if (pregunta.tipo_input === 'filefield'){
                pregunta.tipo_preguntas_pregunta.map((tipo_pregunta, indexTP)=>{
                    if ( tipo_pregunta.requerido===true && (tipo_pregunta.valor_opcion ==="" || tipo_pregunta.valor_opcion ===undefined || tipo_pregunta.valor_opcion === null || tipo_pregunta.valor_opcion.length === 0)){
                        pregunta.tipo_preguntas_pregunta[indexTP].alert = true
                        preguntas.map(el => (el.id !== pregunta.id ? {...el, pregunta} : el))
                        setValues([...preguntas]);
                        isValid = false
                    }
                    return null
                })
            }else if( pregunta.tipo_input === 'select'){
                let bandera = false
                pregunta.tipo_preguntas_pregunta.map((tipo_pregunta, indexTP)=>{
                    if ((pregunta.requerido===true && tipo_pregunta.is_selected === true)||(pregunta.requerido===false)){
                        bandera =  true
                    }
                    return null
                })
                if (bandera === false){
                    pregunta.alert = true
                    preguntas.map(el => (el.id !== pregunta.id ? {...el, pregunta} : el))
                    setValues([...preguntas]);
                    isValid = false
                }
            }
            return null
        })
        if (isValid===false){
            Toast.fire({
                title:'Faltan campos por completar en el formulario',
                icon: 'error'
            })
        }
        return isValid
    }

    const SendDataForm = async  (e) => {
        carga(true)
        e.preventDefault()
        if (ValidForm()){
            let respuestas = RecopileDataForm()
            let sendData  = {
                formulario:data.id,
                tarea:tarea, 
                respuestas:respuestas
            }
            try{
                const res = await Call('PUT', '/api/formularios/responder/' + data.id, true, sendData, false)  
 
                if(res.data.data !== null){
                        if (res.data.code===1){
                            Swal.fire({
                                icon: `${((data.tipo_formulario === 'cuantitativo' ) && res.data.data.aprobado) ? 'success': ((data.tipo_formulario === 'cuantitativo') && !res.data.data.aprobado ? 'error' : 'success') }`,
                                title: `${data.tipo_formulario === 'cualitativo'?'Formulario enviado' : ((data.tipo_formulario === 'cuantitativo') ? (res.data.data.aprobado ? 'Estado: Aprobado' : 'Estado: No aprobado') : '')}`,
                                text: '',
                                showDenyButton: ((data.tipo_formulario === 'cuantitativo' && !res.data.data.formulario.is_reply && !res.data.data.aprobado && res.data.data.formulario.respuestas_usuario_ilimitadas) ? true : ((data.tipo_formulario === 'cuantitativo' &&  !res.data.data.formulario.is_reply && !res.data.data.aprobado && !res.data.data.formulario.respuestas_usuario_ilimitadas) ?false: true)),
                                showCancelButton: false,
                                showConfirmButton: (data.tipo_formulario === 'cuantitativo' ? ((!res.data.data.formulario.is_reply &&!res.data.data.aprobado && !res.data.data.formulario.respuestas_usuario_ilimitadas) ? true : false) : false),
                                confirmButtonText: 'Continuar aquí',
                                denyButtonText: `Volver a formularios`,
                              }).then((result) => {
                                /* Read more about isConfirmed, isDenied below */
                                if (result.isConfirmed) {
                                    ClearForm(e) 
                                    props.setPreguntas([])
                                } else if (result.isDenied) {
                                    if(!props.url){
                                        window.location='/formularios'
                                    }
                                }
                              })
                        }else{
                            Swal.fire({
                                icon: `${((data.tipo_formulario === 'cuantitativo' ) && res.data.data.aprobado) ? 'success': ((data.tipo_formulario === 'cuantitativo') && !res.data.data.aprobado ? 'error' : 'success') }`,
                                title: `${data.tipo_formulario === 'cualitativo'?'Formulario enviado' : ((data.tipo_formulario === 'cuantitativo') ? (res.data.data.aprobado ? 'Estado: Aprobado' : 'Estado: No aprobado') : '')}`,
                                text: '',
                                showDenyButton: ((data.tipo_formulario === 'cuantitativo' && !res.data.data.formulario.is_reply && !res.data.data.aprobado && res.data.data.formulario.respuestas_usuario_ilimitadas) ? true : ((data.tipo_formulario === 'cuantitativo' &&  !res.data.data.formulario.is_reply && !res.data.data.aprobado && !res.data.data.formulario.respuestas_usuario_ilimitadas) ?false: true)),
                                showCancelButton: false,
                                showConfirmButton: (data.tipo_formulario === 'cuantitativo' ? ((!res.data.data.formulario.is_reply &&!res.data.data.aprobado && !res.data.data.formulario.respuestas_usuario_ilimitadas) ? true : false) : false),
                                confirmButtonText: 'Continuar aquí',
                                denyButtonText: `Volver a formularios`,
                              }).then((result) => {
                                /* Read more about isConfirmed, isDenied below */
                                if (result.isConfirmed) {
                                    ClearForm(e) 
                                    props.setPreguntas([])
                                } else if (result.isDenied) {
                                    if(!props.url){
                                        window.location='/formularios'
                                    }
                                }
                              })
                              
                        }
            
                        carga(false)
                        // if(props.url === 'tareas'){
                        //     
                        //     window.location='/tareas'
                        // }
                } else {
                    Toast.fire({
                        title:"Ha ocurrido un problema contacte con el administrador",
                        icon: 'error'
                    })
                    carga(false) 
                } 
            } catch (error) {
                Toast.fire({
                    title:"Ha ocurrido un problema contacte con el administrador",
                    icon: 'error'
                })
                carga(false) 
            }
        }else{
            carga(false)
        //    window.location='/home'
        }
    }
    
    const { Option, OptGroup } = Select;
    const cambioDatos =(uno, dos, tres)=>{
        // props.setPreguntas(uno)
        RecopileDataForm()
    }
    const atras =()=>{
        window.location='/formularios'
    }
    return (
        <> { sp ? <Spinner /> : ''}
            <div className="col-sm-12 col-md-8 col-lg-8 p-0 m-auto">
                <div className="card cabecera">
                    {
                        props && !props.url ?
                        <div className='p-2'><i className="fas fa-long-arrow-alt-left fs-25 text-danger cursor-pointer" onClick={atras}></i></div>
                        :
                        ''
                    }
                    
                    <div className="card-header titulo justify-content-start">
                        <h4 className='fs-20'>{formData.titulo}</h4>
                    </div>
                    <div className="card-body descripcion d-block m-regular">
                        <div>
                            <div className='16' style={{fontWeight:'regular'}}  dangerouslySetInnerHTML={{__html:formData.descripcion}} />
                        </div>
                    <div>
                <form id="form"   name="dynamic_form_nest_item" className="needs-validation">
                <div className="mb-1 p-0">
                {preguntas.map((pregunta, key) => {
                    let prgtaHtml;
                    let input;
                    let inputs_types = ['text','number']
                    let datepickers_type = ['datepicker','datetimepicker','timepicker']
                    if (pregunta.tipo_input==='checkbox only' && pregunta.visible===true){
                        input = (
                            <div className="selectgroup selectgroup-pills ant-row ant-form-item w-100 ">
                                {pregunta.tipo_preguntas_pregunta.map((tipo_pregunta, indextp)=>{
                                    return (
                                    <label key={indextp} className="selectgroup-item m-regular">
                                        <div className='todo'>
                                        <input className="selectgroup-input"  onChange={(e) => {handleInputChange(pregunta, indextp, e)} } type="radio" name={pregunta.id}  id={tipo_pregunta.id} value={tipo_pregunta.valor_opcion} checked={tipo_pregunta.is_checked}/>
                                        <span className="selectgroup-button-icon selectgroup-button">{tipo_pregunta.valor_opcion}</span> 
                                        </div>
                                    </label>
                                    )
                                })}
                                {pregunta.alert && pregunta.alert ===true? <label className='ant-form-item-explain-error'>Debe seleccionar una opcion</label>:''}
                            </div>
                        )
                    }else if (pregunta.tipo_input==='checkbox many' && pregunta.visible===true){
                            input = (
                                <div key={key} className="selectgroup selectgroup-pills ant-row ant-form-item w-100">
                                    {pregunta.tipo_preguntas_pregunta.map((tipo_pregunta, indextp)=>{
                                        return (
                                        <label key={indextp} className="">
                                            <input className=""  onChange={(e) => {handleInputChange(pregunta, indextp, e) ; cambioDatos()}} type="checkbox" name={pregunta.id}  id={tipo_pregunta.id} value={tipo_pregunta.valor_opcion}  checked={tipo_pregunta.is_checked}/>
                                            <span className="selectgroup-button-icon">{tipo_pregunta.valor_opcion}</span>
                                        </label>
                                        )
                                    })}
                                    {pregunta.alert && pregunta.alert ===true? <label className='ant-form-item-explain-error'>Debe seleccionar al menos una opcion</label>:''}
                                </div>
                            )
                    }else if (pregunta.tipo_input==='textarea' && pregunta.visible===true){
                            input = (
                                <div key={key} className="mb-0 ant-form-item w-100">
                                    {pregunta.tipo_preguntas_pregunta.map((tipo_pregunta, indextp)=>{
                                        return (
                                            <Form.Item key={indextp}  rules={[{ required: tipo_pregunta.requerido, message: 'Este campo es requerido' }]} >
                                                <Input.TextArea className="w-100" onChange={(e) => {handleInputChange(pregunta, indextp, e); cambioDatos(pregunta, indextp, e)} } id={tipo_pregunta.id} value={tipo_pregunta.valor_opcion}  required={tipo_pregunta.requerido}/>
                                                {tipo_pregunta.alert && tipo_pregunta.alert ===true? <label className='ant-form-item-explain-error'>Este campo es requerido</label>:''}
                                            </Form.Item>
                                        )
                                    })}
                                </div>
                            )
                    
                    }else if(inputs_types.includes(pregunta.tipo_input) && pregunta.visible===true){
                        if (pregunta.dinamic_input===false ){
                            input = (
                                pregunta.tipo_preguntas_pregunta.map((tipo_pregunta, indextp)=>{
                                    let maxLength = tipo_pregunta.length_max?tipo_pregunta.length_max:''
                                    let minLength = tipo_pregunta.length_min?tipo_pregunta.length_min:''
                                    let min = tipo_pregunta.val_min?tipo_pregunta.val_min:''
                                    let max = tipo_pregunta.val_max?tipo_pregunta.val_max:''
                                    let placeholder = tipo_pregunta.placeholder?tipo_pregunta.placeholder:''
                                    // let value = tipo_pregunta.valor_opcion?tipo_pregunta.valor_opcion:undefined
                                    return (
                                        <Form.Item key={indextp} className="ant-row ant-form-item w-100 m-regular" rules={[{ required: tipo_pregunta.requerido, message: 'Este campo es requerido' }]}>                                    
                                            <input 
                                                key={indextp}
                                                className="form-control form-control-sm m-regular"  
                                                id={tipo_pregunta.id}
                                                required={tipo_pregunta.requerido}
                                                maxLength={maxLength}
                                                minLength={minLength}
                                                type={pregunta.tipo_input} 
                                                value={tipo_pregunta.valor_opcion?tipo_pregunta.valor_opcion:""}
                                                min={min}
                                                max={max}
                                                placeholder={placeholder}
                                                onChange={(e) =>{ handleInputChange(pregunta, indextp, e) ; cambioDatos()}}
                                            ></input>
                                              {tipo_pregunta.alert && tipo_pregunta.alert ===true? <label className='ant-form-item-explain-error'>Este campo es requerido</label>:''}
                                        </Form.Item>
                                    )
                                })
                            )
                        }else{
                            input = (<div className='row' key={key}>
                                {pregunta.tipo_preguntas_pregunta.map((tipo_pregunta, indextp)=>{
                                    let maxLength = tipo_pregunta.length_max?tipo_pregunta.length_max:''
                                    let minLength = tipo_pregunta.length_min?tipo_pregunta.length_min:''
                                    let min = tipo_pregunta.val_min?tipo_pregunta.val_min:''
                                    let max = tipo_pregunta.val_max?tipo_pregunta.val_max:''
                                    let placeholder = tipo_pregunta.placeholder?tipo_pregunta.placeholder:''
                                    // let value = tipo_pregunta.valor_opcion?tipo_pregunta.valor_opcion:undefined
                                    return (
                                        <div key={indextp} className='row col-12'>
                                            <Form.Item key={indextp} className="col-12 w-100 m-regular "  rules={[{ required: tipo_pregunta.requerido, message: 'este campo es requerido' }]} >
                                                <input 
                                                    key={indextp}
                                                    className="form-control form-control-sm m-regular"  
                                                    id={tipo_pregunta.id} 
                                                    type={pregunta.tipo_input} 
                                                    required={tipo_pregunta.requerido}
                                                    maxLength={maxLength}
                                                    minLength={minLength}
                                                    value={tipo_pregunta.valor_opcion?tipo_pregunta.valor_opcion:""}
                                                    min={min}
                                                    max={max}
                                                    placeholder={placeholder}
                                                    onChange={(e) => handleInputChange(pregunta, indextp,e)}
                                                ></input>
                                            </Form.Item>
                                            {indextp>=pregunta.min_input?<MinusCircleOutlined  onClick={() => handleRemoveClick(pregunta,indextp)} className='col-2' />:''}
                                        </div>
                                    )
                                })}
                                    {pregunta.tipo_preguntas_pregunta.length<pregunta.max_input?
                                    <Form.Item className="col-12 w-100 ">
                                        <Button type="dashed"  onClick={() => handleAddClick(pregunta)}  block icon={<PlusOutlined />}>
                                            agregar respuesta
                                        </Button>
                                    </Form.Item>:''}
                                </div>
                            )
                        }
                    }else if(pregunta.tipo_input==='filefield' && pregunta.visible===true){
                        input = (
                            pregunta.tipo_preguntas_pregunta.map((tipo_pregunta, indextp)=>{
                                let maxLength = tipo_pregunta.length_max?tipo_pregunta.length_max:''
                                let minLength = tipo_pregunta.length_min?tipo_pregunta.length_min:''
                                let min = tipo_pregunta.val_min?tipo_pregunta.val_min:''
                                let max = tipo_pregunta.val_max?tipo_pregunta.val_max:''
                                let placeholder = tipo_pregunta.placeholder?tipo_pregunta.placeholder:''
                                // let value = tipo_pregunta.valor_opcion?tipo_pregunta.valor_opcion:undefined
                                let props = {
                                    handleInputChange,
                                    indextp,
                                    pregunta,
                                    placeholder,
                                    max,
                                    min,
                                    minLength,
                                    maxLength
                                }
                                return (
                                    <div key={indextp} className="ant-row ant-form-item w-100 m-regular" >
                                    <InputUpload className="col-12 m-regular"
                                    cleanUpload={cleanUpload} max={max} 
                                    handleInputChange={handleInputChange} 
                                    cambioDatos={cambioDatos} 
                                    indextp={indextp} pregunta={pregunta} 
                                    key={indextp} props={props}/>
                                    {tipo_pregunta.alert && tipo_pregunta.alert===true? <label className='ant-form-item-explain-error m-regular'>Debe adjuntar los archivos</label>:''}
                                    </div>
                                )
                            })
                        )
                    }else if (pregunta.tipo_input==='select' && pregunta.visible===true){
                        let disable = pregunta.disabled?true:false
                        input = (<>
                            <div className='ant-form-item w-100 m-regular' >
                            <Select
                            showSearch
                            onChange={(value, options) => {handleInputChange(pregunta, parseInt(options.key), {target:value}); cambioDatos();if(pregunta.select_dependientes.length >0){handleInputDependiente(pregunta, parseInt(options.key), {target:value})};}}
                            style={{ width: '100%' }}
                            className="select2-selection__rendered ant-row ant-form-item w-100 m-regular"
                            optionFilterProp="value"
                            value={disable?pregunta.tipo_preguntas_pregunta[0].valor_opcion:pregunta&&pregunta.value?pregunta.value:null}
                            filterOption={(input, option) =>{
                                if(option.value){
                                return option.value?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false;
                                }else if(option.label){
                                return option.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false;
                                }
                            }}
                            disabled={disable}>
                                <OptGroup   label={pregunta.titulo} required={pregunta.requerido}>
                                    {pregunta.tipo_preguntas_pregunta && pregunta.tipo_preguntas_pregunta.map((tipo_pregunta, indextp)=>{
                                        return (
                                            <Option key={indextp} value={tipo_pregunta.valor_opcion} id={tipo_pregunta} >{tipo_pregunta.valor_opcion}</Option>
                                        )
                                    })}
                                </OptGroup>
                            </Select>
                            {pregunta.alert && pregunta.alert ===true? <label className='ant-form-item-explain-error'>Debe seleccionar una opcion</label>:''}
                            </div>
                            </>
                        )
                    }else if (datepickers_type.includes(pregunta.tipo_input) && pregunta.visible===true){
                        input = (
                            <>
                            <Space  className="ant-form-item w-100 d-block m-regular" >
                                {pregunta.tipo_preguntas_pregunta && pregunta.tipo_preguntas_pregunta.map((tipo_pregunta, indextp)=>{
                                    if (pregunta.tipo_input === 'datetimepicker'){
                                        return (<div className='' key={indextp} >
                                            <DatePicker className='w-100'
                                             value={tipo_pregunta.valor_opcion
                                            ? moment(tipo_pregunta.valor_opcion) : undefined} 
                                             showTime={{ format: 'HH:mm' }} key={indextp} 
                                             onChange={(value, dateString) => {handleInputChange(pregunta, indextp,{target:dateString}); cambioDatos()}} required={pregunta.requerido}/>
                                              {tipo_pregunta.alert && tipo_pregunta.alert ===true? <label className='col ant-form-item-explain-error'>Este campo es requerido</label>:''}
                                             </div>)   
                                    }else if (pregunta.tipo_input === 'datepicker') {
                                        return (<div className='row pl-3 pr-3' key={indextp} >
                                            <DatePicker   className='col-12'
                                            value={tipo_pregunta.valor_opcion
                                            ? moment(tipo_pregunta.valor_opcion) : undefined}   
                                            key={indextp} 
                                            onChange={(value, dateString) => {handleInputChange(pregunta, indextp,{target:dateString}); cambioDatos()}} 
                                            required={pregunta.requerido}/>
                                            {tipo_pregunta.alert && tipo_pregunta.alert ===true? <label className='col ant-form-item-explain-error'>Este campo es requerido</label>:''}
                                            </div>) 
                                    }else{
                                        return (<div className='row pl-3 pr-3' key={indextp} >
                                            <TimePicker   className='col-12 time-form'
                                             value={tipo_pregunta.valor_opcion
                                            ? moment(tipo_pregunta.valor_opcion, "HH:mm") : undefined}   
                                             key={indextp} format="HH:mm" 
                                             onChange={(value, dateString) => {handleInputChange(pregunta, indextp,{target:dateString}); cambioDatos()}} 
                                             required={pregunta.requerido}/>
                                              {tipo_pregunta.alert && tipo_pregunta.alert ===true? <label className='col ant-form-item-explain-error'>Este campo es requerido</label>:''}
                                             </div>
                                        ) 
                                    }
                                })}
                            </Space>
                            </>
                        )
                    }
                    else if (pregunta.tipo_input==='firma' && pregunta.visible===true){
                        input = (<>{pregunta.tipo_preguntas_pregunta && pregunta.tipo_preguntas_pregunta.map((tipo_pregunta, indextp)=>{
                                return (
                                    <div key={indextp} className="ant-row ant-form-item w-100 m-regular" >
                                        <Firma  clear={cleanFirma}  cambioDatos={cambioDatos} indextp={indextp} handleInputChange={handleInputChange} pregunta={pregunta}   />
                                        {tipo_pregunta.alert && tipo_pregunta.alert ===true? <label className='ant-form-item-explain-error'>Es necesaria su firma para completar este formulario</label>:''}
                                    </div>
                                )
                            })}
                            </>
                        )
                    }
                    else if (pregunta.tipo_input==='seccion' && pregunta.visible===true){
                        input = (<>{<div class="card-header">
                                        <h4>JavaScript Validation</h4>
                                    </div>}
                            </>
                        )
                    }
                    
                    prgtaHtml = (
                        <ConfigProvider  key={key} locale={es_ES}>
                            {pregunta.tipo_input!=='seccion'?<div key={key}  className=" ">
                                <label  className="section-title m-regular">{pregunta.titulo}</label>
                                {pregunta.descripcion&& pregunta.descripcion !==null?(<p className='' style={{fontWeight:'regular', fontSize:'15px', fontFamily: "Manrope-regular"}}><div dangerouslySetInnerHTML={{ __html:pregunta.descripcion}} /> </p>):''}
                                {input}
                                </div>:<>
                                        <div className="">
                                        </div><div className="card card-danger" style={{marginBottom:'0px',marginTop:'30px'}}>
                                        </div>
                                        <h5 className='m-regular'>{pregunta.titulo}</h5>
                                        <div  style={{ fontSize:'15px', fontFamily: "Manrope-extralight"}} dangerouslySetInnerHTML={{ __html:pregunta.descripcion}} />
                                        </>}
                        </ConfigProvider>
                    )
                    return prgtaHtml;
                })}
                </div>
                <div className='d-flex justify-content-center flex-wrap pt-4 m-regular'>
                    { showButtonLimpiar?<div className="card-footer text-center py-0 bg-white">
                        <button type="button"  onClick={(e) => {ClearForm(e); props.setPreguntas([])}} className="btn  btn-info-clear px-5 py-2 fs-12">Limpiar</button>
                    </div>:''}
                    { showButtonEnviar?<div className="card-footer text-center py-0 bg-white">
                        <button  onClick={(e) => SendDataForm(e)}   className="btn btn-primary px-5 py-2 fs-12 mt-1">Enviar</button>
                    </div>:''}
                </div>
                </form></div>
                </div>
                </div>
            </div>
        </>
    );
}
