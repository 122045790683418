import  {useState} from 'react';

export const useForm = ( initialState={} )=> {
    const [values, setValues] = useState(initialState);

    const handleInputChange = (pgta, index,  {target} ) => {
        if (pgta.tipo_input==='checkbox only'){
            pgta.tipo_preguntas_pregunta.forEach((element, i) => {
                if (i ===index){
                    pgta.tipo_preguntas_pregunta[i].is_checked = true 
                }else{
                    pgta.tipo_preguntas_pregunta[i].is_checked = false
                }
            });
            pgta.alert = false
            values.map(el => (el.id !== pgta.id ? {...el, pgta} : el))
            setValues([...values]);
        }else if (pgta.tipo_input==='checkbox many'){
            pgta.tipo_preguntas_pregunta[index].is_checked = pgta.tipo_preguntas_pregunta[index].is_checked === true?false:true
            pgta.alert = false
            values.map(el => (el.id !== pgta.id ? {...el, pgta} : el))
            setValues([...values]);
        }else if (pgta.tipo_input==='select'){
            pgta.tipo_preguntas_pregunta.forEach((element, i) => {
                if (i===index){
                    pgta.tipo_preguntas_pregunta[i].is_selected = true
                    pgta.alert = false
                    pgta.value = target
                }else{
                    pgta.tipo_preguntas_pregunta[i].is_selected = false
                }
            });
            values.map(el => (el.id !== pgta.id ? {...el, pgta} : el))
            setValues([...values]);
        }else if  (pgta.tipo_input==='datepicker'||pgta.tipo_input==='datetimepicker'||pgta.tipo_input==='timepicker' ){
            pgta.tipo_preguntas_pregunta[index].valor_opcion = target
            pgta.tipo_preguntas_pregunta[index].alert = false
            values.map(el => (el.id === pgta.id ? {...el, pgta} : el))
            setValues([...values]);
        }else if  (pgta.tipo_input==='firma'){
            pgta.tipo_preguntas_pregunta[index].valor_opcion = target
            pgta.tipo_preguntas_pregunta[index].alert = false
            values.map(el => (el.id === pgta.id ? {...el, pgta} : el))
            setValues([...values]);
        }else if  (pgta.tipo_input==='filefield'){
            pgta.tipo_preguntas_pregunta[index].valor_opcion = target
            pgta.tipo_preguntas_pregunta[index].alert = false
            values.map(el => (el.id === pgta.id ? {...el, pgta} : el))
            setValues([...values]);
        }else{
            pgta.tipo_preguntas_pregunta[index].valor_opcion = target.value
            pgta.tipo_preguntas_pregunta[index].alert = false
            values.map(el => (el.id === pgta.id ? {...el, pgta} : el))
            setValues([...values]);
        }
    }

    const handleInputClearAllInputs = (pgtas) => {
        let inputs_types = ['text','number','textarea']
        let datepickers_type = ['datepicker','datetimepicker','timepicker']
        let checkbox_type = ['checkbox only','checkbox many']
        pgtas.map((pregunta, indexP)=>{
            pregunta.tipo_preguntas_pregunta.map((tipo_pregunta, indexTP)=>{
                pregunta.tipo_preguntas_pregunta[indexTP].alert = false
                pregunta.alert = false
                if (inputs_types.includes(pregunta.tipo_input) || datepickers_type.includes(pregunta.tipo_input) ){
                    pregunta.tipo_preguntas_pregunta[indexTP].valor_opcion = null
                    values.map(el => (el.id !== pregunta.id ? {...el, pregunta} : el))
                    setValues([...values]);
                }else if(checkbox_type.includes(pregunta.tipo_input)  && tipo_pregunta.is_checked === true){
                    pregunta.tipo_preguntas_pregunta[indexTP].is_checked = false
                    values.map(el => (el.id !== pregunta.id ? {...el, pregunta} : el))
                    setValues([...values]);
                }else if(pregunta.tipo_input==='filefield'){
                    pregunta.tipo_preguntas_pregunta[indexTP].valor_opcion = null
                    values.map(el => (el.id !== pregunta.id ? {...el, pregunta} : el))
                    setValues([...values]);
                }else if(pregunta.tipo_input==='select'){
                    pregunta.tipo_preguntas_pregunta[indexTP].is_selected = false
                    pregunta.value = null
                    values.map(el => (el.id !== pregunta.id ? {...el, pregunta} : el))
                    setValues([...values]);
                }
                return null
            })
            return null
        })
    }

    const handleInputClear = () => { setValues(initialState) }

    const handleAddClick = (pgta) => {
        const objnew = Object.assign({}, pgta.tipo_preguntas_pregunta[pgta.tipo_preguntas_pregunta.length-1]);
        objnew.id_clone = objnew.id
        objnew.id = objnew.id+1
        objnew.valor_opcion = ""
        pgta.tipo_preguntas_pregunta.push(objnew)
        values.map(el => (el.id === pgta.id ? {...el, pgta} : el))
        setValues([...values])
    }; 

    const handleRemoveClick = (pgta, index) => {
        pgta.tipo_preguntas_pregunta.splice(index,1)
        values.map(el => (el.id === pgta.id ? {...el, pgta}  : el))
        setValues([...values])
    };

    return [values, handleInputChange, handleInputClear, handleAddClick, handleRemoveClick, handleInputClearAllInputs , setValues];
}