import React, { useState } from 'react';
// import { MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';
import 'antd/dist/antd.css';
import './FormDinamic.css';
import {
    Form,
    Tooltip,
    Image,
    ConfigProvider 
} from 'antd';

// import $ from 'jquery';

import axios from 'axios';

import ProfileCard from '../base/ProfileCard';

import es_ES from 'antd/lib/locale/es_ES';


export const FormViewResponseDetail = (props)=> {
    // const tarea = props.tarea_id? props.tarea_id:null
    const data = props.respuestas_formulario? props.respuestas_formulario:{formData:{titulo:"Cargando...",descripcion:"Cargando..."},preguntas:[]}
//    console.log(props.respuestas_formulario, 'props.respuestas_formulario')
    // const [formData, setFormData] = useState(data);
    const is_tarea_view = props.is_tarea_view?props.is_tarea_view:false
    const [url, setUrl]= useState(null)

    const downloadFile = async (url, nombre)=>{
        
        // let token= decode(localStorage.getItem('authInfo')).token
        axios({
            url:`${url}`,
            method: 'GET',
            responseType: 'blob'
        })
            .then((response) => {
                  const url = window.URL
                        .createObjectURL(new Blob([response.data]));
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download', nombre);
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
            })
    }

    // const ver = async(url) =>{
    //     await setUrl(url)
    //     $('#modalMoreInfo').modal('show') 
    // }

    const SeeImage = () =>{
        return(      
          <div className="modal fade" id="modalMoreInfo" tabIndex="-1" role="dialog" aria-labelledby="modalMoreInfoTitle" aria-hidden="true" style={{background:'transparent'}}>
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
              <div className="modal-content position-relative" style={{maxHeight: '95vh'}}>
                <button type="button" className="close fs-20 p-1 pr-2" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true" style={{color: 'white'}} onClick={()=>setUrl(null)}>&times;</span>
                </button>
                <div className="modal-body py-1 mb-2 px-4 admin-text-g">
                  <div className="--header">
                    <div className="img">
                      <img src={url} alt="img modal" className="img-fluid shadow rounded" style={{maxHeight:"80vh"}}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }

    return (
        <><SeeImage/>
            <div className="col-sm-12 col-md-11 col-lg-11 p-0 m-auto">
                <div className="card cabecera">
                    {is_tarea_view===false? <div className='mt-3'><ProfileCard props={data&& data.usuario}/></div>:''}
                    {/* {console.log(data, 'data profile')} */}
                    <div className="card-header titulo">
                        <h4 className='fs-20'>{data&& data.formulario&& data.formulario.titulo}</h4>
                    </div>
                    <div className="card-body descripcion d-block m-regular">
                        <div>
                            <p className='fs-16' style={{fontWeight:'bold'}}>{data&& data.formulario&& data.formulario.descripcion}</p>
                        </div>
                        <div>
                            <form id="form" name="dynamic_form_nest_item" className="needs-validation">
                                <div className="mb-1 p-0 m-regular">
                                    {data.preguntas_respuestas &&
                                    data.preguntas_respuestas.map((pregunta, key) => {
                                        let prgtaHtml;
                                        let input;
                                        let inputs_types = ['text','number','datepicker','datetimepicker','timepicker']
                                        if(inputs_types.includes(pregunta.tipo_input)){
                                            pregunta.respuestas.map((respuesta, indextp)=>{
                                                input = (
                                                    <Form.Item key={indextp} className="ant-row ant-form-item w-100 m-regular" >
                                                        <input 
                                                            key={indextp}
                                                            className="form-control form-control-sm m-regular"  
                                                            id={respuesta.id}
                                                            value={respuesta.valor ? respuesta.valor : ""}
                                                        disabled></input>
                                                    </Form.Item>
                                                    )
                                                    return null
                                                })
                                            
                                            
                                        }if(pregunta.tipo_input==='select'){
                                            if(pregunta.respuestas.length>0){
                                                pregunta.respuestas.map((respuesta, indextp)=>{
                                                    input = (
                                                        <Form.Item key={indextp} className="ant-row ant-form-item w-100 m-regular" >
                                                            <input 
                                                                key={indextp}
                                                                className="form-control form-control-sm m-regular"  
                                                                id={respuesta.id}
                                                                value={respuesta.is_selected ? respuesta.valor : ""}
                                                            disabled></input>
                                                        </Form.Item>
                                                        )
                                                        return null
                                                    })
                                            }else{
                                                input = (
                                                    <Form.Item key={key} className="ant-row ant-form-item w-100 m-regular" >
                                                 
                                                        <input 
                                                            key={key}
                                                            className="form-control form-control-sm m-regular"  
                                                            
                                                            value=""
                                                        disabled></input>
                                                    </Form.Item>
                                                    )
                                            }
                                        }else if (pregunta.tipo_input==='textarea' ){
                                            pregunta.respuestas.map((respuesta, indextp)=>{
                                                input = (
                                                    <Form.Item key={indextp} className="ant-row ant-form-item w-100 m-regular"  >
                                                        <div className='disabled-texarea m-regular' dangerouslySetInnerHTML={{ __html: respuesta.valor }} />
                                                        {/* <Input.TextArea className="w-100 text-form" value={respuesta.valor} disabled/>                                 */}
                                                    </Form.Item>
                                                    )
                                                    return null
                                                })
                                        }else if (pregunta.tipo_input==='checkbox many' ){
                                            input = (
                                                <div key={key} className="ant-form-item w-100 m-regular" style={{display:'grid'}}>
                                                    {pregunta.respuestas.length > 0 ? pregunta.respuestas.map((respuesta, indextp)=>{
                                                        return (
                                                        <label key={indextp} className="selectgroup-item m-regular">
                                                            <input className="m-regular"  type="checkbox" value={respuesta.valor}  checked={respuesta.is_checked} disabled/>
                                                            <span className="selectgroup-button-icon m-regular">{respuesta.valor}</span>
                                                        </label>)
                                                    })
                                                    :
                                                    <input className="form-control form-control-sm m-regular"  type="text" value={''}  disabled/>
                                                }
                                                </div>
                                            )
                                        }else if (pregunta.tipo_input==='checkbox only'){
                                            input = (
                                                <div key={key} className="selectgroup selectgroup-pills ant-row ant-form-item w-100 m-regular">
                                                    {pregunta.respuestas.length > 0 ? pregunta.respuestas.map((respuesta, indextp)=>{
                                                        return (
                                                        <label key={indextp} className="m-regular">
                                                            <input className="m-regular"  type="radio" value={respuesta.valor}  checked={respuesta.is_checked} disabled/>
                                                            <span className="selectgroup-button-icon m-regular">{respuesta.valor}</span>
                                                        </label>)
                                                    })
                                                    : 
                                                    <input className="form-control form-control-sm m-regular"  type="text" value={''}  disabled/>
                                                }
                                                </div>
                                            )
                                        }else if (pregunta.tipo_input==='firma'){
                                            if (pregunta.respuestas.length>0){
                                                pregunta.respuestas.map((respuesta, indextp)=>{
                                                    input = (
                                                            <label key={indextp} className="selectgroup-item ant-row ant-form-item w-100 m-regular">
                                                                <img className='ant-row ant-form-item img-fluid w-30 m-regular' alt={respuesta.filename}  src={respuesta.file_url} />
                                                            </label>
                                                        )
                                                        return null
                                                    })
                                            }else{
                                                input = (
                                                    <label key={key} className="selectgroup-item ant-row ant-form-item w-100 m-regular">
                                                        No se registró ninguna firma
                                                    </label>
                                                )
                                            }
                                            
                                            
                                            
                                        }else if (pregunta.tipo_input==='filefield'){
                                            if (pregunta.respuestas.length>0){
                                            input = (
                                                <div key={key} className="selectgroup selectgroup-pills ant-row ant-form-item w-100 m-regular">
                                                    {pregunta.respuestas.map((respuesta, indextp)=>(
                                                        <div key={indextp} className="mx-1 d-flex file">
                                                        {(respuesta.filename.toLowerCase().split(".")[1] === "jpeg" || respuesta.filename.toLowerCase().split(".")[1] === "png" || respuesta.filename.toLowerCase().split(".")[1] === "jpg") &&
                                                        //    <img src={respuesta.file_url} alt="" />
                                                        <Image  className='img-fluid p-2' style={{width:'250px'}} src={`${respuesta.file_url}`}/>
                                                        }
                                                        {respuesta.file_type === "application/pdf" && 
                                                        <i className="fas fa-file-pdf fa-2xl justify-content-center p-2 file-pdf"/>
                                                        }
                                                        {
                                                        respuesta.file_type === "video/mp4" &&
                                                            <i className="fas inpuload fa-file-video file-pdf p-2 fa-2xl"/>
                                                        }
                                                        {   respuesta.file_type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
                                                            <i className="fas inpuload fa-file-excel fa-2xl file-pdf p-2 justify-content-center"/>
                                                        }
                                                        {
                                                            respuesta.file_type === 'text/plain' &&
                                                            <i className="fas inpuload fa-file-alt fa-2xl file-pdf p-2 justify-content-center"/>
                                                        }
                                                        {
                                                            ( respuesta.file_type === 'application/vnd.rar' ||respuesta.file_type === 'application/zip') &&
                                                            <i className="fas inpuload fa-file-archive fa-2xl file-pdf p-2 justify-content-center"/>
                                                        }
                                                        {
                                                           ( respuesta.filename.toLowerCase().split(".")[1] !== "jpeg" && respuesta.filename.toLowerCase().split(".")[1] !== "png" && respuesta.filename.toLowerCase().split(".")[1] !== "jpg" && respuesta.file_type !== 'text/plain'
                                                            && respuesta.file_type !== "application/pdf" && respuesta.file_type !== "video/mp4" && respuesta.file_type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" && respuesta.file_type !== 'application/vnd.rar' && respuesta.file_type !== 'application/zip') &&
                                                            <i className="fas inpuload fa-file-alt fa-2xl file-pdf p-2 justify-content-center"/>
                                                        }
                                                        <div className='my-auto'>
                                                        
                                                        <div className='text-file text-file-form' width='50'><Tooltip color={'#aba7a7c2'} title={respuesta.filename}>{respuesta.filename}</Tooltip></div>
                                                            <div className='d-flex'>
                                                                {/* {(respuesta.filename.toLowerCase().split(".")[1]=== "jpeg" || respuesta.filename.toLowerCase().split(".")[1] === "png" || respuesta.filename.toLowerCase().split(".")[1]=== "jpg"  ) &&
                                                                <div className='mx-1 my-auto cursor-pointer' onClick={()=>{ver(respuesta.file_url)}}><i className="fas fa-eye fs-16"></i></div>
                                                                } */}
                                                                <div className='mx-1 my-auto icons-file cursor-pointer'>
                                                                    <i className="fas fa-download cursor-pointer fs-16" onClick={() => downloadFile(respuesta.file_url, respuesta.filename)}></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    ))
                                                    }
                                                </div>
                                            )
                                        }else{
                                            input = (
                                                <label key={key} className="selectgroup-item ant-row ant-form-item w-100">
                                                    No se cargaron imagenes
                                                </label>
                                            )
                                        }
                                        }        
                                        prgtaHtml = (
                                            <ConfigProvider  key={key} locale={es_ES}>
                                                {pregunta.tipo_input!=='seccion'?
                                                <div key={key}  className="form-group m-regular">
                                                    <label  className="section-title m-regular">{pregunta.pregunta}</label>
                                                    {input}
                                                </div>:<>
                                                    <div className="m-regular">
                                                    </div><div className="card card-danger m-regular" style={{marginBottom:'0px',marginTop:'30px'}}>
                                                    </div>
                                                    <h5 className='m-regular'>{pregunta.pregunta}</h5>
                                                    <p className='m-regular' style={{fontWeight:'bold', fontSize:'15px'}}>{pregunta.descripcion}</p>
                                                    </>}
                                            </ConfigProvider>
                                        )
                                        return prgtaHtml;
                                    })}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
