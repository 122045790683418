import React, {useState,useEffect, useRef} from 'react';
import { useParams } from 'react-router';
import ReactDOM from 'react-dom'
import {TitleLayout} from '../../base/TitleLayout'
import Call from '../../../config/Call'
import Spinner from '../../base/spiner.js';
import $ from 'jquery';
import DataTableComp from './dataTableComp'
import moment from 'moment';
import Swal from 'sweetalert2'
import { Image } from 'antd';
import {saveAs} from "file-saver";
import { decode } from '../../../config/crypto';


const Index = (props) =>{
    const {id} = useParams();
    let datat = {
        titulo: "Auditoría de lotes"
    }
    
    const columns_list = [
        {
            name: 'OP',
            selector: row => row.op,
            sortable: true,
            width: "90px" ,
             headerStyle: (selector, id) => {
                return { textAlign: "center" };
            },
        },
        {
            name: 'Referencia',
            selector: row => row.referencia,
            sortable: true,
            width: "140px" ,
            headerStyle: (selector, id) => {
                return { textAlign: "center" };
            },
        },
        {
            name: 'Fecha',
            selector: row => row.updated_at,
            width: "135px",
            sortable: true,
            headerStyle: (selector, id) => {
                return { textAlign: "center" };
            },
        },
        {
            name: 'Descargar',
            width: "130px" ,
            // selector: row => row.ID,
            cell:(row)=><button className='btn-aceptar-info cursor-pointer' onClick={()=>downloadReport(row)} id={row.ID} style={{border: '1px'}}>Descargar</button>
        },
    ]
    
    
    const [init, setInit]= useState(true)
    const [tab, setTab]= useState('registro')
    const [buscar, setBuscar]=useState('') //BUSCAR
    const [time, setTime] =useState('') // TIEMPO DE BUSQUEDA
    const [filterText, setFilterText] = useState('');
    const [filterText2, setFilterText2] = useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [data, setData] = useState([])
    const [editMode, setEditMode]= useState(false)
    const [edit, setEdit]= useState(false)
    const [sp, setSp] = useState(false)
    const [detalleMolderia, setDetalleMolderia] = useState('')
    const [molderiaTemp, setMolderiaTemp] = useState([])
    const [editmolderia, setEditmolderia] = useState({molderia_base:[],contramuestra:[],molderia_antes:[], molderia_despues:[]})
    const [editmolderianombre, setEditmolderianombre] = useState("")

	const [editTallaMolderia, setEditTallaModleria]= useState(null)
    const [where, setWere] = useState('noEdit')
    const [observacionesTxt, setObservacionesTxt] =useState('')
    const [observacionesTxtContramuestra, setObservacionesTxtContramuestra] =useState('')
    const [datosCancelar, setDatosCancelar] = useState([])
 	const [ListFichasAuditadas, setListFichasAuditadas]= useState([])
    const [page, setPage] = useState(1);
    const countPerPage = 10;
    
    useEffect(()=>{
        // busquedaOp ()
        // createTable()
    }, [filterText, data,detalleMolderia, molderiaTemp]) // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(()=>{
       if(id){
        showModleria(id)
       }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    $.DataTable = require('datatables.net')
    
    const tableRef = useRef()
    
    const detalle = async (id)=>{
        var element = document.querySelector('.loader');
        element.parentNode.removeChild(element);
        // document.table.removeChild(node);
        setEdit(true)
    }

    const getListAuditoria = async (q)=>{
        let search = q?q:filterText2
        setSp(true)
        const res = await Call('GET', `/api/produccion/fichaconfeccion/?page=${page}&q=${search}`, true, null, false)
        setListFichasAuditadas(res.data)
        setSp(false)
    }

    const downloadReport = async (row)=>{
        let id_ficha = row.id
        let nombre = row.op
        setSp(true)
        let token= decode(localStorage.getItem('authInfo')).token
        
        let url=process.env.REACT_APP_BACKEND_URL+'/api/produccion/fichaconfeccion/reporte/'+id_ficha+'?export=xls'
        const res2= await Call('GET','api/produccion/fichaconfeccion/reporte/'+id_ficha, true, null, false )
        
        if(res2.data.data.length === 1){
        }else{
            const res= await Call('GET','/api/produccion/fichaconfeccion/reporte/'+id_ficha+'?export=xls', true, null, false )
            if(res.data.code === 2){
                Swal.fire({
                    icon: 'info',
                    title: '',
                    text: 'No hay respuestas en este formulario',
                    confirmButtonText:'Aceptar',
                })
            }else{
                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                })
                    .then(function (response) {
                            return response.blob();
                        }
                    )
                    .then(function(blob) {
                        saveAs(blob, `respuestas ${nombre}.xlsx`);
                    })
                    .catch(error => {
                        //whatever
                    })
                }
        }
        setSp(false)
    }



    const busquedatabla =()=>{
        let b = data.filter(
            item => item.op && item.op.toLowerCase().includes(filterText.toLowerCase()),
        );
        setFilteredItems(b)
        if(filterText === ''){
            setFilteredItems(null)
        }
    }

    useEffect(() => {
        getListAuditoria();
      }, [page]);// eslint-disable-line react-hooks/exhaustive-deps

    

    const dibujar =(datos) =>{
        const table = $(tableRef.current).DataTable(
            {
                    data: datos,
                    columns: [
                        { title: "Op",  data: 'op'},
                        { title: "referencia", data:'referencia'},
                        {
                            title: "Action",
                            createdCell: (td, cellData, rowData, row, col) => {
                                ReactDOM.render(
                                        <button onClick={()=>detalle(rowData)}>detalle</button>
                                    , td);
                                }
                            // render: function ( data, type, row, meta ) {
                            //     return '<div onclick="{'+detalle()+'}">Download</div>';
                            //     }
                          }
                          
                    ],
                    responsive: {
                        details: true
                    },
                //     responsive: {
                //         details: {
                //         renderer: $.fn.dataTable.Responsive.renderer.listHiddenNodes()
                //       }
                //    },
                //    responsive: true,
        // dom: "Bfrtip",
                    destroy: true  // I think some clean up is happening here
            }
        )
        // Extra step to do extra clean-up.
        return function() {
            table.destroy()
        }
    }
    useEffect(() => {
       if(filterText){
        busquedatabla()
       }
        
    },[filterText])// eslint-disable-line react-hooks/exhaustive-deps
    // BUSCAR
    const handlechange =(e) =>{
        setBuscar(e.target.value)
        clearTimeout(time) 
        setTime(setTimeout(() => 
        {
            if(e.target.value !== ''){
                busquedaOp(e.target.value)
            }else{
                setData([])
                setSp(false)
            }
            
        }, 900))   
    }
    const busquedaOp =async (datos)=>{
        setSp(true)
        setInit(false)
        try {
            const res = await Call('GET', `/api/produccion/fichaconfeccion/buscar/${datos.trim()}`, true, null, false)
            let b= []
        
            if(res.data.data !== null){
                b.push(res.data.data)
                setData(b)
                dibujar(b)
            }else{
                setData([]) 
            }
        
            setSp(false)
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: '',
                text: error,
                confirmButtonText:'Aceptar',
            })
            setSp(false)
        }
        
    }
    // -------------DATATABLE -------------
   
    const columns = [
        {
            name: 'OP',
            cell: row => (
                <div>
                    {row.op+' - '+row.referencia}
                </div>
            ),
            filterable: true,
        },
        {	
            name: 'Editar',
            cell: row => (
                <div>
                        <div className='btn-info-guardar btn-editprod b-radius3 cursor-pointer' onClick={()=>clickHandler(row,'Todas')}>Editar</div>
                </div>
            ),
        },
    ];
    const createTable=()=>{
        $(document).ready(function () {
            $('#example').DataTable({
                columns: [
                    { data: 'id' },
                    { data: 'position' },
                    { data: 'office' },
                ],
                data: data,
                responsive: true,
            });
        });
    }
    const showModleria = async (id)=>{
        setSp(true)
        setInit(false)
        const res = await Call('GET', `/api/produccion/fichaconfeccion/buscar/${id.trim()}`, true, null, false)
        clickHandler(res.data.data, 'Todas')
        setData(res.data.data)
        setSp(false)
    }
    const clickHandler =(info, molderia)=>{
        if(info.molderia_base.length !== 0){
            setEditMode(true)
            setWere('noEdit')
            if(molderia==='Todas'){
                if(info){
                
                    for(let i=0; i<info.molderia_base.length; i++){
                        for(let j=0; j<info.molderia_base[i].molderia.length; j++){
                            info.molderia_base[i].molderia[j].valor2=info.molderia_base[i].molderia[j].valor
                        }
                    }
                    for(let i=0; i<info.molderia_despues.length; i++){
                        for(let j=0; j<info.molderia_despues[i].molderia.length; j++){
                            info.molderia_despues[i].molderia[j].valor2=info.molderia_despues[i].molderia[j].valor
                        }
                    }
                    for(let i=0; i<info.contramuestra.length; i++){
                        for(let j=0; j<info.contramuestra[i].molderia.length; j++){
                            info.contramuestra[i].molderia[j].valor2=info.contramuestra[i].molderia[j].valor
                        }
                    }
                    for(let i=0; i<info.molderia_antes.length; i++){
                        for(let j=0; j<info.molderia_antes[i].molderia.length; j++){
                            info.molderia_antes[i].molderia[j].valor2=info.molderia_antes[i].molderia[j].valor
                        }
                    }
                }
                setDetalleMolderia(info)
                setObservacionesTxt(info.observacion_molderia)
                setObservacionesTxtContramuestra(info.observacion_contramuestra)
                editmolderia.molderia_base = info.molderia_base
                setEditmolderia(editmolderia)
                if(info.contramuestra.length === 0){
                    setMolderiaTemp(info.molderia_base)
                    editmolderia.molderia_base = info.molderia_base
                    setEditmolderia(editmolderia)
                }else if(info.contramuestra.length !== 0 && info.molderia_antes.length === 0 && info.molderia_despues.length === 0){
                    setMolderiaTemp(info.contramuestra)
                    editmolderia.contramuestra = info.contramuestra
                    setEditmolderia(editmolderia)
                }else if(info.contramuestra.length !== 0 && info.molderia_antes.length !== 0 && info.molderia_despues.length === 0){
                    setMolderiaTemp(info.molderia_antes)
                    editmolderia.molderia_antes = info.molderia_antes
                    setEditmolderia(editmolderia)
                }else if(info.contramuestra.length !== 0 && info.molderia_antes.length !== 0 && info.molderia_despues.length !== 0){
                    setMolderiaTemp(info.molderia_despues)
                    editmolderia.molderia_despues = info.molderia_despues
                    setEditmolderia(editmolderia)
                }
            }else if (molderia==='Contramuestra'){
                for(let i=0; i<info.contramuestra.length; i++){
                    for(let j=0; j<info.contramuestra[i].molderia.length; j++){
                        info.contramuestra[i].molderia[j].valor2=info.contramuestra[i].molderia[j].valor
                    }
                }
                setMolderiaTemp(info.contramuestra)
                editmolderia.contramuestra = info.contramuestra
                setEditmolderia(editmolderia)
            } else if (molderia==='Antes'){
                for(let i=0; i<info.molderia_antes.length; i++){
                    for(let j=0; j<info.molderia_antes[i].molderia.length; j++){
                        info.molderia_antes[i].molderia[j].valor2=info.molderia_antes[i].molderia[j].valor
                    }
                }
                setMolderiaTemp(info.molderia_antes)
                editmolderia.molderia_antes = info.molderia_antes
                setEditmolderia(editmolderia)
            } else if (molderia==='Despues'){
                for(let i=0; i<info.molderia_despues.length; i++){
                    for(let j=0; j<info.molderia_despues[i].molderia.length; j++){
                        info.molderia_despues[i].molderia[j].valor2=info.molderia_despues[i].molderia[j].valor
                    }
                }
                
                setMolderiaTemp(info.molderia_despues)
                editmolderia.molderia_despues = info.molderia_despues
                setEditmolderia(editmolderia)
            } 
            setBuscar('')
        }else{
            Swal.fire({
                icon: 'info',
                title: '',
                text: 'No hay datos para la OP seleccionada',
                confirmButtonText:'Aceptar',
            })
        }
       
    }
    
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };
    const handletext =(e)=>{
        setFilterText(e.target.value)
        if(e.target.value === ''){
            setFilteredItems(null)
        }
    }
    
    const handletext2 =(e)=>{
        setFilterText2(e.target.value)
        clearTimeout(time) 
        setTime(setTimeout(() => 
        {
            if(e.target.value !== ''){
                getListAuditoria(e.target.value)
                setPage(1)
            }else{
                setFilterText2('')
                getListAuditoria(e.target.value)
                setPage(1)
                setSp(false)
            }
        }, 800))   
        // if(e.target.value === ''){
        //     setFilteredItems(null)
        // }
    }
    
    const handleClear = () => {
        
        if (filterText) {
            setResetPaginationToggle(!resetPaginationToggle);
            setFilterText('');
            setFilterText2('');
            setFilteredItems(null)
        }
    };

    const subHeaderComponentMemo2 = React.useMemo(() => {
        return (
            <>
            <input type="text" className="form-control form-control-sm col col-sm-3" 
                    placeholder="Buscar OP o Referencia" 
                    aria-label="Buscar" 
                    aria-describedby="basic-addon1" 
                    onChange={handletext2} 
                    value={filterText2} 
                />
                { filterText !== '' &&
                    <div type="button" className='pl-2 cursor-pointer' onClick={handleClear} >
                        <i className="fas fa-times-circle"></i>
                    </div>
                }
    
            </>
        );
    
    });
    const [filteredItems, setFilteredItems]= useState(null)
    // --------- FIN DATATABLE -----------------------
    const editarMolderia = async(talla, molderia)=>{
        setEditmolderianombre(molderia)
        setEditTallaModleria(talla)
        setDatosCancelar(detalleMolderia)

    }   
    
    const handlechangeMolderia=(e, id)=>{
        // let datosMolderia = [...molderiaTemp]
        let datosMolderia = []
        setWere('edit')
        if (editmolderianombre==='Contramuestra'){
            if (detalleMolderia.contramuestra.length>0){
                datosMolderia = [...detalleMolderia.contramuestra]
            }else{
                datosMolderia = [...detalleMolderia.molderia_base]
            }
        }else if (editmolderianombre==='Antes'){
            if (detalleMolderia.molderia_antes.length>0){
                datosMolderia = [...detalleMolderia.molderia_antes]
            }else{
                datosMolderia = [...detalleMolderia.molderia_base]
            }
        }else if (editmolderianombre==='Despues'){
            if(detalleMolderia.molderia_despues.length>0){
                datosMolderia = [...detalleMolderia.molderia_despues]
            }else{
                datosMolderia = [...detalleMolderia.molderia_antes]
            }
        }
        let a=[]

        for(let i=0; i<datosMolderia.length; i++){
            for(let j=0; j<datosMolderia[i].molderia.length; j++){
                if(datosMolderia[i].molderia[j].id === id){
                    datosMolderia[i].molderia[j].valor = e.target.value
                    setMolderiaTemp(datosMolderia)
                }
            }
        }
        if (editmolderianombre==='Contramuestra'){
            detalleMolderia.contramuestra = datosMolderia
            editmolderia.contramuestra =datosMolderia
        }else if (editmolderianombre==='Antes'){
            detalleMolderia.molderia_antes = datosMolderia
            editmolderia.molderia_antes =datosMolderia
        }else if (editmolderianombre==='Despues'){
            detalleMolderia.molderia_despues = datosMolderia
            editmolderia.molderia_despues =datosMolderia
        }

        // setDetalleMolderia(detalleMolderia)
        setEditmolderia(editmolderia)
        setDatosCancelar(a)
    }
    const customStyles = {
        rows: {
            style: {
                textAlign:'center',
                marginLeft:'auto',
                justifyContent:'center'
            },
        },
        headCells: {
            style: {
                textAlign:'center',
                marginLeft:'auto',
                justifyContent:'center',
                fontSize:'16px'
            },
        },
        cells: {
            style: {
                textAlign:'center',
                marginLeft:'auto',
                justifyContent:'center',
                fontSize:'16px'
            },
        },
    };

    // funcion para editar solo el elemento que se hace click
    const cargarDatos = async(res, molderia)=>{
        setDetalleMolderia(res.data.data)
        setObservacionesTxt(res.data.data.observacion_molderia)
        setObservacionesTxtContramuestra(res.data.data.observacion_contramuestra)
        setEditTallaModleria(null)
        
        if(molderia==='Todas'){
            for(let i=0; i<res.data.data.molderia_antes.length; i++){
                for(let j=0; j<res.data.data.molderia_antes[i].molderia.length; j++){
                    res.data.data.molderia_antes[i].molderia[j].valor2=res.data.data.molderia_antes[i].molderia[j].valor
                }
            }
            
            for(let i=0; i<res.data.data.molderia_despues.length; i++){
                for(let j=0; j<res.data.data.molderia_despues[i].molderia.length; j++){
                    res.data.data.molderia_despues[i].molderia[j].valor2=res.data.data.molderia_despues[i].molderia[j].valor
                }
            }
            for(let i=0; i<res.data.data.contramuestra.length; i++){
                for(let j=0; j<res.data.data.contramuestra[i].molderia.length; j++){
                    res.data.data.contramuestra[i].molderia[j].valor2=res.data.data.contramuestra[i].molderia[j].valor
                }
            }
            for(let i=0; i<res.data.data.molderia_base.length; i++){
                for(let j=0; j<res.data.data.molderia_base[i].molderia.length; j++){
                    res.data.data.molderia_base[i].molderia[j].valor2=res.data.data.molderia_base[i].molderia[j].valor
                }
            }
            if(res.data.data.contramuestra.length === 0){
                setMolderiaTemp(res.data.data.molderia_base)
                editmolderia.molderia_base = res.data.data.molderia_base
                setEditmolderia(editmolderia)
            }else if(res.data.data.contramuestra.length !== 0 && res.data.data.molderia_antes.length === 0 && res.data.data.molderia_despues.length === 0){
                setMolderiaTemp(res.data.data.contramuestra)
                editmolderia.contramuestra = (res.data.data.contramuestra)
                setEditmolderia(editmolderia)
            }else if(res.data.data.contramuestra.length !== 0 && res.data.data.molderia_antes.length !== 0 && res.data.data.molderia_despues.length === 0){
                setMolderiaTemp(res.data.data.molderia_antes)
                editmolderia.molderia_antes = res.data.data.molderia_antes
                setEditmolderia(editmolderia)
            }else if( res.data.data.contramuestra.length !== 0 && res.data.data.molderia_antes.length !== 0 && res.data.data.molderia_despues.length !== 0){
                setMolderiaTemp(res.data.data.molderia_despues)
                editmolderia.molderia_despues = res.data.data.molderia_despues
                setEditmolderia(editmolderia)
            }
        }else if(molderia==='Contramuestra'){
            for(let i=0; i<res.data.data.contramuestra.length; i++){
                for(let j=0; j<res.data.data.contramuestra[i].molderia.length; j++){
                    res.data.data.contramuestra[i].molderia[j].valor2=res.data.data.contramuestra[i].molderia[j].valor
                }
            }
            setMolderiaTemp(res.data.data.contramuestra)
            editmolderia.contramuestra = res.data.data.contramuestra
            setEditmolderia(editmolderia)
        }else if(molderia==='Antes'){
            for(let i=0; i<res.data.data.molderia_antes.length; i++){
                for(let j=0; j<res.data.data.molderia_antes[i].molderia.length; j++){
                    res.data.data.molderia_antes[i].molderia[j].valor2=res.data.data.molderia_antes[i].molderia[j].valor
                }
            }
            setMolderiaTemp(res.data.data.molderia_antes)
            editmolderia.molderia_antes = res.data.data.molderia_antes
            setEditmolderia(editmolderia)
        }else if(molderia==='Despues'){
            for(let i=0; i<res.data.data.molderia_despues.length; i++){
                for(let j=0; j<res.data.data.molderia_despues[i].molderia.length; j++){
                    res.data.data.molderia_despues[i].molderia[j].valor2=res.data.data.molderia_despues[i].molderia[j].valor
                }
            }
            setMolderiaTemp(res.data.data.molderia_despues)
            editmolderia.molderia_despues = res.data.data.molderia_despues
            setEditmolderia(editmolderia)
        }
    }



    const guardarMolderia = async(tipo)=>{
        let datosfinales = []
        let datos = []
        if(tipo === 'Contramuestra'){
            datos =  editmolderia.contramuestra 
        }
        if(tipo === 'Antes'){
            datos =  editmolderia.molderia_antes 
        }
        if(tipo === 'Despues'){
            datos =  editmolderia.molderia_despues 
        }

        for(let i=0;i<datos.length;i++){
            let molderia = datos[i].molderia
            for(let j=0; j<molderia.length;j++){
                let b={
                    id:molderia[j].id,
                    concepto: molderia[j].concepto,
                    talla: molderia[j].talla,
                    valor: molderia[j].valor
                }
                datosfinales.push(b)
            }
        }
        setSp(true)
        const res = await Call('PUT', '/api/produccion/fichaconfeccion/molderia/actualizar/'+detalleMolderia.id, true, {tipo_molderia: tipo, observacion_molderia: observacionesTxt, data:datosfinales, observacion_contramuestra: observacionesTxtContramuestra }, false )
        if(res.data.code === 1){
            const res = await Call('GET', `/api/produccion/fichaconfeccion/buscar/${detalleMolderia.op.trim()}`, true, null, false)
            if(res.data.code === 1){
                await cargarDatos(res,'Todas')
                Swal.fire({
                    icon: 'success',
                    title: '',
                    text: 'Datos guardados con éxito',
                    confirmButtonText:'Aceptar',
                })
                if(tipo === 'Despues'){
                    setDetalleMolderia('')
                    setMolderiaTemp([])
                    setMolderiaTemp([])
                    setDatosCancelar([])
                    setData([])
                    setInit(true)
                    setEditMode(false)
                    
                }

            }else{
                await cargarDatos(res,'Todas')
                Swal.fire({
                    icon: 'error',
                    title: '',
                    text: `${res.data.message}`,
                    confirmButtonText:'Aceptar',
                })
                setSp(false)
            }
        }
        setSp(false)
    }
    const cancelarEdicion =()=>{
        if(where === 'noEdit'){
            setEditmolderianombre("")
            setEditTallaModleria(null)
        }else{
            let infoOriginal= []
            if(detalleMolderia.molderia_antes.length === 0){
                infoOriginal = detalleMolderia.molderia_base
            }
            if(detalleMolderia.molderia_antes.length !== 0 && detalleMolderia.molderia_despues.length === 0){
                infoOriginal = detalleMolderia.molderia_antes
            }
            if(detalleMolderia.molderia_antes.length !== 0 && detalleMolderia.molderia_despues.length !== 0){
                infoOriginal = detalleMolderia.molderia_despues
            }
            for(let i=0; i<infoOriginal.length; i++){
                if(infoOriginal[i].talla === editTallaMolderia){
                    for(let j=0; j<infoOriginal[i].molderia.length; j++){
                        infoOriginal[i].molderia[j].valor= infoOriginal[i].molderia[j].valor2
                    }
                }
            }
            setEditTallaModleria(null)
            setEditmolderianombre("")
        }
    }
    
    return(
        <div>
             { sp ? <Spinner /> : ''}  {/*LOADER*/}
             <TitleLayout data={datat} clase={"text-left"} /> {/*-----TITULO VISTA------*/}
            <div className='bg-white col-lg-8 col-md-8 col-sm-12 mx-auto p-3' style={{minHeight:'60vh', paddingBottom:'1rem'}}>
                <div className='d-flex'>
                    <span className={`encabezado-tareas fs-16 ${tab === 'registro' ? 'active': '' }`} onClick={()=>setTab('registro')}>
                        Registro
                    </span>
                    <span className={`encabezado-tareas fs-16 ${tab === 'descargas' ? 'active': '' }`} onClick={()=>{setTab('descargas');setBuscar('')}}>
                        Descarga
                    </span>
                </div>
                {/* ------------- AQUI COMIENZA EL CONTENIDO --------------*/}
                <div className='py-3'>
                    {
                        tab === 'registro' ?
                        // ------------ REGISTRO ------------------------
                            <div>
                                {
                                    !editMode ?
                                        <>
                                            <div className="input-group input-group-sm mb-3 p-1 mt-1">
                                                <div className="input-group-prepend">
                                                <span className="input-group-text buscar-tareas" id="basic-addon1"><i className="fas fa-search"></i></span>
                                                </div>
                                                <input type="text" className="form-control form-control-sm border-left-0 text-movil" 
                                                    placeholder="Buscar OP" 
                                                    aria-label="Buscar" 
                                                    aria-describedby="basic-addon1" 
                                                    onChange={handlechange} 
                                                    value={buscar} 
                                                />
                                            </div>
                                            {
                                                (data !== null && !init) &&
                                                <DataTableComp customStyles={customStyles} filterText={filterText} filtered={false} columns={columns} data={filteredItems ? filteredItems: data} responsive={true} paginationComponentOptions={paginationComponentOptions} subHeaderComponent={null} /> 
                                            }
                                            
                                        {/* <table className="display responsive nowrap loader" style={{width:"100%"}} ref={ tableRef }></table> */}
                                        </>
                                    :
                                    <>
                                       {
                                        detalleMolderia&&
                                        <div className='textospan'>
                                            <div onClick={()=>{setEditMode(false); setData([]); setInit(true)} }>
                                                <i className="fas fa-long-arrow-alt-left fs-25 atras-flecha text-danger cursor-pointer"></i>
                                            </div>
                                            <div><span className='bold'>OP:</span> {detalleMolderia.op}</div>
                                            <div><span className='bold'>Referencia:</span> {detalleMolderia.referencia}</div>
                                            <div className='mb-2'><span className='bold'>Observación de confección:</span> 
                                            <div className='detalle-mensaje' dangerouslySetInnerHTML={{ __html: (detalleMolderia.observacion_confeccion.charCodeAt(0) >64 && detalleMolderia.observacion_confeccion.charCodeAt(0)<91) ? detalleMolderia.observacion_confeccion.charAt(0).toUpperCase()+ detalleMolderia.observacion_confeccion.slice(1).toLowerCase() : detalleMolderia.observacion_confeccion.charAt(1).toUpperCase() +detalleMolderia.observacion_confeccion.slice(2).toLowerCase()}} />
                                            </div>
                                            <div className='imagenprod m-auto'>
                                                <Image src={`${detalleMolderia.imagen}`} className="img-fluid" />
                                                {/* <img src={`${detalleMolderia.imagen}`} className="img-fluid border-1" alt="img" /> */}
                                            </div>
                                            <div>
                                                <div className='bold fs-18 pt-4 pb-2'>Molderia de Contramuestra</div>
                                                <div className="accordion" id="accordionExample">
                                                    {   
                                                        (detalleMolderia.contramuestra.length !== 0 ? detalleMolderia.contramuestra:detalleMolderia.molderia_base).map((m, index)=>(
                                                            <div key={index}>
                                                                <div className="accordion-header ac-headerprod" id="headingOne" type="button" data-toggle="collapse" data-target={"#mcontra"+m.talla} aria-expanded="false" aria-controls="collapseOne">
                                                                   Talla {m.talla}
                                                                </div>
                                                                <div id={'mcontra'+m.talla} className={`collapse`} aria-labelledby="headingOne" data-parent="#accordionExample">
                                                                    {detalleMolderia.molderia_antes.length === 0 && (detalleMolderia.contramuestra.length !== 0 ?
                                                                            ((moment((detalleMolderia.contramuestra)[0].molderia[0].created_at).format('DD/MM/YYYY') === moment().format('L')) &&
                                                                                <div>
                                                                                    <div className='text-right cursor-pointer' onClick={()=>editarMolderia(m.talla,'Contramuestra')}>
                                                                                        <i className="fas fa-edit fs-16"></i>
                                                                                    </div>
                                                                                </div>)
                                                                            : 
                                                                            <div>
                                                                                <div className='text-right cursor-pointer' onClick={()=>editarMolderia(m.talla,'Contramuestra')}>
                                                                                    <i className="fas fa-edit fs-16"></i>
                                                                                </div>
                                                                            </div>)

                                                                        }
                                                                    {
                                                                        m.molderia &&
                                                                        m.molderia.map((det, indexdet)=>(
                                                                            <div key={indexdet} className="d-flex my-1">
                                                                                <div className='row w-100'>
                                                                                    <div className='col-7'>
                                                                                        <div className={`bold molderiai ${det.is_changed ? 'color-blue': ''}`}>{det.concepto}: </div> 
                                                                                    </div>
                                                                                    <div className='col-5'>
                                                                                        <div className={`${det.is_changed ? 'color-blue': ''}`}>
                                                                                            { detalleMolderia.molderia_antes.length === 0 &&
                                                                                                (editTallaMolderia === det.talla && editmolderianombre==='Contramuestra' ) ?
                                                                                                    <input type="number" 
                                                                                                        className="input-molderia" 
                                                                                                        value={det.valor}
                                                                                                        onChange={(e)=>handlechangeMolderia(e, det.id)} 
                                                                                                    />
                                                                                                : 
                                                                                                
                                                                                                    det.valor
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                    {   detalleMolderia.molderia_antes.length === 0 &&
                                                                        (editTallaMolderia === m.talla && editmolderianombre === 'Contramuestra' ) ?
                                                                            <div>
                                                                                <div className='px-3 btn-outline-bj bj-form fs-14 my-3 py-1' style={{width:'fit-content'}} onClick={cancelarEdicion}>Cancelar</div>
                                                                            </div>
                                                                        :   ''

                                                                    }
                                                                </div>
                                                                
                                                            </div>
                                                        ))
                                                    }
                                                    <div className='d-block pt-4 pb-3'>
                                                        <div className='bold fs-18 pt-2'>Observaciones</div>
                                                        { ((detalleMolderia.contramuestra.length !== 0 && (detalleMolderia.contramuestra.length > 0 && moment((detalleMolderia.contramuestra)[0].molderia[0].created_at).format('DD/MM/YYYY') === moment().format('L'))) || detalleMolderia.contramuestra.length === 0) ?
                                                            <textarea name="observaciones" id="observaciones" className="form-control" col="30" rows="5" value={data.observacion_contramuestra ==!''?data.observacion_contramuestra:observacionesTxtContramuestra} onChange={(e)=>setObservacionesTxtContramuestra(e.target.value)}style={{height:"100%"}}></textarea>
                                                            :
                                                            <div className='detalle-mensaje' dangerouslySetInnerHTML={{ __html: detalleMolderia.observacion_contramuestra}} />
                                                        }
                                                    </div>
                                                    
                                                    { detalleMolderia.molderia_antes.length === 0 && detalleMolderia.molderia_antes.length === 0 && (moment((detalleMolderia.contramuestra.length !== 0 ? detalleMolderia.contramuestra:detalleMolderia.molderia_base)[0].molderia[0].created_at).format('DD/MM/YYYY') === moment().format('L')) &&
                                                        <>
                                                            <hr />
                                                            <div className="cursor-pointer btn-aceptar-info btnprod" onClick={()=>guardarMolderia('Contramuestra')}> Guardar</div>
                                                        </>
                                                    }
                                                </div>
                                                {/* aqui termina la molderta de contramuestra */}
                                                {/* aqui comienza la molderia antes */}
                                                {
                                                    detalleMolderia.contramuestra.length >0 && detalleMolderia.contramuestra_exist &&
                                                    <div>
                                                        <div className='bold fs-18 pt-4 pb-2'>Molderia Antes</div>
                                                        <div className="accordion" id="accordionExample2">
                                                            {   
                                                                (detalleMolderia.molderia_antes.length !== 0 ? detalleMolderia.molderia_antes:detalleMolderia.molderia_base).map((m, index)=>(
                                                                    <div key={index}>
                                                                        <div className="accordion-header ac-headerprod" id="headingOne" type="button" data-toggle="collapse" data-target={"#mantes"+m.talla} aria-expanded="false" aria-controls="collapseOne">
                                                                        Talla {m.talla}
                                                                        </div>
                                                                        <div id={'mantes'+m.talla} className={`collapse`} aria-labelledby="headingOne" data-parent="#accordionExample2">
                                                                            { detalleMolderia.molderia_despues.length === 0 && ( detalleMolderia.molderia_antes.length !== 0 ? 
                                                                                (moment(detalleMolderia.molderia_antes[0].molderia[0].created_at).format('DD/MM/YYYY') === moment().format('L')) 
                                                                                    && 
                                                                                        <div>
                                                                                            <div className='text-right cursor-pointer' onClick={()=>editarMolderia(m.talla,'Antes')}>
                                                                                                <i className="fas fa-edit fs-16"></i>
                                                                                            </div>
                                                                                        </div>
                                                                                     :  
                                                                                     <div>
                                                                                        <div className='text-right cursor-pointer' onClick={()=>editarMolderia(m.talla,'Antes')}>
                                                                                            <i className="fas fa-edit fs-16"></i>
                                                                                        </div>
                                                                                    </div>)
                                                                                    
                                                                            }
                                                                            {
                                                                                m.molderia &&
                                                                                m.molderia.map((det, indexdet)=>(
                                                                                    <div key={indexdet} className="d-flex my-1">
                                                                                        <div className='row w-100'>
                                                                                            <div className='col-6 '>
                                                                                                <div className={`bold molderiai ${det.is_changed ? 'color-blue': ''}`}>{det.concepto}: </div> 
                                                                                            </div>
                                                                                            <div className='col-6'>
                                                                                                <div className={`${det.is_changed ? 'color-blue': ''}`}>
                                                                                                    {
                                                                                                        (editTallaMolderia === det.talla && editmolderianombre==='Antes') ?
                                                                                                            <input type="number" 
                                                                                                                className="input-molderia w-auto" 
                                                                                                                value={det.valor}
                                                                                                                onChange={(e)=>handlechangeMolderia(e, det.id)} 
                                                                                                            />
                                                                                                        : 
                                                                                                        det.valor
                                                                                                    }     
                                                                                                </div>   
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                ))
                                                                            }
                                                                            {   
                                                                                (editTallaMolderia === m.talla && editmolderianombre === 'Antes' ) ?
                                                                                    <div>
                                                                                        <div className='px-3 btn-outline-bj bj-form fs-14 my-3 py-1' style={{width:'fit-content'}} onClick={cancelarEdicion}>Cancelar</div>
                                                                                    </div>
                                                                                :   ''

                                                                            }
                                                                        </div>
                                                                        
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                        <div className='d-block pt-4 pb-3'>
                                                        <div className='bold fs-18 pt-2'>Observaciones</div>
                                                            { ((detalleMolderia.molderia_despues.length !== 0 && (moment((detalleMolderia.molderia_despues)[0].molderia[0].created_at).format('DD/MM/YYYY') === moment().format('L'))) || detalleMolderia.molderia_despues.length === 0) ?
                                                                <textarea name="observaciones" id="observaciones" className="form-control" col="30" rows="5" value={data.observacion_molderia ==!''?data.observacion_molderia:observacionesTxt} onChange={(e)=>setObservacionesTxt(e.target.value)}style={{height:"100%"}}></textarea>
                                                                :
                                                                <div className='detalle-mensaje' dangerouslySetInnerHTML={{ __html: detalleMolderia.observacion_molderia}} />
                                                            }
                                                        </div>
                                                        { detalleMolderia.molderia_despues.length === 0 && (moment((detalleMolderia.molderia_antes.length !== 0 ? detalleMolderia.molderia_antes:detalleMolderia.molderia_base)[0].molderia[0].created_at).format('DD/MM/YYYY') === moment().format('L')) &&
                                                        <>
                                                            <hr />
                                                            <div className="cursor-pointer btn-aceptar-info btnprod" onClick={()=>guardarMolderia('Antes')}> Guardar</div>
                                                        </>
                                                         }
                                                    </div>
                                                }
                                                {/* aqui termina la molderia antes */}
                                                {/* aqui comienza la molderia despues */}
                                                {
                                                    detalleMolderia.molderia_antes.length >0 && detalleMolderia.molderia_antes_exist &&
                                                    <div>
                                                        <div className='bold fs-18 pt-4 pb-2'>Molderia después</div>
                                                        <div className="accordion" id="accordionExample3">
                                                            {   
                                                                (detalleMolderia.molderia_despues.length !== 0 ? detalleMolderia.molderia_despues:detalleMolderia.molderia_antes).map((m, index)=>(
                                                                    <div key={index}>
                                                                        <div className="accordion-header ac-headerprod" id="headingOne" type="button" data-toggle="collapse" data-target={"#mdespues"+m.talla} aria-expanded="false" aria-controls="collapseOne">
                                                                        Talla {m.talla}
                                                                        </div>
                                                                        <div id={'mdespues'+m.talla} className={`collapse`} aria-labelledby="headingOne" data-parent="#accordionExample3">
                                                                            {detalleMolderia.molderia_despues.length !== 0 ? 
                                                                                (moment(detalleMolderia.molderia_despues[0].molderia[0].created_at).format('DD/MM/YYYY') === moment().format('L')) 
                                                                                    && 
                                                                                        <div>
                                                                                            <div className='text-right cursor-pointer' onClick={()=>editarMolderia(m.talla, 'Despues')}>
                                                                                                <i className="fas fa-edit fs-16"></i>
                                                                                            </div>
                                                                                        </div>
                                                                                     :  
                                                                                     <div>
                                                                                        <div className='text-right cursor-pointer' onClick={()=>editarMolderia(m.talla, 'Despues')}>
                                                                                            <i className="fas fa-edit fs-16"></i>
                                                                                        </div>
                                                                                    </div>
                                                                            }
                                                                            {
                                                                                m.molderia &&
                                                                                m.molderia.map((det, indexdet)=>(
                                                                                    <div key={indexdet} className="d-flex my-1">
                                                                                        <div className='row w-100'>
                                                                                            <div className='col-6 '>
                                                                                                <div className={`bold molderiai ${det.is_changed ? 'color-blue': ''}`}>{det.concepto}: </div> 
                                                                                            </div>
                                                                                            <div className='col-6'>
                                                                                                <div className={`${det.is_changed ? 'color-blue': ''}`}>
                                                                                                    {
                                                                                                        (editTallaMolderia === det.talla && editmolderianombre==='Despues') ?
                                                                                                            <input type="number" 
                                                                                                                className="input-molderia w-auto" 
                                                                                                                value={det.valor}
                                                                                                                onChange={(e)=>handlechangeMolderia(e, det.id)} 
                                                                                                            />
                                                                                                        : 
                                                                                                        det.valor
                                                                                                    }     
                                                                                                </div>   
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                ))
                                                                            }
                                                                            {   
                                                                                (editTallaMolderia === m.talla && editmolderianombre === 'Despues' ) ?
                                                                                    <div>
                                                                                        <div className='px-3 btn-outline-bj bj-form fs-14 my-3 py-1' style={{width:'fit-content'}} onClick={cancelarEdicion}>Cancelar</div>
                                                                                    </div>
                                                                                :   ''

                                                                            }
                                                                        </div>
                                                                        
                                                                    </div>
                                                                ))
                                                            }
                                                           
                                                        </div>
                                                       
                                                        {
                                                                detalleMolderia.molderia_despues.length !== 0 ?
                                                                    ((moment((detalleMolderia.molderia_despues)[0].molderia[0].created_at).format('DD/MM/YYYY') === moment().format('L')) && 
                                                                        <>
                                                                            <hr />
                                                                            <div className="cursor-pointer btn-aceptar-info btnprod" onClick={()=>guardarMolderia('Despues')}> Guardar</div>
                                                                        </>
                                                                    ) 
                                                                :
                                                                <>
                                                                    <hr/>
                                                                    <div className="cursor-pointer btn-aceptar-info btnprod" onClick={()=>guardarMolderia('Despues')}> Guardar</div> 
                                                                </>
                                                            }
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                       }
                                    </>

                                }
                               
                            </div>
                        :
                            //-------------- DESCARGAS --------------------- 
                            <div className='container'>
                                <div className='col-12'>
                                    <DataTableComp customStyles={customStyles} paginationServer={true} paginationPerPage={countPerPage} filterText={filterText2} paginationTotalRows={ListFichasAuditadas.count} paginationComponentOptions={{noRowsPerPage: true, rowsPerPageText: 'Filas por página',rangeSeparatorText: 'de'}} columns={columns_list} data={ListFichasAuditadas.results} onChangePage={page => setPage(page)} responsive={true} subHeaderComponent={subHeaderComponentMemo2}/>
                                </div>
                            </div>
                    }
                </div>
            </div>
            <div className='py-3'>

            </div>
        </div>
    )
}
export default Index;
