import {Switch, Route, Redirect} from 'react-router-dom';


// import {Tareas} from '../components/tareas/indexOld.js';
import {Formularios} from '../components/formularios/index.js';
import ListFormularios from '../components/formularios/listFormularios';
import {Home} from '../components/base/Home';
import {Comunidad} from '../components/base/Comunidad';
import AuditoriaLotes from '../components/produccion/autoriaLotes/index'
import UbicacionInventario from '../components/bodega/ubicacionesInventario/index'
import ConsultarUbicacion from '../components/bodega/consultar/index'
import Citas from '../components/bodega/citas/index.js'
import Mercar from '../components/bodega/mercar/index'
import Abastecimiento from '../components/producto/abastecimiento/index'
import Generar from '../components/producto/predistribucion/index.js'
import EnviosPendientes from '../components/envios/enviosPendientes'
import Traslados from '../components/tiendas/traslados/index'
import Habladores from '../components/tiendas/habladores/index'
import CrearHabladores from '../components/tiendas/habladores/createTalkers'
import VerHablador from '../components/tiendas/habladores/verSpeaker'
import Sondeos from '../components/tiendas/sondeos'
import Presupuestos from '../components/tiendas/presupuestos/index.js';
import PresupuestosVendedores from '../components/tiendas/presupuestos/sellersIndex.js'
import SondeosHistorial from '../components/tiendas/sondeos/historial'
import Ingresos from '../components/gestion-humana/controlIngresos/index.js';
import Page404 from '../components/base/page404';
import Tareas from '../components/tareas/index.js';
import Dashboard from '../components/dashboard/dashboard.js';

const redirectIntranet = (Component) => {

  window.location.replace(process.env.REACT_APP_MENU_URL)
      
}

const AdminRouters = (
  <Switch>
    {/* <Route exact path='/' render={() => redirectIntranet(Home)}/> */}
    <Route exact path='/' component={Dashboard}/>
    <Route exact path='/tareas/:id?' component={Tareas}/>
    {/* <Route exact path='/tareas2/:id?' component={Tareas2}/> */}
    <Route exact path='/formularios/' component={ListFormularios}/>
    <Route exact path='/formularios/:id?' component={Formularios}/>
    <Route exact path='/produccion/auditoria-lotes/:id?' component={AuditoriaLotes}/>
    <Route exact path='/bodega/ubicacion-inventario/:lote?/:cedi?/:ubicacion?/:destino?/:ref?/:tipo?/:undnecesarias?' component={UbicacionInventario}/>
    <Route exact path='/bodega/consultar-ubicacion' component={ConsultarUbicacion}/>
    <Route exact path='/bodega/mercar/:lote?/:tienda?' component={Mercar}/>
    <Route exact path='/bodega/citas-bodega' component={Citas}/>
    <Route exact path='/producto/predistribucion/abastecimiento/' component={Abastecimiento}/>
    <Route exact path='/producto/predistribucion/generar' component={Generar}/>
    <Route exact path='/envios/envios-pendientes' component={EnviosPendientes}/>
    <Route exact path='/tiendas/traslados' component={Traslados}/>
    <Route exact path='/tiendas/habladores' component={Habladores}/>
    <Route exact path='/tiendas/crear-habladores/:id?' component={CrearHabladores}/>
    <Route exact path='/tiendas/ver-hablador' component={VerHablador}/>
    <Route exact path='/tiendas/sondeos' component={Sondeos}/>
    <Route exact path='/tiendas/sondeos/historial' component={SondeosHistorial}/>
    <Route exact path='/tiendas/cargar-presupuestos' component={Presupuestos}/>
    <Route exact path='/tiendas/presupuestos-vendedores' component={PresupuestosVendedores}/>
    <Route exact path='/gh/control-ingresos' component={Ingresos}/>
    <Route exact path='/bigjohn/comunidad' component={Comunidad}/>
    <Route component={Page404}/>

  </Switch>
);



export {
  AdminRouters
}
