import React from 'react';
import { Link, NavLink } from "react-router-dom";

export const UserDropdown = (props) => {
    const { userDetail } = props;
    return (
        <>
            <li className="dropdown">
                <Link to="#" data-toggle="dropdown" className="nav-link dropdown-toggle nav-link-lg nav-link-user">
                    <img src={userDetail.userImg}
                        alt=""
                        className="rounded-circle mr-1"
                    />
                    <div className="d-sm-none d-lg-inline-block">
                        Hola, {userDetail.userName}
                    </div>
                </Link>
                <div className="dropdown-menu dropdown-menu-right">
                <div className="dropdown-title">
                    Estado: Activo
                </div>

                {userDetail.datas.map((data, idata) => {
                        if(data.redirect){ 
                            // return (<Link key={idata} className="dropdown-item has-icon" to={data.link} >  <i className={data.icode} /> {data.title} </Link>) 
                            return (<a key={idata} href={data.link} className="dropdown-item has-icon" > <i className={data.icode} /> <span> {data.title} </span></a>) 
                        }else{
                            return (<NavLink
                                key={idata}
                                to={data.link}
                                className="dropdown-item has-icon"
                              >
                                <i className={data.icode} /> {data.title}
                              </NavLink>) 
                        }
                })}

                <div className="dropdown-divider" />
                <Link
                    to="#"
                    className="dropdown-item has-icon text-danger"
                    onClick={userDetail.logoutFunction}
                >
                    <i className={userDetail.logoutIcon} /> {userDetail.logoutTitle}
                </Link>
                </div>
            </li>

        </>
    );
};