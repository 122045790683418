import React, { useState,useEffect } from "react";
import Call from '../../../config/Call'
import { PdfSpeaker } from "./pdfSpeaker";
import { PDFViewer } from '@react-pdf/renderer'

export default function VerSpeaker() {
  const [lHabaldores, setLHabladores] = useState("");
//   const [movieDetails, setDetails] = useState([]);
  const [show, setHide] = useState(false)
  const [verPDF,serVerPDF] = useState(false)

  useEffect(()=>{
    fetchMovie()

}, [])// eslint-disable-line react-hooks/exhaustive-deps
  const fetchMovie = async e => {
    const res = await Call('GET', '/api/habladores/buscar/', true, null, false)
    // console.log(res.data.data, 'habaldrews')
    setLHabladores(res.data.data)
    // setDetails(res.data.data)
    setHide(true)
    
  };

  return (
    <div className="container pt-4 bg-white mt-4">
      <div className="pdf pb-4 fw-600">
        <div className="p-2">
            <img src="/assets/images/cubo_rojo.ico" width="50" className="img-fluid"/>
        </div>
        {
            lHabaldores&& lHabaldores.map((a, indexa)=>(
                <div key={indexa} className="text-center">
                    <div className="p-3 d-flex justify-content-center">
                        <div style={{height:'fit-content'}}>
                            <div className="fs-14">
                                {a.ref}
                            </div>
                            <div className="fs-14">
                                {a.nombre}
                            </div>
                        </div>
                        <div className="fs-60">
                            <div style={{height:'fit-content', marginTop: '-34px'}}>
                                ${a.precio}
                            </div>
                        </div>
                    </div>
                </div>
            ))
        }
         {
            lHabaldores&& lHabaldores.map((a, indexa)=>(
                <div key={indexa} className="text-center">
                    <div className="p-3 d-flex justify-content-center">
                        <div style={{height:'fit-content'}}>
                            <div className="fs-14">
                                {a.ref}
                            </div>
                            <div className="fs-14">
                                {a.nombre}
                            </div>
                        </div>
                        <div className="fs-60">
                            <div style={{height:'fit-content', marginTop: '-34px'}}>
                                ${a.precio}
                            </div>
                        </div>
                    </div>
                </div>
            ))
        }
         {
            lHabaldores&& lHabaldores.map((a, indexa)=>(
                <div key={indexa} className="text-center">
                    <div className="p-2 d-flex justify-content-center">
                        <div style={{height:'fit-content'}}>
                            <div className="fs-14">
                                {a.ref}
                            </div>
                            <div className="fs-14">
                                {a.nombre}
                            </div>
                        </div>
                        <div className="fs-60">
                            <div style={{height:'fit-content', marginTop: '-34px'}}>
                                ${a.precio}
                            </div>
                        </div>
                    </div>
                </div>
            ))
        }
         {
            lHabaldores&& lHabaldores.map((a, indexa)=>(
                <div key={indexa} className="text-center">
                    <div className="p-2 d-flex justify-content-center">
                        <div style={{height:'fit-content'}}>
                            <div className="fs-14">
                                {a.ref}
                            </div>
                            <div className="fs-14">
                                {a.nombre}
                            </div>
                        </div>
                        <div className="fs-60">
                            <div style={{height:'fit-content', marginTop: '-34px'}}>
                                ${a.precio}
                            </div>
                        </div>
                    </div>
                </div>
            ))
        }
         {
            lHabaldores&& lHabaldores.map((a, indexa)=>(
                <div key={indexa} className="text-center">
                    <div className="p-2 d-flex justify-content-center">
                        <div style={{height:'fit-content'}}>
                            <div className="fs-14">
                                {a.ref}
                            </div>
                            <div className="fs-14">
                                {a.nombre}
                            </div>
                        </div>
                        <div className="fs-60">
                            <div style={{height:'fit-content', marginTop: '-34px'}}>
                                ${a.precio}
                            </div>
                        </div>
                    </div>
                </div>
            ))
        }
         {
            lHabaldores&& lHabaldores.map((a, indexa)=>(
                <div key={indexa} className="text-center">
                    <div className="p-2 d-flex justify-content-center">
                        <div style={{height:'fit-content'}}>
                            <div className="fs-14">
                                {a.ref}
                            </div>
                            <div className="fs-14">
                                {a.nombre}
                            </div>
                        </div>
                        <div className="fs-60">
                            <div style={{height:'fit-content', marginTop: '-34px'}}>
                                ${a.precio}
                            </div>
                        </div>
                    </div>
                </div>
            ))
        }
         {
            lHabaldores&& lHabaldores.map((a, indexa)=>(
                <div key={indexa} className="text-center">
                    <div className="p-2 d-flex justify-content-center">
                        <div style={{height:'fit-content'}}>
                            <div className="fs-14">
                                {a.ref}
                            </div>
                            <div className="fs-14">
                                {a.nombre}
                            </div>
                        </div>
                        <div className="fs-60">
                            <div style={{height:'fit-content', marginTop: '-34px'}}>
                                ${a.precio}
                            </div>
                        </div>
                    </div>
                </div>
            ))
        }
        <div>
        
        </div>
        
        </div>
        {
            show && 
            <div onClick={()=>{serVerPDF(true)}}>ver PDF</div>
        }
        {
            verPDF &&
            <PDFViewer style={{width:'100%', height:'90vh'}}> <PdfSpeaker data={lHabaldores&& lHabaldores} /></PDFViewer>
        }
    </div>
  );
}