import React from 'react';
import '../../../assets/base/css/navbar/navbar.css';
import { Link } from "react-router-dom";

export const ToggleContent = (props) => {
    const { data } = props;
    return (
        <>
            <div className={`dropdown-list-content ${data.listClass}`}>
                {data.content.length>0? data.content.map((content, icontent) => {
                return (
                    <Link
                    key={icontent}
                    to="#"
                    className={`dropdown-item ${
                        content.unreadClass === true ? "dropdown-item-unread" : ""
                    }`}
                    >
                    {content.img ? (
                        <div className="dropdown-item-avatar">
                        <img
                            src={content.img}
                            className="rounded-circle"
                            alt="imag"
                        />
                        {content.online ? <div className="is-online" /> : null}
                        </div>
                    ) : (
                        <div
                        className={`dropdown-item-icon ${content.bgClass} text-white`}
                        >
                        <i className={"fas "+content.iClassname} />
                        </div>
                    )}

                    <div className="dropdown-item-desc">
                        {content.title ? <b>{content.title}</b> : null}
                        <p>{content.description}</p>
                        <div
                        className={`time ${content.textCls ? content.textCls : null}`}
                        >
                        {content.time}
                        </div>
                    </div>
                    </Link>
                );
                }):'cargando...'}
            </div>
        </>
    );
};