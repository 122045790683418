import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router';

import Call from '../../config/Call';

import {FormDinamic} from './FormDinamic';

export const Formularios = (props) => {
    const {id} = useParams();
    const [showMessage, setShowMessage] = useState({
        estado: false,
        texto:''
    })
    
    const initialStateForm = { 
        titulo:"cargando...",
        descripcion:"cargando...",
        pregunta_formulario:[]
    }

    const [values, setValues] = useState(initialStateForm);
    
    useEffect(() => {        
        getDataForm(id)
    }, [id]);
    
    const getDataForm = async (id) =>{
        try{
            const res = await Call('GET', '/api/formularios/'+id, true, false, false)            
            if(res.data.data !== null){
                    // console.log(res.data, 'data form')
                    setValues(res.data.data)
            } else {
                if (res.data.message === "Lo sentimos, no te encuentras activo en el sistema") {
                    // Toast.fire({
                    //   icon: 'error',
                    //   title: 'Lo sentimos, no te encuentras activo en el sistema, contacte con el administrador'
                    // })
                }else if(res.data.code === 2){
                    setShowMessage({
                        estado: true,
                        texto: res.data.message
                    })
                }  
                // console.log(res.data.message)
            } 
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <>
            <div className="section-header">
                <h1>Formularios</h1>
                <div className="section-header-breadcrumb">
                    <div className="breadcrumb-item active"><a href="/formularios">Formularios</a></div>
                </div>
            </div>
            { showMessage &&
                showMessage.estado ?
                    <div className='bg-white section-body' style={{height:'70vh'}}>
                        <div className='p-3 text-emptyg'>
                            <div className='mb-2'>
                                <i className="fas fa-door-closed fs-30"></i>
                            </div>
                            {showMessage.texto.charAt(0).toUpperCase() + showMessage.texto.slice(1).toLowerCase() }
                        </div>
                    </div>

                :
                <>
                    
                    <div className="section-body">
                        
                        <FormDinamic formData={values} showButtonEnviar={true} showButtonLimpiar={true}/>
                    </div>
                </>

            }
            
        </>
    );
};
