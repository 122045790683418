import React, {useState,useContext,useEffect} from 'react';
import {TitleLayout} from '../../base/TitleLayout'
import Spinner from '../../base/spiner';
import Call from '../../../config/Call'
import { Link } from 'react-router-dom'
import DataTableComp from '../../produccion/autoriaLotes/dataTableComp';
import { Select, DatePicker, ConfigProvider } from 'antd';
import es_ES from 'antd/es/locale/es_ES';
import dayjs from 'dayjs';
import moment from 'moment';
import weekday from "dayjs/plugin/weekday"
import localeData from "dayjs/plugin/localeData"
import AuthContext from '../../../contexts/auth/AuthContext';
import DataTableMovil from '../../produccion/autoriaLotes/dataTableCompMovil';

dayjs.extend(weekday)
dayjs.extend(localeData)

const { RangePicker } = DatePicker;


const Historial =(props)=>{

    let datat = {
        titulo: "Historial sondeos"
    }
    const [sp, setSp] = useState(false)
    const [lHistory, setLHistory] = useState([])
    const [lHistory2, setLHistory2] = useState([])
    const [detalle, setDetalle] = useState([])
    const [lGrupos, setlGrupos] = useState([])
    const [lTiendas, setLtiendas] = useState([])
    const [showDetail, setShowDetail] = useState(false)
    const [showFilterPrendas, setShowFilterPrendas]= useState(false)
    const [filtros, setFiltros] = useState({
        grupo:[],
        fecha: [],
        diferencia: false,
        tiendas:[]
    })
    const { Option } = Select;
    const { user } = useContext(AuthContext) // INFORMACION DE USUARIO
    const [filterText, setFilterText] = useState('');
    // const [page, setPage] = useState(1);
    
    useEffect(()=>{
        
    },[filtros])// eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(()=>{
        if(user && (user.nombre_cargo === 'GERENTE DE ZONA' || user.nombre_cargo === 'COMERCIAL CLIENTES CORPORATIVOS' || user.nombre_cargo === 'OPERARIO DE MONITOREO')){
            listarTiendas()
        }
        listGroups()
        ultimos30()
        listHistorial()
        
    },[])// eslint-disable-line react-hooks/exhaustive-deps
    
    
    const ultimos30 = (limpiar) =>{
        
        //fecha actual
        var dt = new Date();
        let fFin=moment(dt).format('YYYY-MM-DD')
        //Fecha 30dias
        dt.setDate( dt.getDate() - 30 );
       
        let fInicio=moment(dt).format('YYYY-MM-DD')
    
            filtros.fecha=[fInicio, fFin]
           setFiltros({
           ...filtros
           })
        filtrarSondeos(limpiar)   
        
    }
    const listarTiendas = async ()=>{
        setSp(true)
        const res= await Call('GET', '/api/sondeos/listaTiendas', true, null, false )
        setLtiendas(res.data.data)
        setSp(false)
    }
    
    const listHistorial =async()=>{
        setSp(true)
        const res = await Call('GET', `/api/sondeos/historial?fecha_inicial=${filtros.fecha[0]}&fecha_final=${filtros.fecha[1]}&grupos[]=[${filtros.grupo}]&tiendas[]=[${filtros.tiendas}]&diferencia=${filtros.diferencia}`, true, null, false)
        if(res.data.code === 1){
            setLHistory(res.data.data)
            setLHistory2(res.data.data)
        }else{

        }
        
        setSp(false)
    }
    const listGroups = async()=>{
        setSp(true)
        const res = await Call('GET', '/api/sondeos/prendas', true, null, false)
        if(res.data.code === 1){
            setlGrupos(res.data.data)
        }
        
        setSp(false)
    }
    const detailSondeo=async(fecha, tienda)=>{
        setSp(true)
        const res = await Call('GET', `/api/sondeos/historial/detalles/${fecha}${(user.nombre_cargo === 'GERENTE DE ZONA' || user.nombre_cargo=== 'COMERCIAL CLIENTES CORPORATIVOS' || user.nombre_cargo === 'OPERARIO DE MONITOREO' ? '?cod_tienda='+tienda : '')}`, true, null, false)
        let nTienda = Object.keys(res.data.data)
        let data=[]
        for(let i=0; i<res.data.data[nTienda[0]].length; i++){
        let nTienda = Object.keys(res.data.data)
            let l={
                nombreTienda: nTienda[0],
                cod_tienda: res.data.data[nTienda[0]][i].cod_tienda,
                completado: res.data.data[nTienda[0]][i].completado,
                conteo: res.data.data[nTienda[0]][i].conteo,
                diferencia: res.data.data[nTienda[0]][i].diferencia,
                existencia: res.data.data[nTienda[0]][i].existencia,
                fecha_creacion: res.data.data[nTienda[0]][i].fecha_creacion,
                id: res.data.data[nTienda[0]][i].id,
                prenda: res.data.data[nTienda[0]][i].prenda,
                usuario: res.data.data[nTienda[0]][i].usuario

            }
            data.push(l)
        }
        
        setDetalle(data)
        setShowDetail(true)
        setSp(false)
    }
    const handleChange=(e, options, name)=>{
    
        if(options.name === 'dif'){
            setFiltros({
                ...filtros,
                diferencia: e,
            })
        } 
        if(name === 'grupos'){
            
            setFiltros({
                ...filtros,
                grupo: e,
            })
        }
        if(name === 'tiendas'){
            
            setFiltros({
                ...filtros,
                tiendas: e,
            })
        }
        
    }
    const handleDate =(value, mode)=>{
       
        if(value === null){
           ultimos30()
        }else{
            setFiltros({
                ...filtros,
                fecha: mode,
            })
        }

    }
    const filtrarSondeos =async(estado)=>{
        
        setSp(true)
        // let buscar={}
        if(estado === 'limpiar'){
            setShowFilterPrendas(false)
            filtros.fecha=[(filtros.fecha.length> 0 ? filtros.fecha[0] : ''),(filtros.fecha.length> 0 ? filtros.fecha[1]: '')]
                filtros.grupo=[]
                filtros.tiendas=[]
                filtros.diferencia=false
            
            setFiltros({
                ...filtros
            })
           
            listHistorial()
        }
        if(estado === 'filtrar'){
            
            listHistorial()
        }
       
        
        // const res= await Call('POST', '/api/sondeos/filtros', true, buscar, false)
        
        if(filtros.grupo.length > 0){
            setShowFilterPrendas(true)
        }else{
            setShowFilterPrendas(false)
        }
        // setLHistory(res.data.data)
        // setLHistory2(res.data.data)
        setSp(false)
    }

    const cleanInfo =()=>{
        setShowFilterPrendas(false); 
        
        setFiltros({
            grupo:[],
            fecha: [],
            diferencia:false,
            tiendas: []
        })
        ultimos30('limpiar')
    }
    const columns=[
        {
            name: 'Fecha',
            cell: row => (
                <div>
                    {row.fecha_creacion}
                </div>
            ),
            
            filterable: true,
        },
        {
            name: 'Existencias',
            cell: row => (
                <div>
                    {row.existencias}
                </div>
            ),
            
            filterable: true,
        },
        {
            name: 'Conteo',
            cell: row => (
                <div>
                    {row.conteos}
                </div>
            ),
            width: '120px',
            filterable: true,
        },
        {
            name: 'Diferencia',
            cell: row => (
                <div>
                    {row.diferencias}
                </div>
            ),
            
            filterable: true,
        },
        {
            name: 'Detalle',
            cell: row => (
                <div>
                    <div onClick={()=>{detailSondeo(row.fecha_creacion, row.cod_tienda)}} className="c-sec cursor-pointer"><i className="fas fa-eye"></i></div>
                </div>
            ),
            
            filterable: true
        },
    ]
    const columnsMov=[
        {
            name: 'Fecha',
            cell: row => (
                <div>
                    {row.fecha_creacion}
                </div>
            ),
            
            filterable: true,
        },
        {
            name: 'Detalle',
            cell: row => (
                <div>
                    <div onClick={()=>{detailSondeo(row.fecha_creacion, row.cod_tienda)}} className="c-sec cursor-pointer"><i className="fas fa-eye"></i></div>
                </div>
            ),
            
            filterable: true
        },
    ]
    const columnsPrendas=[
        {
            name: 'Fecha',
            cell: row => (
                <div>
                    {row.fecha_creacion}
                </div>
            ),
            filterable: true,
        },
        {
            name: 'Prenda',
            cell: row => (
                <div>
                    {row.prenda}
                </div>
            ),
            
            filterable: true,
        },
        {
            name: 'Existencias',
            cell: row => (
                <div>
                    {row.existencia}
                </div>
            ),
            width: '120px',
            filterable: true,
        },
        {
            name: 'Conteo',
            cell: row => (
                <div>
                    {row.conteo}
                </div>
            ),
            width: '120px',
            filterable: true,
        },
        {
            name: 'Diferencia',
            cell: row => (
                <div>
                    {row.diferencia}
                </div>
            ),
            width: '120px',
            filterable: true,
        },
        {
            name: 'Auditado por',
            cell: row => (
                <div className='text-capitalize'>
                    {row.usuario && row.usuario !== '' ? row.usuario.toLowerCase() : '-'}
                </div>
            ),
            filterable: true,
        },
    ]
    const columnsPrendasMov=[
        {
            name: 'Fecha',
            cell: row => (
                <div>
                    {row.fecha_creacion}
                </div>
            ),
            filterable: true,
        },
        {
            name: 'Prenda',
            cell: row => (
                <div>
                    {row.prenda}
                </div>
            ),
            
            filterable: true,
        },
        {
            name: 'Existencias',
            cell: row => (
                <div>
                    {row.existencia}
                </div>
            ),
            width: '120px',
            filterable: true,
        },
        {
            name: 'Conteo',
            cell: row => (
                <div>
                    {row.conteo}
                </div>
            ),
            width: '120px',
            filterable: true,
        },
        {
            name: 'Diferencia',
            cell: row => (
                <div>
                    {row.diferencia}
                </div>
            ),
            width: '120px',
            filterable: true,
        },
        {
            name: 'Auditado por',
            cell: row => (
                <div className='text-capitalize'>
                    {row.usuario && row.usuario !== '' ? row.usuario.toLowerCase(): '-'}
                </div>
            ),
            filterable: true,
        },
    ]
    const columnDetalle =[
        {
            name: 'Código',
            cell: row => (
                <div>
                    {row.id}
                </div>
            ),
            filterable: true,
        },
        {
            name: 'Sublínea',
            cell: row => (
                <div>
                    {row.prenda}
                </div>
            ),
            filterable: true,
        },
        {
            name: 'Existencias',
            cell: row => (
                <div>
                    {row.existencia}
                </div>
            ),
            filterable: true,
        },
        {
            name: 'Físicas',
            cell: row => (
                <div>
                    {row.conteo}
                </div>
            ),
            filterable: true,
        },
        {
            name: 'Diferencia',
            cell: row => (
                <div>
                    {row.diferencia}
                </div>
            ),
            filterable: true,
        },
        {
            name: 'Usuario',
            cell: row => (
                <div className='text-capitalize'>
                    {row.usuario=== ''? '-' : row.usuario.toLowerCase()}
                </div>
            ),
            filterable: true,
        },
        
    ]
    const columnDetalleMov =[
        {
            name: 'Código',
            cell: row => (
                <div>
                    {row.id}
                </div>
            ),
            filterable: true,
        },
        {
            name: 'Sublínea',
            cell: row => (
                <div>
                    {row.prenda}
                </div>
            ),
            filterable: true,
        },        
    ]
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };  
    const conditionalRowStyles= [
        {
          when: row => (row.diferencia <0 || row.diferencia >0),
          style: {
            backgroundColor: '#F89393',
          },
        },
        {
            when: row => (row.usuario === ''),
            style: {
              backgroundColor: '#49505759',
            },
        },
       
    ];
    const handletext =(e)=>{
        
        setFilterText(e.target.value)
        let nuevo = (lHistory2.filter(item => item.fecha_creacion && item.fecha_creacion.includes(e.target.value)))
        setLHistory(nuevo)
        if(e.target.value === ''){
            setLHistory(lHistory2)
        }
    }
    const handleClear = () => {
        
        if (filterText) {
            // setResetPaginationToggle(!resetPaginationToggle);
            setFilterText('');
            setLHistory(lHistory2)
            // setFilteredItems(null)
        }
    };
    const subHeaderComponentMemo2 = React.useMemo(() => {
        return (
            <>
            <input type="text" className="form-control form-control-sm col col-sm-3" 
                    placeholder="Buscar fecha" 
                    aria-label="Buscar" 
                    aria-describedby="basic-addon1" 
                    onChange={handletext} 
                    value={filterText} 
                />
                { filterText !== '' &&
                    <div type="button" className='pl-2 cursor-pointer' 
                        onClick={handleClear} >
                        <i className="fas fa-times-circle"></i>
                    </div>
                }
    
            </>
        );
    
    });
    const expandedComponentDetalleConfirm=(row)=>{
        return(
            lHistory && lHistory.map((a, indexa)=>(
                   ( (a.existencias === row.data.existencias) && (a.fecha_creacion === row.data.fecha_creacion))&&
                    <div className='py-2 d-block' key={indexa}>
                    <div><span className='fw-600'>Existencias: </span> {a.existencias} </div>
                    <div><span className='fw-600'>Conteo: </span>{a.conteos}</div>
                    <div><span className='fw-600'>Diferencia: </span>{a.diferencias}</div>
                </div>
            ))           
        )
    }
    const expandedComponentDetalleConfirm2=(row)=>{
        return(
            lHistory && lHistory.map((a, indexa)=>(
                   ( (a.existencia === row.data.existencia) && (a.fecha_creacion === row.data.fecha_creacion))&&
                    <div className='py-2 d-block' key={indexa}>
                    <div><span className='fw-600'>Existencias: </span> {a.existencia} </div>
                    <div><span className='fw-600'>Conteo: </span>{a.conteo}</div>
                    <div><span className='fw-600'>Diferencia: </span>{a.diferencia}</div>
                    <div><span className='fw-600'>Auditado por: </span><span className='text-capitalize'>{a.usuario === ''? '-': a.usuario.toLowerCase()}</span></div>

                </div>
            ))           
        )
    }
    // MODAL DETALLE MOVIL
    const expandedComponentDetalle=(row)=>{
        return(
            detalle && detalle.map((a, indexa)=>(
                   (a.id === row.data.id) && 
                    <div className='py-2 d-block' key={indexa}>
                    <div><span className='fw-600'>Existencias: </span> {a.existencia} </div>
                    <div><span className='fw-600'>Físicas: </span>{a.conteo}</div>
                    <div><span className='fw-600'>Diferencia: </span>{a.diferencia}</div>
                    <div><span className='fw-600'>Auditado por: </span><span className='text-capitalize'>{a.usuario === ''? '-': a.usuario.toLowerCase()}</span></div>
                </div>
            ))           
        )
    }
    const customStyles ={    
        headCells: {
            style: {
                fontSize: '17px',    
                paddingLeft: '16px',
                paddingRight: '16px',
            },
        },
    }
    
    return(
        <div>
             { sp ? <Spinner /> : ''}  {/*LOADER*/}
             <TitleLayout data={datat} clase={"text-left"}  icon={true} seccion={'sondeos'} /> {/*-----TITULO VISTA------*/}
             <div className='bg-white p-4 col-lg-10 col-md-10 col-sm-12 mx-auto mb-3' >
                {
                    user && (user.nombre_cargo !== 'GERENTE DE ZONA' && user.nombre_cargo !== 'COMERCIAL CLIENTES CORPORATIVOS' && user.nombre_cargo !== 'OPERARIO DE MONITOREO') &&
                    <div className='mb-4 text-right'>
                        <Link to="/tiendas/sondeos" className='btn-buscar cursor-pointer fs-16 mr-0'>Generar sondeo</Link>
                    </div>
                }
                
                {
                    showDetail &&
                    <>
                        <div className="modal show" tabIndex="-1" style={{display:'block'}}>
                            <div className="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable ">
                                <div className="modal-content">
                                <div className="modal-header py-2">
                                    <span className="fs-18 fw-600">Detalle</span>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>{setShowDetail(false)}}>
                                    <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body"  style={{maxHeight: '90vh', overflowY:'auto'}}>
                                    <div className='d-flex justify-content-between'>
                                        <div className='mb-2'><span className='fw-600'>Fecha: </span>{detalle[0].fecha_creacion} </div>
                                        {user && (user.nombre_cargo === 'GERENTE DE ZONA' || user.nombre_cargo === 'COMERCIAL CLIENTES CORPORATIVOS' || user.nombre_cargo === 'OPERARIO DE MONITOREO') && <div className='mb-2 text-capitalize'><span className='fw-600'>Tienda: </span>{detalle[0].nombreTienda.toLowerCase()} </div>}
                                    </div>
                                    <div className='nomenclarura'>
                                            <div className='fs-14 d-flex'>
                                                <div className='point gray' width="40" height="40"></div> No completado
                                            </div>
                                            <div className='fs-14 d-flex'>
                                                <div className='point danger' width="40" height="40"></div> Unidades sobrantes o faltantes
                                            </div>
                                        </div>
                                    <div className='ocultar-movil'>
                                        <DataTableComp
                                            columns={columnDetalle} 
                                            data={detalle && detalle}
                                            paginationComponentOptions={paginationComponentOptions}
                                            paginationPerPage={20}
                                            conditionalRowStyles={conditionalRowStyles}
                                        />
                                    </div>
                                    <div className='mostrar-movil'>
                                        <DataTableMovil  
                                            columns={columnDetalleMov } 
                                            data={detalle && detalle}
                                            expandedComponent={expandedComponentDetalle} 
                                            pagination={true}
                                            paginationComponentOptions={paginationComponentOptions}
                                            conditionalRowStyles={conditionalRowStyles}
                                            paginationPerPage={20}
                                            customStyles={customStyles}
                                        />
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-backdrop fade show"></div> 
                    </>
                }
                <div >
                    <div className='row mb-4'>
                        <div className='col-lg-4 col-md-4 col-sm-12 my-2'>
                        <ConfigProvider locale={es_ES}>
                            <RangePicker 
                                style={{width:'100%'}}
                                name='fecha'
                                allowClear
                                showToday
                                onChange={(value, mode)=>{handleDate(value, mode)}}
                                value={filtros.fecha.length>0 && [dayjs(filtros.fecha[0]), dayjs(filtros.fecha[1])]}
                             />
                        </ConfigProvider>
                        </div>
                        <div className='col-lg-3 col-md-3 col-sm-12 my-2'>
                            <Select
                             style={{width:'100%'}}
                                mode="multiple"
                                className="select-filtros"
                                showSearch
                                placeholder="Selecciona grupos"
                                optionFilterProp="children"
                                value={(filtros.grupo )}
                                name='grupo'
                                onChange={(value, options)=>handleChange(value, options, 'grupos')}
                                filterOption={(input, option) =>{
                                    if(option.children){
                                    return option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false;
                                    }else if(option.label){
                                    return option.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false;
                                    }
                                    }}
                            >
                                {/* <Option value={'Todos'} name="grupos">Todos</Option> */}
                                {
                                    lGrupos &&
                                    lGrupos.map((u, indexu)=>(
                                        <Option key={'u'+indexu} value={u} name="grupos">{u}</Option>
                                    ))
                                }
                            
                            </Select>
                        </div>
                        <div className='col-lg-2 col-md-2 col-sm-12 my-2'>
                        <Select
                            style={{width:'100%'}}
                            className='select-filtros2'
                            value={filtros.diferencia}
                            onChange={handleChange}
                            options={[
                                {
                                value: false,
                                label: 'Todo',
                                name:'dif'
                                },
                                {
                                value: true,
                                label: 'Diferencias',
                                name:'dif'
                                },
                            ]}
                            />
                        </div>
                        {
                            (user && (user.nombre_cargo === 'GERENTE DE ZONA' || user.nombre_cargo === 'COMERCIAL CLIENTES CORPORATIVOS' || user.nombre_cargo === 'OPERARIO DE MONITOREO')) &&
                            <div className='col-lg-3 col-md-3 col-sm-12 my-2'>
                                <Select
                                style={{width:'100%'}}
                                    className="select-filtros"
                                    showSearch
                                    mode="multiple"
                                    placeholder="Tiendas"
                                    optionFilterProp="children"
                                    value={(filtros.tiendas )}
                                    name='tiendas'
                                    onChange={(value, options)=>handleChange(value, options, 'tiendas')}
                                    filterOption={(input, option) =>{
                                        if(option.children){
                                        return option.children[2]?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false;
                                        }else if(option.label){
                                        return option.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false;
                                        }
                                        }}
                                >
                                    {/* <Option value={'Todos'} name="grupos">Todos</Option> */}
                                    {
                                        lTiendas &&
                                        lTiendas.map((u, indexu)=>(
                                            <Option key={indexu} value={u.codigo} name="tiendas">{u.codigo} - {u.nombre} </Option>
                                        ))
                                    }
                                
                                </Select>
                            </div>
                        }
                        <div className='col-lg-2 col-md-3 col-sm-12 d-flex mr-auto pl-1 my-2'>
                            <div className='mx-2 btn-filter fs-16' onClick={()=>filtrarSondeos('filtrar')}>Filtrar</div>
                            <div className='mx-2 pt-1' onClick={cleanInfo}> <i className="fas fa-times fs-18 text-danger cursor-pointer"></i> </div>
                        </div>
                    </div>
                    
                    <div className='ocultar-movil'>
                        <DataTableComp 
                            columns={showFilterPrendas? columnsPrendas : columns} 
                            data={lHistory && lHistory} 
                            paginationComponentOptions={paginationComponentOptions}
                            conditionalRowStyles={conditionalRowStyles}
                            paginationPerPage={20}
                            subHeaderComponent={subHeaderComponentMemo2}
                        />
                    </div>
                    <div className='mostrar-movil'>
                        <DataTableMovil  
                            columns={showFilterPrendas? columnsPrendasMov : columnsMov} 
                            data={lHistory && lHistory} 
                            expandedComponent={showFilterPrendas? expandedComponentDetalleConfirm2 : expandedComponentDetalleConfirm} 
                            paginationComponentOptions={paginationComponentOptions}
                            conditionalRowStyles={conditionalRowStyles}
                            customStyles={customStyles}
                        />
                    </div>
                </div>
               

             </div>
        </div>
    )
}
export default Historial;