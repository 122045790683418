import React, {useState,useEffect} from 'react';
import {TitleLayout} from '../../base/TitleLayout'
import Spinner from '../../base/spiner';
import Call from '../../../config/Call'
import DataTableComp from '../../produccion/autoriaLotes/dataTableComp';
import Swal from 'sweetalert2';
import { pdf, Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { saveAs } from "file-saver";
import logo from '../../../assets/base/images/cubo_rojo.png'
import DataTableMovil from '../../produccion/autoriaLotes/dataTableCompMovil';
import logoMambo from '../../../assets/base/images/logo_mambo.png'


const Habladores = (props)=>{

    let datat = {
        titulo: "Habladores"
    }
    const [sp, setSp] = useState(false)
    const [lHabladores, setLHabladores]= useState([])

    useEffect(()=>{
        listHabladores()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const listHabladores = async()=>{
        setSp(true)
        const res= await Call('GET', '/api/habladores', true, null, false)
        setLHabladores(res.data.data)
        setSp(false)
    }

    const createTalkers = ()=>{
        props.history.push('/tiendas/crear-habladores')
    }
    const deleteSpeaker= async(id)=>{

        Swal.fire({
            title: '¿Está seguro de eliminar este hablador?',
            text: "Esta acción no podrá deshacerse",
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#23b991',
            cancelButtonColor: '#ee4630',
            confirmButtonText: 'Aceptar',
            cancelButtonText:'Cancelar',
            customClass:{
                cancelButton:'btn-cancel-general',
                confirmButton:'btn-cancel-general',
            }
          }).then(async(result) => {
            if (result.isConfirmed) {
                setSp(true)
                const res = await Call('GET', '/api/habladores/eliminar/'+id, true, null, false)
                if(res.data.code === 1){
                    setSp(false)
                    Swal.fire({
                        icon: 'success',
                        title: '',
                        text: 'Hablador eliminado correctamente',
                        confirmButtonText:'Aceptar',
                    })
                    listHabladores()
                }else{
                    Swal.fire({
                        icon: 'info',
                        title: '',
                        text: `${res.data.message}`,
                        confirmButtonText:'Aceptar',
                    })
                }
            }
          })        
    }
    const styles = StyleSheet.create({
        page: {
            backgroundColor: "#ffffff",
            margin:'10px',
        },
        page2: {
            backgroundColor: "#ffffff",
            position:'relative',
            alignContent: 'center',
            alignItems: 'center',
            justifyContent:'center',
            alignSelf: 'center',
        },
        container:{
            textAlign:"left",  
            flexDirection:'row',
            justifyContent:'left', 
            marginBottom:'35px',
            marginTop:'35px',
            fontWeight:'800'
        },
        container2:{
            textAlign:"left",  
            flexDirection:'row',
            justifyContent:'left', 
            marginBottom:'45px',
            marginTop:'45px',
            fontWeight:'800'
        },
        
        image:{
            width:'50px',
            height:'50px',
            textAlign:'right', 
            position:'absolute',
            justifyContent:'flex-start',       
        },
        imageMambo:{
            height:'50px',
            textAlign:'right', 
            position:'absolute',
            justifyContent:'flex-start',       
        },
        imag:{
            width:'50px',
            height:'50px',
            textAlign:'right', 
            position:'absolute',
            justifyContent:'flex-end',
            marginBottom:'-100px',
            bottom:0,     
            right:0,  
        },
        view1:{
            fontFamily:'Helvetica-Bold',
            fontSize:"40px", 
            textAlign: 'right',
            alignContent: 'right',
            justifyContent:'flex-end',
            marginTop:'3%',
            position:'relative',
            fontWeight:'bold'
        },
        border1:{
            border: '2px solid red', 
            padding:'5px', 
            paddingBottom:'35px', 
            marginLeft:'10px',
            marginRight:'10px',
        },
        border2:{
            border: '2px solid black', 
            padding:'5px', 
            paddingBottom:'35px', 
            marginLeft:'10px',
            marginRight:'10px',
        },
        borderMayor1:{
            border: '2px solid red', 
            padding:'5px', 
            paddingBottom:'35px', 
            margin:'5px', 
            height:'95%', 
            width:'95%'
        },
        borderMayor2:{
            border: '2px solid black', 
            padding:'5px', 
            paddingBottom:'35px', 
            margin:'5px', 
            height:'95%', 
            width:'95%'
        }
        
        
    });
    const printSpeaker = async(id)=>{

        const res= await Call('GET', '/api/habladores/imprimir/'+id, true, null, false)
        
        if(res.data.code === 1){
            if(res.data.data.length >=6){
                const blob = await pdf(
                    <Document>
                    <Page style={styles.page} size='A4'>
                        <View style={(lHabladores[0].tienda === "MAMBO QUIMBAYA" || lHabladores[0].tienda === "BIG JOHN MONTERIA") ? styles.borderMayor2 : styles.borderMayor1}>
                            <View style={{justifyContent: 'end', marginBottom:'80px'}}>
                                { lHabladores &&
                                    (lHabladores[0].tienda === "MAMBO QUIMBAYA" || lHabladores[0].tienda === "BIG JOHN MONTERIA") ?
                                        <Image  
                                        source={logoMambo}
                                        style={styles.imageMambo}
                                    />
                                    :
                                        <Image  
                                        source={logo}
                                        style={styles.image}
                                    />
                                }
                                
                          </View>
                        {res.data.data
                            ? res.data.data.map((a, index) => {
                                    return (
                                        <View key={index}  style={styles.container}>
                                            <View style={{width:'68%', marginTop:'-2px',fontWeight:'bold', fontFamily:'Helvetica-Bold'}}>
                                                    <Text style={{width:'90%', fontWeight:'800', marginBottom: '2px', fontFamily:'Helvetica-Bold'}}>{a.referencia}</Text>
                                                    <Text break style={{fontWeight:'800',fontSize:'19px',fontFamily:'Helvetica-Bold',letterSpacing:'-1px',height:'auto', overflowWrap:'break-word'}}>{a.nombre}</Text>
                                            </View>
                                            <View style={{display:'flex', width:'32%'}}>
                                                    <Text style={styles.view1}>${new Intl.NumberFormat('de-DE').format(a.precio)}</Text>
                                            </View>
                                        </View>
                            );
                        })
                    : ""} 
                    
                    </View>
                    </Page>
                </Document>
                  ).toBlob();
                  saveAs(blob, `habladores-${id}`);
            }else{
                const blob = await pdf(
                    <Document>
                        <Page style={styles.page2} size='A4'>
                            <View style={(lHabladores[0].tienda === "MAMBO QUIMBAYA" || lHabladores[0].tienda === "BIG JOHN MONTERIA") ? styles.border2 : styles.border1}>
                                <View style={{justifyContent: 'end', marginBottom:'70px'}}>
                                { lHabladores &&
                                    (lHabladores[0].tienda === "MAMBO QUIMBAYA" || lHabladores[0].tienda === "BIG JOHN MONTERIA") ?
                                        <Image  
                                        source={logoMambo}
                                        style={styles.imageMambo}
                                    />
                                    :
                                        <Image  
                                        source={logo}
                                        style={styles.image}
                                    />
                                }
                                </View>
                            {res.data.data
                                ? res.data.data.map((a, index) => {
                                        return (
                                            <View key={index}  style={styles.container2}>
                                                <View style={{width:'68%', marginTop:'-2px',fontWeight:'bold', fontFamily:'Helvetica-Bold'}}>
                                                        <Text style={{width:'90%', fontWeight:'800', marginBottom: '2px', fontFamily:'Helvetica-Bold'}}>{a.referencia}</Text>
                                                        <Text break style={{fontWeight:'800',fontSize:'19px',fontFamily:'Helvetica-Bold',letterSpacing:'-1px',height:'auto', overflowWrap:'break-word'}}>{a.nombre}</Text>
                                                </View>
                                                <View style={{display:'flex', width:'32%'}}>
                                                        <Text style={styles.view1}>${new Intl.NumberFormat('de-DE').format(a.precio)}</Text>
                                                </View>
                                            </View>
                                );
                            })
                        : ""} 
                        
                        </View>
                        </Page>
                    </Document>
                  ).toBlob();
                  saveAs(blob, `habladores-${id}`);
            }
            
        }        
    }
    const editSpeaker = (id)=>{
        props.history.push('/tiendas/crear-habladores/'+id)
    }
    // INICIO DATATABLE
    const columns =[
        {
            name: 'Id',
            cell: row => (
                <div>
                    {row.id}
                </div>
            ),
            width: '80px',
            filterable: true,
        },
        {
            name: 'Fecha creación',
            cell: row => (
                <div>
                    {row.fecha}
                </div>
            ),
            width: '150px',
            filterable: true,
        },
        {
            name: 'Cantidad',
            cell: row => (
                <div>
                    {row.cantidad}
                </div>
            ),
            width: '90px',
            filterable: true,
        },
        {
            name: 'Usuario',
            cell: row => (
                <div className='text-capitalize'>
                    {row.usuario.toLowerCase()}
                </div>
            ),
            filterable: true,
        },
        {
            name: 'Editar',
            cell: row => (
                <div>
                    <div onClick={()=>{editSpeaker(row.id)}} className="cursor-pointer"><i className="fas fa-edit fs-18"></i></div>
                </div>
            ),
            width: '100px',
            filterable: true,
        },
        {
            name: 'Eliminar',
            cell: row => (
                <div>
                    <div onClick={()=>{deleteSpeaker(row.id)}} className="cursor-pointer"><i className="fas fa-trash-alt text-danger fs-18"></i></div>
                </div>
            ),
            width: '120px',
            filterable: true,
        },
        {
            name: 'Descargar',
            cell: row => (
                <div>
                    <div onClick={()=>{printSpeaker(row.id)}} className="cursor-pointer"><i className="fas fa-print fs-18 c-se"></i></div>
                </div>
            ),
            width: '140px',
            filterable: true,
        },
    ]

    const columnsDetalleMovil =[
        {
            name: 'Fecha creación',
            cell: row => (
                <div>
                    {row.fecha}
                </div>
            ),
            
            filterable: true,
        },
        {
            name: 'Imprimir',
            cell: row => (
                <div>
                    <div onClick={()=>{printSpeaker(row.id)}} className="cursor-pointer"><i className="fas fa-print fs-18 c-se"></i></div>
                </div>
            ),
            width: '100px',
            filterable: true,
        },
    ]
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };   
    const expandedComponentDetalleConfirm=(row)=>{
        return(
            lHabladores && lHabladores.map((a, indexa)=>(
                    a.id === row.data.id &&
                    <div className='py-2 d-block' key={indexa}>
                    <div><span className='fw-600'>Id: </span>{a.id}</div>
                    <div><span className='fw-600'>Cantidad: </span>{a.cantidad}</div>
                    <div><span className='fw-600'>Usuario: </span> {a.usuario} </div>
                    <div className='mt-2 d-flex'>
                        <div onClick={()=>{editSpeaker(row.data.id)}} className="cursor-pointer m-2"><i className="fas fa-edit fs-25"></i></div>
                        <div onClick={()=>{deleteSpeaker(row.data.id)}} className="cursor-pointer m-2"><i className="fas fa-trash-alt text-danger fs-25"></i></div>
                    </div>
                </div>
            ))           
        )
    }
    // FIN DATATABLE
    return(
        <div>
             { sp ? <Spinner /> : ''}  {/*LOADER*/}
             <TitleLayout data={datat} clase={"text-left"} icon={true} seccion={'habladores'} /> {/*-----TITULO VISTA------*/}
             <div className='bg-white col-lg-9 col-md-9 col-sm-12 mx-auto p-3' style={{minHeight:'60vh', paddingBottom:'1rem'}}>
                { lHabladores.length>0 &&
                    <div className='btn-buscar cursor-pointer fs-16 mr-0' onClick={createTalkers}>Crear habladores</div>
                }
                
                {/* ----- INICIO LISTADO HABLADORES -------- */}
                <div className='ocultar-movil'>
                    <DataTableComp
                        columns={columns} 
                        data={lHabladores && lHabladores}
                        paginationComponentOptions={paginationComponentOptions}
                        paginationPerPage={20}
                     />

                </div>
                <div className='mostrar-movil'>
                    <DataTableMovil  
                        columns={columnsDetalleMovil} 
                        data={lHabladores && lHabladores}
                        expandedComponent={expandedComponentDetalleConfirm} 
                        paginationComponentOptions={paginationComponentOptions}
                    />
                </div>
                { lHabladores.length===0 &&
                    <div className='btn-buscar cursor-pointer fs-16 mx-auto' onClick={createTalkers}>Crear habladores</div>
                }
                {/* ----- FIN LISTADO HABLADORES -------- */}
             </div>
        </div>
    )
}
export default Habladores;