import React, {useState,useEffect,useContext} from 'react';
import {TitleLayout} from '../../base/TitleLayout'
import Spinner from '../../base/spiner';
import Call from '../../../config/Call'
import { Upload, Button,message  } from 'antd';
import DataTableComp from '../../produccion/autoriaLotes/dataTableComp';
import moment from 'moment';
import { UploadOutlined } from '@ant-design/icons';
import DataTableMovil from '../../produccion/autoriaLotes/dataTableCompMovil';
import Swal from 'sweetalert2';
import AuthContext from '../../../contexts/auth/AuthContext';

const Traslados = (props)=>{
    let datat = {
        titulo: "Traslados"
    }
    const { user } = useContext(AuthContext) // INFORMACION DE USUARIO
    const [sp, setSp]= useState(false) // MOSTRAR/OCULTAR LOADER
    const [lTraslados, setListTraslados] = useState([])
    const [showList, setShowList] =useState(false)
    const [filterText, setFilterText] = useState('');
    const [filterText2, setFilterText2] = useState('');
    const [filterTienda, setfilterTienda] = useState('')
    const [detalle, setDetalle] = useState([]) //OBETENER DETALLE TRASLADO
    const [showDetalleTraslado, setShowDetalleTraslado] = useState(false) //MOSTRAR MODEL DETALLE
    const [showResult, setShowResult] = useState(false)
    const [data, setData] = useState({previewVisible: false, previewImage: '', previewTitle: '', fileList:[]})
    const [lInconsistencias, setLInconsistencias] = useState([])
    const [confirmadas, setConfirmadas]=useState([])
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [filteredItems, setFilteredItems]= useState(null)
    const [showErrores, setShowErrores] = useState(false)
    const [lErrores, setlErrores]= useState([])
    const [cargado, setCargado] = useState([])
    const [trasladoSelected, setTrasladoSelected]= useState(null)
    const [listHistorial, setListHistorial] = useState([])
    const [listHistorialCompleto, setListHistorialCompleto] = useState([]) // LIST HISTORIAL
    const [listTiendasUsuario, setListTiendasUsuario] = useState([]) // LIST HISTORIAL
    const [showDetailHist, setShowDetailHist] = useState(false)
    const [datosDetalleHist, setDatosDetalleHist] = useState(null)
    const [dHistorial, setDHistorial] = useState([])
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10); // Cantidad de registros por página

    useEffect(()=>{
        if(user && ((user.nombre_cargo !== 'GERENTE DE ZONA') && (user.nombre_cargo !== 'COMERCIAL CLIENTES CORPORATIVOS'))){
            listTraslados()
        }
        lHistorial()
    },[])// eslint-disable-line react-hooks/exhaustive-deps
    
    const listTraslados = async()=>{
        setSp(true)
        const res = await Call('GET', '/api/traslados', true, null, false)
        setListTraslados(res.data.data)
        setShowList(true)
        setSp(false)
    }
    const confirmar=async(datos)=>{
        
        setTrasladoSelected(datos)
        setSp(true)
        const res = await Call('GET', '/api/traslados/buscar/'+datos.destino, true, null, false)
        setDetalle(res.data.data)
        setSp(false)
        setShowDetalleTraslado(true)
    }
    const saveTraslado = async()=>{

        
        let detail=[]
        if(cargado.length > 0){
            for(let i=0; i< cargado.length; i++){
               
                let datos ={
                    referencia: cargado[i].codigo,
                    plu: cargado[i].plu?cargado[i].plu: '-',
                    enviadas: cargado[i].envio,
                    recibidas: cargado[i].tienda,
                    confirmacion: cargado[i].confirmacion,
                    fecha_traslado: detalle[0].fecha,
                    nombre: cargado[i].referencia,
                    estado: cargado[i].completo,
                }
                detail.push(datos)
            }
            let final={
                confirmar:{
                    traslado:{
                        cod_traslado: detalle[0].traslado,
                        confirmacion: detalle[0].confirmacion,
                        cantidad: trasladoSelected.cantidad,
                        movimiento: trasladoSelected.movimiento,
                        origen: trasladoSelected.origen,
                        tienda_origen: trasladoSelected.tienda,
                    },
                    detalles: detail
                }

            }
            setSp(true)
            const res = await Call('POST', '/api/traslados/guardar', true, final, false)
            setSp(false)
            if(res.data.code === 1){
                Swal.fire({
                    title: '',
                    text: "El traslado ha sido guardado",
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '##23b991',
                    cancelButtonColor: 'transparent',
                    confirmButtonText: 'Aceptar'
                  }).then((result) => {
                    if (result.isConfirmed) {
                      setShowDetalleTraslado(false)
                      setData({previewVisible: false, previewImage: '', previewTitle: '', fileList:[]})
                      setShowResult(false)
                      setShowErrores(false)
                      setlErrores([])
                      lHistorial()
                    }
                  })
            }else{
                Swal.fire({
                    title: '',
                    text: `${res.data.message}`,
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonColor: '##23b991',
                    cancelButtonColor: 'transparent',
                    confirmButtonText: 'Aceptar'
                  }).then((result) => {
                    if (result.isConfirmed) {
                    //   setShowDetalleTraslado(false)
                    //   setData({previewVisible: false, previewImage: '', previewTitle: '', fileList:[]})
                    //   setShowResult(false)
                    //   setShowErrores(false)
                    //   setlErrores([])
                    //   lHistorial()
                    }
                  })
            }
        }
        
    }
    const lHistorial = async()=>{
        if (currentPage) {
            setSp(true)
            const res = await Call('GET', `/api/traslados/historial/?filter_tienda=${filterTienda}&page=${currentPage}&page_size=${perPage}&search=${filterText}`, true, null, false)
            setListHistorialCompleto(res.data.results.data)
            setListTiendasUsuario(res.data.results.tiendas_usuario)
            setListHistorial(res.data.results.data)
            setTotalRows(res.data.count);
            setSp(false)
        }
    }
    const detHistorial = async (datos)=>{
        setSp(true)
        const res = await Call('GET', '/api/traslados/historial/'+datos.cod_traslado, true, null, false)
        if(res.data.code === 1){
            setDatosDetalleHist(datos)
            setDHistorial(res.data.data)
            let inconsis=[]
            let confirm=[]
            let err=[]
            for(let i=0; i<res.data.data.length; i++){
                if(res.data.data[i].estado === 'False'){
                    inconsis.push(res.data.data[i])
                }
                if(res.data.data[i].estado === 'True'){
                    confirm.push(res.data.data[i])
                }
                if(res.data.data[i].estado !== 'True' && res.data.data[i].estado !== 'False'){
                    inconsis.push(res.data.data[i])
                    err.push(res.data.data[i].estado)
                }
            }
            
            setlErrores(err)
            if(err.length > 0){
                setShowErrores(true)
            }
            setShowResult(true)
            setLInconsistencias(inconsis)
            setConfirmadas(confirm)
            setShowDetailHist(true)
            
            setSp(false)
        }else{
            setSp(false)
            Swal.fire({
                icon: 'error',
                title: '',
                text: `${res.data.message}`,
              })
        }
        
    }

    // ----------- INICIO CABECERAS DE TABLAS ------------
    const columns=[
        {
            name: 'ID',
            cell: row => (
                <div>
                    {row.destino}
                </div>
            ),
            width: '100px',
            filterable: true,
        },
        {
            name: 'Fecha despacho',
            cell: row => (
                <div>
                    {moment(row.fecha).format('DD/MM/YYYY HH:mm')}
                </div>
            ),
            filterable: true,
        },
        {
            name: 'Consecutivo',
            cell: row => (
                <div>
                    {row.origen}
                </div>
            ),
            filterable: true,
        }, 
        {
            name: 'Tienda origen',
            cell: row => (
                <div>
                    {row.tienda}
                </div>
            ),
            filterable: true,
        },
        {
            name: 'Movimiento',
            cell: row => (
                <div>
                    {row.movimiento}
                </div>
            ),
            filterable: true,
        }, 
        {
            name: '',
            cell: row => (
                <div>
                    <div className='btn-buscar cursor-pointer fs-16' data-toggle="modal" data-target="#exampleModalDetalle" onClick={()=>{confirmar(row)}}>
                        Confirmar
                    </div>
                </div>
            ),
            filterable: true,
        }
    ]

    const columnsDetalle =[
        {
            name: 'Referencia',
            cell: row => (
                <div>
                    {row.codigo}
                </div>
            ),
            width: '150px',
            filterable: true,
        },
        {
            name: 'PLU',
            cell: row => (
                <div>
                    {row.plu? row.plu : '-'}
                </div>
            ),
            filterable: true,
        },
        {
            name: 'Nombre',
            cell: row => (
                <div>
                    {row.referencia}
                </div>
            ),
            filterable: true,
        },
        {
            name: 'Envio',
            cell: row => (
                <div>
                    {row.envio}
                </div>
            ),
            width: '80px',
            filterable: true,
        },
        {
            name: 'Tienda',
            cell: row => (
                <div>
                    {row.tienda}
                </div>
            ),
            filterable: true,
            width: '90px',
        },
        {
            name: 'Diferencia',
            cell: row => (
                <div>
                    {row.diferencia}
                </div>
            ),
            width: '150px',
            filterable: true,
        },       
    ]
    const columnsDetalleHist =[
        {
            name: 'Referencia',
            cell: row => (
                <div>
                    {row.referencia}
                </div>
            ),
            width: '150px',
            filterable: true,
        },
        {
            name: 'PLU',
            cell: row => (
                <div>
                    {row.plu ? row.plu:'-'}
                </div>
            ),
            width: '150px',
            filterable: true,
        },
        {
            name: 'Nombre',
            cell: row => (
                <div>
                    {row.nombre}
                </div>
            ),
            filterable: true,
        },
        {
            name: 'Enviadas',
            cell: row => (
                <div>
                    {row.enviadas}
                </div>
            ),
            width: '100px',
            filterable: true,
        },
        {
            name: 'Tienda',
            cell: row => (
                <div>
                    {row.recibidas}
                </div>
            ),
            filterable: true,
            width: '90px',
        },  
    ]
    const columnsDetalleHistMov =[
        {
            name: 'Referencia',
            cell: row => (
                <div>
                    {row.referencia}
                </div>
            ),
            width: '150px',
            filterable: true,
        },
        
    ]
    
    const columnsDetalleMovil =[
        {
            name: 'Referencia',
            cell: row => (
                <div>
                    {row.codigo}
                </div>
            ),
            width: '150px',
            filterable: true,
        },    
    ]
    const columnsMovil=[
        {
            name: 'ID',
            cell: row => (
                <div>
                    {row.destino}
                </div>
            ),
            filterable: true,
        },
        {
            name: 'Consecutivo',
            cell: row => (
                <div>
                   {row.origen}
                </div>
            ),
            filterable: true,
        },
    ]
    const columnsHistorial=[
        {
            name: 'ID',
            cell: row => (
                <div>
                    {row.id}
                </div>
            ),
            filterable: true,
        },
        {
            name: 'Fecha despacho',
            cell: row => (
                <div>
                    {moment(row.fecha_confirmacion).format('DD/MM/YYYY HH:mm')}
                </div>
            ),
            filterable: true,
        },
        {
            name: 'Consecutivo',
            cell: row => (
                <div>
                    {row.cod_traslado}
                </div>
            ),
            filterable: true,
        }, 
        {
            name: 'Tienda origen',
            cell: row => (
                <div>
                    {row.tienda_origen}
                </div>
            ),
            filterable: true,
        }, 
        {
            name: 'Tienda destino',
            cell: row => (
                <div>
                    {row.cod_tienda?.nombre}
                </div>
            ),
            filterable: true,
        },
        {
            name: 'Movimiento',
            cell: row => (
                <div>
                    {row.movimiento}
                </div>
            ),
            filterable: true,
        }, 
        {
            name: '',
            cell: row => (
                <div>
                    <div className='btn-buscar cursor-pointer fs-16' data-toggle="modal" data-target="#exampleModalDetalle" onClick={()=>{detHistorial(row)}}>
                        Ver detalle
                    </div>
                </div>
            ),
            filterable: true,
        }
    ]
    const columnsHistorialMov=[
        {
            name: 'ID',
            cell: row => (
                <div>
                    {row.id}
                </div>
            ),
            filterable: true,
        },
       
        {
            name: 'Consecutivo',
            cell: row => (
                <div>
                    {row.cod_traslado}
                </div>
            ),
            filterable: true,
        }, 
    ]
    // ----------- FIN CABECERAS DE TABLAS ------------
    // ----------- INICIO EXPANDIBLE TABLAS MOVIL  ------------
    const expandedComponentHist=(row)=>{
        return(
            
                lTraslados && lTraslados.map((a, index)=>(
                    a.origen === row.data.origen &&
                    <div className='py-2 d-block' key={index}>
                        <div><span className='fw-600'>Fecha despacho:</span>  {moment(a.fecha_confirmacion).format('DD/MM/YYYY HH:mm')}</div>
                        <div><span className='fw-600'>Tienda origen:</span> {a.tienda} </div>
                        <div><span className='fw-600'>Movimiento:</span> {a.movimiento}</div>
                        <div className='btn-buscar ml-0 mt-2 cursor-pointer fs-16' data-toggle="modal" data-target="#exampleModalDetalle"  onClick={()=>{detHistorial(row.data)}}>
                            Ver detalle
                        </div>
                    </div>
                ))           
        )
    }
    const expandedComponent=(row)=>{
        return(
            
                lTraslados && lTraslados.map((a, index)=>(
                    a.origen === row.data.origen &&
                    <div className='py-2 d-block' key={index}>
                        <div><span className='fw-600'>Fecha despacho:</span>  {moment(a.fecha).format('DD/MM/YYYY HH:mm')}</div>
                        <div><span className='fw-600'>Tienda origen:</span> {a.tienda} </div>
                        <div><span className='fw-600'>Movimiento:</span> {a.movimiento}</div>
                        <div className='btn-buscar ml-0 mt-2 cursor-pointer fs-16' data-toggle="modal" data-target="#exampleModalDetalle" onClick={()=>{confirmar(row.data)}}>
                            Confirmar
                        </div>
                    </div>
                ))           
        )
    }
    const expandedComponentDetalle=(row)=>{
        return(
                detalle && detalle.map((a, indexa)=>(
                    a.codigo === row.data.codigo &&
                    <div className='py-2 d-block' key={indexa}>
                    <div><span className='fw-600'>Nombre: </span>{a.referencia}</div>
                    <div><span className='fw-600'>Envio: </span> {a.envio} </div>
                    <div><span className='fw-600'>Tienda: </span> {a.tienda}</div>
                    <div><span className='fw-600'>Diferencia: </span>{a.diferencia}</div>
                </div>
                ))           
        )
    }
    const expandedComponentDetalleConfirm=(row)=>{
        return(
                confirmadas && confirmadas.map((a, indexa)=>(
                    a.codigo === row.data.codigo &&
                    <div className='py-2 d-block' key={indexa}>
                    <div><span className='fw-600'>PLU: </span>{a.plu? a.plu: '-'}</div>
                    <div><span className='fw-600'>Nombre: </span>{a.referencia}</div>
                    <div><span className='fw-600'>Envio: </span> {a.envio} </div>
                    <div><span className='fw-600'>Tienda: </span> {a.tienda}</div>
                    <div><span className='fw-600'>Diferencia: </span>{a.diferencia}</div>
                </div>
                ))           
        )
    }
    const expandedComponentDetalleConfirmHist=(row)=>{
        return(
                confirmadas && confirmadas.map((a, indexa)=>(
                    a.referencia === row.data.referencia &&
                    <div className='py-2 d-block' key={indexa}>
                    <div><span className='fw-600'>PLU: </span>{a.plu? a.plu: '-'}</div>
                    <div><span className='fw-600'>Nombre: </span>{a.nombre}</div>
                    <div><span className='fw-600'>Enviadas: </span> {a.enviadas} </div>
                    <div><span className='fw-600'>Tienda: </span> {a.recibidas}</div>  
                </div>
                ))           
        )
    }
    const expandedComponentDetalleIncon=(row)=>{
        return(
                lInconsistencias && lInconsistencias.map((a, indexa)=>(
                    a.codigo === row.data.codigo &&
                    <div className='py-2 d-block' key={indexa}>
                    <div><span className='fw-600'>PLU: </span>{a.plu? a.plu:'-'}</div>
                    <div><span className='fw-600'>Nombre: </span>{a.referencia}</div>
                    <div><span className='fw-600'>Envio: </span> {a.envio} </div>
                    <div><span className='fw-600'>Tienda: </span> {a.tienda}</div>
                    <div><span className='fw-600'>Diferencia: </span>{a.diferencia}</div>
                    
                </div>
                ))           
        )
    }
    const expandedComponentDetalleInconHist=(row)=>{
        return(
                lInconsistencias && lInconsistencias.map((a, indexa)=>(
                    a.referencia === row.data.referencia &&
                    <div className='py-2 d-block' key={indexa}>
                    <div><span className='fw-600'>PLU: </span>{a.plu? a.plu: '-'}</div>
                    <div><span className='fw-600'>Nombre: </span>{a.nombre}</div>
                    <div><span className='fw-600'>Enviadas: </span> {a.enviadas} </div>
                    <div><span className='fw-600'>Tienda: </span> {a.recibidas}</div>                    
                </div>
                ))           
        )
    }
    
    //ESTILOS
    const customStyles = {
        rows: {
            style: {
                backgroundColor:'#fec1be'
            },
        },
        // cells: {
        //     style: {
        //         backgroundColor:'#fec1be'
        //     },
        // },
    };
    const customStyles2 = {
        rows: {
            style: {
                backgroundColor:'#23b9919c'
            },
        },
        // cells: {
        //     style: {
        //         backgroundColor:'#23b9919c'
        //     },
        // },
    };
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };     
    // ----------- FIN EXPANDIBLE TABLAS MOVIL  ------------
    // const [filterText, setFilterText] = React.useState('');
	
	// const filteredItems = lTraslados.filter(
	// 	item => item.tienda && item.tienda.toLowerCase().includes(filterText.toLowerCase()),
	// );
    useEffect(() => {
        lHistorial();
    }, [filterText, currentPage, filterTienda]);

    const handletext =(e)=>{
        setFilterText(e.target.value)
    }

    const handleClear = () => {    
        setFilterText('')
    };

    const handleFilterTiendaChange = (event) => {
        const selectedValue = event.target.value;
        setfilterTienda(selectedValue);
    };

    const subHeaderComponentMemo = React.useMemo(() => {

        return (
            <>
            <select 
                className="form-select form-select-lg col-2 rounded" 
                aria-label=".form-select-lg example"
                onChange={handleFilterTiendaChange}
                value={filterTienda}
            >
                <option value="">Filtro de tienda destino</option>
                {listTiendasUsuario?.map((historial) => (
                    <option key={historial.codigo} value={historial.codigo}>
                        {historial.nombre}
                    </option>
                ))}
            </select>
            <input type="text" className="form-control form-control-sm col-3" 
                    placeholder="Buscar consecutivo" 
                    aria-label="Buscar" 
                    aria-describedby="basic-addon1" 
                    onChange={handletext} 
                    value={filterText} 
                />
                { filterText !== '' &&
                    <div type="button" className='pl-2 cursor-pointer' onClick={handleClear} >
                        <i className="fas fa-times-circle"></i>
                    </div>
                }
                
            </>
        );
    });
    
    // SUBIR ARCHIVO
    const iconRender = (file) => {
        const isTXT = file.type === 'text/plain'

        if(isTXT){
            return (<i className="fas inpuload fa-file-alt fa-2xl fs-16 justify-content-center"/>)
        }
    }
    const onChangeImg = async ({ fileList }) =>{ 
        
        if(((fileList[fileList.length-1] && fileList[fileList.length-1].size) && ((fileList[fileList.length-1].size/1024/1024))> 8)){
            message.error('El archivo debe ser menor a 8M');
        }else if((fileList[fileList.length-1] && fileList[fileList.length-1].type !== 'text/plain') || (fileList[fileList.length-1] && fileList[fileList.length-1].type === '')){
            message.error('El formato del archivo no es permitido');
        }else{
        setData({ fileList })
        handle(fileList)
        }   
       
    }
    
    const beforeUpload = (file) => {
        return false;
    }
   
    const handle =async(e)=>{
        setShowResult(false)
        var file = e[0].originFileObj
        let formdata = new FormData()
        formdata.append('file',file)
       setSp(true)
        const res= await Call('PUT', '/api/traslados/confirmar/'+detalle[0].traslado, true, formdata, true)
        setCargado(res.data.data)
        if(res.data.code === 1){
            let incon =[]
            let correctos=[]
            let errores=[]
            for(let i=0; i<res.data.data.length; i++){
                if(res.data.data[i].completo && typeof res.data.data[i].completo !== 'string'){
                    correctos.push(res.data.data[i])
                    
                }if(typeof res.data.data[i].completo === 'string'){
                    errores.push(res.data.data[i].completo)
                }
                if(!res.data.data[i].completo || typeof res.data.data[i].completo === 'string'){
                    incon.push(res.data.data[i])
                }
            }
            setlErrores(errores)
            setLInconsistencias(incon)
            setConfirmadas(correctos)
            if(res.data.errores.length > 0){
                for(let j=0; j<res.data.errores.length; j++){
                    errores.push(res.data.errores[j])
                }
                setlErrores(errores)
               
            }else{
                setShowErrores(false)
            }
            if(errores.length > 0){
                setShowErrores(true)
            }
            setShowResult(true)
            setSp(false)
        }else{
            Swal.fire({
                icon: 'info',
                title: '',
                text: 'El archivo no es válido',
                confirmButtonText:'Aceptar',
            })
            setShowErrores(false)
            setData({previewVisible: false, previewImage: '', previewTitle: '', fileList:[]})
            setSp(false)
        }
    } 
    // ----- FIN SUBIR ARCHIVO
    const conditionalRowStyles= [
        {
          when: row => row.referencia === '-',
          style: {
            backgroundColor: '#fec1be',
          },
        },
      ];

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
        setCurrentPage(page);
        lHistorial(null, page, newPerPage);
    };
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

      const preSave =()=>{
        
        let result = listHistorialCompleto.filter(word => word.cod_traslado === detalle[0].traslado)
        
        if((data.fileList.length !== 0) && (result.length === 0)){

            Swal.fire({
                title: '¿Desea guardar este traslado?',
                text: "Después de guardar no podrá modificarse",
                icon: 'info',
                showCancelButton: true,
                confirmButtonColor: '#23b991',
                cancelButtonColor: '#ee4630',
                confirmButtonText: 'Aceptar',
                cancelButtonText:'No guardar',
                customClass:{
                    cancelButton:'btn-cancel-general',
                    confirmButton:'btn-cancel-general',
                }
            }).then(async(result) => {
                if (result.isConfirmed) {
                    saveTraslado()
                    }else{
                        setShowDetalleTraslado(false)
                        setData({previewVisible: false, previewImage: '', previewTitle: '', fileList:[]})
                        setShowResult(false)
                        setShowErrores(false)
                        setlErrores([])
                    }
                })
        }else{
            setShowDetalleTraslado(false)
            setData({previewVisible: false, previewImage: '', previewTitle: '', fileList:[]})
            setShowResult(false)
            setShowErrores(false)
            setlErrores([])
        }
    }
    return(
        <div  className='pb-4'>
            { sp ? <Spinner /> : ''}  {/*LOADER*/}
            <TitleLayout data={datat} clase={"text-left"} icon={true} seccion={"traslados"}/>
            <div  className='bg-white p-4 col-lg-12 col-md-12 col-sm-12 mx-auto'>
            { showDetalleTraslado &&
                <>
                <div className="modal fade show" id="exampleModalDetalle" tabndex="-1" aria-labelledby="exampleModalDetalle" aria-hidden="true" style={{display:'block'}}>
                    <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg">
                        <div className="modal-content">
                            <div className="modal-header py-2">
                                <h5 className="modal-title" id="exampleModalDetalle">Confirmar traslado</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close fs-18" onClick={()=>{preSave()}}>
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body" style={{height: '70vh', overflowY:'auto'}}>
                                <div className='mt-2 mx-auto mercarTable'>
                                    <div className='d-flex justify-content-between flex-wrap'>
                                    <div className='mx-2 my-1'>
                                        <span className='fw-600'>Fecha:</span> <span> {moment(detalle[0]&& detalle[0].fecha).format('DD/MM/YYYY HH:mm')}</span>
                                        </div>
                                        <div className='mx-2 my-1'>
                                            <span className='fw-600'>Traslado: </span><span>{detalle[0]&& detalle[0].traslado}</span>
                                        </div>
                                        <div className='mx-2 my-1'>
                                            <span className='fw-600'>Confirmación: </span><span>{detalle[0]&& detalle[0].confirmacion}</span>
                                        </div>
                                    </div>
                                    <div className='mt-4'>
                                        <div className='fitcontenedor d-flex' >
                                            <Upload
                                                accept=".txt"
                                                maxCount={1}
                                                fileList={data.fileList}
                                                onChange={onChangeImg}
                                                iconRender={iconRender}  
                                                beforeUpload={beforeUpload} 
                                            >
                                                <Button icon={<UploadOutlined />}>Seleccionar archivo</Button>
                                            </Upload>
                                            {/* <input type="file" name="file" id="file" onChange={handle} /> */}
                                            {
                                                data&& data.fileList.length > 0 &&
                                                <div className='mx-2'>
                                                    <div className='btn-buscar cursor-pointer ml-2 pl-2 mt-0 mx-auto  text-center' style={{height:'fit-content'}} onClick={saveTraslado}>Guardar</div>
                                                </div>
                                            }
                                        </div>
                                        
                                        
                                    </div>
                                    {
                                        <div className='nomenclarura'>
                                            <div className='fs-14 d-flex'>
                                                <div className='point green' width="40" height="40"></div> Unidades confirmadas
                                            </div>
                                            {/* <div className='fs-14 d-flex'>
                                                <div className='point orang' width="40" height="40"></div> Unidades que no corresponden al traslado
                                            </div> */}
                                            <div className='fs-14 d-flex'>
                                                <div className='point danger' width="40" height="40"></div> Unidades con inconsistencias
                                            </div>
                                        </div>
                                    }
                                    <div className='pt-2'>
                                        {
                                            (showErrores && (data.fileList.length>0))&&
                                                <>
                                                    <div className="accordion pt-4" id="accordionExample">
                                                        <div className="card mb-0">
                                                            <div className="accordion-header ac-headerprod" id="headingOne" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                                Inconsistencias detectadas en el archivo
                                                            </div>
                                                            <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                                                                <div className="card-body">
                                                                    {
                                                                        lErrores && lErrores.map((e, indexe)=>(
                                                                            <div key={indexe}>
                                                                                {e}
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>                                        
                                                </>
                                        }
                                        {
                                            showResult ?
                                            <>
                                                <div>
                                                    <hr />
                                                    <span  className='fw-600 fs-18'>Inconsistencias</span> 
                                                    <span className='fw-600 ml-2'> - Total unidades: {lInconsistencias.length} </span> 
                                                    <div className='ocultar-movil'>
                                                        <DataTableComp 
                                                            columns={columnsDetalle} 
                                                            data={lInconsistencias&& lInconsistencias} 
                                                            className="mercarTable" 
                                                            customStyles={customStyles}
                                                            paginationComponentOptions={paginationComponentOptions}
                                                            conditionalRowStyles={conditionalRowStyles}
                                                            paginationPerPage={lInconsistencias&& lInconsistencias.length}
                                                        />
                                                    </div>
                                                    <div className='mostrar-movil'>
                                                        <DataTableMovil 
                                                            columns={columnsDetalleMovil} 
                                                            data={lInconsistencias&& lInconsistencias} 
                                                            expandedComponent={expandedComponentDetalleIncon} 
                                                            className="mercarTable" 
                                                            customStyles={customStyles}
                                                            paginationComponentOptions={paginationComponentOptions}
                                                            conditionalRowStyles={conditionalRowStyles}
                                                            paginationPerPage={lInconsistencias&& lInconsistencias.length}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='mb-4'>
                                                    <hr />
                                                    <span className='fw-600 fs-18'>Confirmadas</span>
                                                    <span className='ml-2 fw-600'> Total unidades: {confirmadas.length}</span>
                                                    <div className='ocultar-movil'>
                                                        <DataTableComp 
                                                            columns={columnsDetalle} 
                                                            data={confirmadas && confirmadas} 
                                                            className="mercarTable" 
                                                            customStyles={customStyles2}
                                                            paginationComponentOptions={paginationComponentOptions}
                                                            paginationPerPage={confirmadas&& confirmadas.length}
                                                        />
                                                    </div>
                                                    <div className='mostrar-movil'>
                                                        <DataTableMovil  
                                                            columns={columnsDetalleMovil} 
                                                            data={confirmadas && confirmadas} 
                                                            expandedComponent={expandedComponentDetalleConfirm} 
                                                            className="mercarTable" 
                                                            customStyles={customStyles2}
                                                            paginationComponentOptions={paginationComponentOptions}
                                                            paginationPerPage={confirmadas&& confirmadas.length}
                                                        />
                                                    </div>
                                                    
                                                </div>
                                            </>
                                            :
                                            <>
                                            
                                                <div className='ocultar-movil'>
                                                    <DataTableComp  
                                                        columns={columnsDetalle} 
                                                        data={detalle && detalle} 
                                                        className="mercarTable" 
                                                        customStyles={customStyles}
                                                        paginationComponentOptions={paginationComponentOptions}
                                                        paginationPerPage={detalle && detalle.length}
                                                    />
                                                </div>
                                                <div className='mostrar-movil'>
                                                    <DataTableMovil  
                                                        columns={columnsDetalleMovil} 
                                                        data={detalle && detalle} 
                                                        expandedComponent={expandedComponentDetalle}  
                                                        className="mercarTable" 
                                                        customStyles={customStyles}
                                                        paginationComponentOptions={paginationComponentOptions}
                                                        paginationPerPage={detalle && detalle.length}
                                                    />
                                                </div>
                                            </>

                                        }                               
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-backdrop fade show" style={{zIndex:'1000'}}></div>
                </>
            }
            <ul className="nav nav-tabs" id="myTab" role="tablist">
                { ((user.nombre_cargo !== 'GERENTE DE ZONA') && (user.nombre_cargo !== 'COMERCIAL CLIENTES CORPORATIVOS')) &&
                    <li className="nav-item" role="presentation">
                        <a className={`nav-link ${((user.nombre_cargo !== 'GERENTE DE ZONA') && (user.nombre_cargo !== 'COMERCIAL CLIENTES CORPORATIVOS'))? 'active':''}`} id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Traslados</a>
                    </li>
                }
                
                <li className="nav-item" role="presentation">
                    <a className={`nav-link ${((user.nombre_cargo === 'GERENTE DE ZONA') || (user.nombre_cargo === 'COMERCIAL CLIENTES CORPORATIVOS'))? 'active':''}`} id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Historial</a>
                </li>
            </ul>
                <div className="tab-content" id="myTabContent">
                    {/*  ------  INICIO TRASLADOS ------- */}
                    <div className={`tab-pane ${((user.nombre_cargo !== 'GERENTE DE ZONA') && (user.nombre_cargo !== 'COMERCIAL CLIENTES CORPORATIVOS'))? 'show active': ''}`}  id="home" role="tabpanel" aria-labelledby="home-tab">
                        <div className='p-2'>
                            {
                                showList &&
                                <>
                                    <div className='ocultar-movil'>
                                        <DataTableComp 
                                            columns={columns}  
                                            data={lTraslados&& lTraslados} 
                                            // subHeaderComponent={subHeaderComponentMemo}
                                            paginationPerPage={20} 
                                            responsive={true}
                                            customStyles={"trasladosdata"}
                                            paginationComponentOptions={paginationComponentOptions}
                                        />
                                    </div>
                                    <div className='mostrar-movil'>
                                        <DataTableMovil  
                                            columns={columnsMovil} 
                                            data={lTraslados&& lTraslados} 
                                            expandedComponent={expandedComponent} 
                                            paginationComponentOptions={paginationComponentOptions} 
                                            className="mercarTable"
                                        />
                                    </div>
                                </>
                            }
                            
                        </div>    
                    </div>
                    {/*  ------  FIN TRASLADOS ------- */}
                    {/*  ------  INICIO HISTORIAL ------- */}
                    <div className={`tab-pane fade ${((user.nombre_cargo === 'GERENTE DE ZONA') || (user.nombre_cargo === 'COMERCIAL CLIENTES CORPORATIVOS')) ? 'show active': ''}`} id="profile" role="tabpanel" aria-labelledby="profile-tab">
                        {showDetailHist &&
                            <>
                            <div className="modal fade show" id="exampleModalDetalle" tabndex="-1" aria-labelledby="exampleModalDetalle" aria-hidden="true" style={{display:'block'}}>
                                <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg">
                                    <div className="modal-content">
                                        <div className="modal-header py-2">
                                            <h5 className="modal-title" id="exampleModalDetalle"> <span className='fw-600'>Traslado: </span><span>{datosDetalleHist&& datosDetalleHist.cod_traslado}</span></h5>
                                            <button type="button" 
                                                className="close" 
                                                data-dismiss="modal" aria-label="Close fs-18" onClick={()=>{setShowDetailHist(false); 
                                                setCargado([]); 
                                                setTrasladoSelected(null); 
                                                setShowErrores(false); 
                                                setLInconsistencias([]); 
                                                setConfirmadas([]); 
                                                setDatosDetalleHist(null); 
                                                setDHistorial([]); 
                                                setlErrores([]);
                                                setShowResult(false) }}>
                                            <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body" style={{height: '70vh', overflowY:'auto'}}>
                                            <div className='mt-2 mx-auto mercarTable'>
                                                <div className='d-flex justify-content-between flex-wrap'>
                                                <div className='mx-2 my-1'>
                                                    <span className='fw-600'>Fecha:</span> <span> {moment(datosDetalleHist&& datosDetalleHist.fecha_confirmacion).format('DD/MM/YYYY HH:mm')}</span>
                                                    </div>
                                                    <div className='mx-2 my-1'>
                                                        <span className='fw-600'>Movimiento: </span><span>{datosDetalleHist&& datosDetalleHist.movimiento}</span>
                                                    </div>
                                                    <div className='mx-2 my-1'>
                                                        <span className='fw-600'>Confirmación: </span><span>{datosDetalleHist&& datosDetalleHist.confirmacion}</span>
                                                    </div>
                                                    <div className='mx-2 my-1'>
                                                        <span className='fw-600'>Revisado por: </span><span className='text-capitalize'>{dHistorial&& dHistorial[0]&& dHistorial[0].usuario.toLowerCase()}</span>
                                                    </div>
                                                    {
                                                        ((user.nombre_cargo === 'GERENTE DE ZONA') ||user.nombre_cargo === 'COMERCIAL CLIENTES CORPORATIVOS' )&&
                                                        <>
                                                            <div className='mx-2 my-1'>
                                                                <span className='fw-600'>Tienda origen: </span><span className='text-capitalize'>{datosDetalleHist&& datosDetalleHist.tienda_origen.toLowerCase()}</span>
                                                            </div>
                                                            <div className='mx-2 my-1'>
                                                                <span className='fw-600'>Tienda destino: </span><span className='text-capitalize'>{dHistorial&& dHistorial[0]&& dHistorial[0].tienda.toLowerCase()}</span>
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                                <div className='pt-2'>
                                                    {
                                                        showErrores &&
                                                            <>
                                                                <div className="accordion pt-4" id="accordionExample">
                                                                    <div className="card mb-0">
                                                                        <div className="accordion-header ac-headerprod" id="headingOne" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                                            Inconsistencias detectadas en el archivo
                                                                        </div>
                                                                        <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                                                                            <div className="card-body">
                                                                                {
                                                                                    lErrores && lErrores.map((e, indexe)=>(
                                                                                        <div key={indexe}>
                                                                                            {e}
                                                                                        </div>
                                                                                    ))
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>                                        
                                                            </>
                                                    }
                                                    {
                                                        showResult ?
                                                        <>
                                                            <div>
                                                                <hr />
                                                                <span  className='fw-600 fs-18'>Inconsistencias</span>
                                                                <span className='fw-600 ml-2'> - Total unidades: {lInconsistencias.length} </span> 
                                                                <div className='ocultar-movil'>
                                                                    <DataTableComp 
                                                                        columns={columnsDetalleHist} 
                                                                        data={lInconsistencias&& lInconsistencias} 
                                                                        className="mercarTable" 
                                                                        customStyles={customStyles}
                                                                        paginationComponentOptions={paginationComponentOptions}
                                                                        conditionalRowStyles={conditionalRowStyles}
                                                                        paginationPerPage={lInconsistencias&& lInconsistencias.length}
                                                                    />
                                                                </div>
                                                                <div className='mostrar-movil'>
                                                                    <DataTableMovil 
                                                                        columns={columnsDetalleHistMov} 
                                                                        data={lInconsistencias&& lInconsistencias} 
                                                                        expandedComponent={expandedComponentDetalleInconHist} 
                                                                        className="mercarTable" 
                                                                        customStyles={customStyles}
                                                                        paginationComponentOptions={paginationComponentOptions}
                                                                        conditionalRowStyles={conditionalRowStyles}
                                                                        paginationPerPage={lInconsistencias&& lInconsistencias.length}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className='mb-4'>
                                                                <hr />
                                                                <span className='fw-600 fs-18'>Confirmadas</span>
                                                                <span className='ml-2 fw-600'> Total unidades: {confirmadas.length}</span>
                                                                <div className='ocultar-movil'>
                                                                    <DataTableComp 
                                                                        columns={columnsDetalleHist} 
                                                                        data={confirmadas && confirmadas} 
                                                                        className="mercarTable" 
                                                                        customStyles={customStyles2}
                                                                        paginationComponentOptions={paginationComponentOptions}
                                                                        paginationPerPage={confirmadas&& confirmadas.length}
                                                                    />
                                                                </div>
                                                                <div className='mostrar-movil'>
                                                                    <DataTableMovil  
                                                                        columns={columnsDetalleHistMov} 
                                                                        data={confirmadas && confirmadas} 
                                                                        expandedComponent={expandedComponentDetalleConfirmHist} 
                                                                        className="mercarTable" 
                                                                        customStyles={customStyles2}
                                                                        paginationComponentOptions={paginationComponentOptions}
                                                                        paginationPerPage={confirmadas&& confirmadas.length}
                                                                    />
                                                                </div>
                                                                
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                        
                                                            <div className='ocultar-movil'>
                                                                <DataTableComp  
                                                                    columns={columnsDetalle} 
                                                                    data={detalle && detalle} 
                                                                    className="mercarTable" 
                                                                    customStyles={customStyles}
                                                                    paginationComponentOptions={paginationComponentOptions}
                                                                    paginationPerPage={detalle && detalle.length}
                                                                />
                                                            </div>
                                                            <div className='mostrar-movil'>
                                                                <DataTableMovil  
                                                                    columns={columnsDetalleMovil} 
                                                                    data={detalle && detalle} 
                                                                    expandedComponent={expandedComponentDetalle}  
                                                                    className="mercarTable" 
                                                                    customStyles={customStyles}
                                                                    paginationComponentOptions={paginationComponentOptions}
                                                                    paginationPerPage={detalle && detalle.length}
                                                                />
                                                            </div>
                                                        </>

                                                    }                               
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-backdrop fade show" style={{zIndex:'1000'}}></div>
                                                
                            </>
                        }
                        <div className='ocultar-movil'>
                            <DataTableComp 
                                columns={columnsHistorial} 
                                data={listHistorial && listHistorial} 
                                subHeaderComponent={subHeaderComponentMemo}
                                className="mercarTable" 
                                paginationComponentOptions={paginationComponentOptions}
                                pagination
                                paginationServer
                                paginationTotalRows={totalRows}
                                onChangePage={handlePageChange}
                                onChangeRowsPerPage={handlePerRowsChange}
                                paginationPerPage={perPage}
                            />
                        </div>
                        <div className='mostrar-movil'>
                            <DataTableMovil  
                                columns={columnsHistorialMov} 
                                data={listHistorial&& listHistorial} 
                                expandedComponent={expandedComponentHist} 
                                paginationComponentOptions={paginationComponentOptions} 
                                subHeaderComponent={subHeaderComponentMemo}
                                className="mercarTable"
                            />
                        </div>
                    
                    </div>
                </div>
            </div>
        </div>
    )

}
export default Traslados;