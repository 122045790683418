import React, {useState} from 'react';
import { Tooltip } from 'antd';

export const TitleLayout = (props) => {
    
    const { data } = props;
    const [showInst, setShowInst] = useState(false)
    const [urlVideo, setUrlVideo] = useState(null)
    const aqui =()=>{
        if(props.seccion === 'traslados'){
            setUrlVideo('https://filemanager.imr.com.co/files/filer_public/53/f4/53f4c829-a71e-4b26-9c18-9c3ae309d5d1/traslados_v2.mp4')
        }
        if(props.seccion === 'sondeos'){
            setUrlVideo('https://filemanager.imr.com.co/files/filer_public/77/63/776325ca-9d63-4ad3-bb3e-d72966795f14/sondeos.mp4')
        }
        if(props.seccion === 'tareas'){
            setUrlVideo('https://filemanager.imr.com.co/files/filer_public/2f/c2/2fc267a7-8b72-41a3-94be-3a21a7243fb5/tareas_20.mp4')
        }
        if(props.seccion === 'habladores'){
            setUrlVideo('https://filemanager.imr.com.co/files/filer_public/08/c4/08c43d8f-aabf-43e7-8f5d-6b646201e59f/habladores.mp4')
        }
        if(props.seccion === 'mercar'){
            setUrlVideo('https://filemanager.imr.com.co/files/filer_public/4b/7a/4b7a7eee-fe0f-45bf-b372-bc444caa9f1f/mercar_bonos.mp4')
        }
        if(props.seccion === 'ubicacion-inventario'){
            setUrlVideo('https://filemanager.imr.com.co/files/filer_public/30/a2/30a25fdd-09b7-4554-b884-e402fd2c8dac/unidades_de_inventario_v2.mp4') 
        }
        if(props.seccion === 'control-ingresos'){
            setUrlVideo('https://filemanager.imr.com.co/files/filer_public/5d/82/5d82c701-b592-4338-a33b-b9d14ec1b6f5/instructivo_control_ingresos.mp4') 
        }
        if(props.seccion === 'presupuestos'){
            setUrlVideo('https://filemanager.imr.com.co/files/filer_public/3b/2c/3b2c7eef-e2f6-4ba7-8713-df8da991d0c3/presupuestos_tiendas.mp4') 
        }
        if(props.seccion === 'citas'){
            setUrlVideo('https://filemanager.imr.com.co/files/filer_public/b9/4e/b94ed2e3-fa78-4bfd-8958-6670493ae973/citas_bodega.mp4')
        }
        if(props.seccion === 'prepvendedores'){
            setUrlVideo('https://filemanager.imr.com.co/files/filer_public/92/35/92354c08-9e72-4566-b3b9-c6f54202d24a/presupuestos_vendedores.mp4')
        }
        setShowInst(true)
    }
    return (
        <>
            <div className="section-header">
                <h1 className={`encabezado ${props.clase ? props.clase : ''}`}>{data.titulo}</h1>
                {
                    props.icon && 
                    <div>
                        <Tooltip color={'#aba7a7c2'} placement="left" title="Instructivo">
                            <span className="cursor-pointer text-danger d-flex fs-14" onClick={aqui}><i className="fas fa-info-circle fs-18 cursor-pointer text-danger mr-1"></i> Instructivo</span>
                        </Tooltip>
                    </div>
                }
                {
                    showInst &&
                    <>
                        <div className="modal show" style={{display:'block'}} id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                            <div className="modal-dialog modal-lg modal-dialog-centered">
                                    <div className="modal-content">
                                    <div className="modal-header">
                                        <h6 className="modal-title" id="staticBackdropLabel">Instructivo</h6>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>{setShowInst(false) }}>
                                        <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="embed-responsive embed-responsive-16by9">
                                            <iframe className="embed-responsive-item" src={urlVideo} allowfullscreen></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-backdrop fade show"></div>
                    </>
                }
            </div>
        </>
    );
};