import React from "react";
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image
} from "@react-pdf/renderer";
import logo from '../../../assets/base/images/cubo_rojo.png'
import logoMambo from '../../../assets/base/images/logo_mambo.png'



const styles = StyleSheet.create({
    page: {
        backgroundColor: "#ffffff",
        margin:'10px',
        fontWeight:'bold',
    },
    page2: {
        backgroundColor: "#ffffff",
        position:'relative',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent:'center',
        alignSelf: 'center',
    },
    container:{
        textAlign:"left",  
        flexDirection:'row',
        justifyContent:'left', 
        marginBottom:'30px',
        marginTop:'30px'
    },
    container2:{
        textAlign:"left",  
        flexDirection:'row',
        justifyContent:'left', 
        marginBottom:'45px',
        marginTop:'45px',
        fontWeight:'800'
    },
    image:{
        width:'50px',
        height:'50px',
        textAlign:'right', 
        position:'absolute',
        justifyContent:'flex-start',       
    },
    imageMambo:{
        height:'50px',
        textAlign:'right', 
        position:'absolute',
        justifyContent:'flex-start',       
    },
    imag:{
        width:'50px',
        height:'50px',
        textAlign:'right', 
        position:'absolute',
        justifyContent:'flex-end',
        marginBottom:'-100px',
        bottom:0,     
        right:0,  
    },
    view1:{
        fontFamily:'Helvetica-Bold',
        fontSize:"43px", 
        textAlign: 'right',
        alignContent: 'right',
        justifyContent:'flex-end',
        marginTop:'3%',
        position:'relative',
        fontWeight:'Bold'
    },
    borderMayor1:{
        border: '2px solid red', 
        padding:'5px', 
        paddingBottom:'35px', 
        margin:'5px', 
        height:'95%', 
        width:'95%'
    },
    borderMayor2:{
        border: '2px solid black', 
        padding:'5px', 
        paddingBottom:'35px', 
        margin:'5px', 
        height:'95%', 
        width:'95%'
    },
    borderMenor1:{
        border: '2px solid red', 
        padding:'5px', 
        paddingBottom:'35px', 
        marginLeft:'10px',
        marginRight:'10px'
    },
    borderMenor2:{
        border: '2px solid black', 
        padding:'5px', 
        paddingBottom:'35px', 
        marginLeft:'10px',
        marginRight:'10px'
    }
    
});

export function PdfSpeaker(props) {
   
    return (
        props.data && props.data.length >= 6 ?
        ((<Document>
            <Page style={styles.page} size='A4'>
                <View style={(props.tienda === "TIENDA BIG JOHN MAMBO QUIMBAYA" || props.tienda === "TIENDA BIG JOHN MONTERIA") ? styles.borderMayor2 : styles.borderMayor1}>
                    <View style={{justifyContent: 'end', marginBottom:'80px'}}>
                        {
                            (props.tienda && props.tienda === "TIENDA BIG JOHN MAMBO QUIMBAYA" || props.tienda === "TIENDA BIG JOHN MONTERIA") ?
                                <Image  
                                    source={logoMambo}
                                    style={styles.imageMambo}
                                />
                            :
                                <Image  
                                    source={logo}
                                    style={styles.image}
                                />
                        }
                        
                  </View>
                {props.data
                    ? props.data.map((a, index) => {
                        return (
                            <View key={index}  style={styles.container}>
                                <View style={{width:'68%',fontWeight:'Bold', fontFamily:'Helvetica-Bold',}}>
                                        <Text style={{width:'90%', marginBottom: '2px', fontWeight:800, fontFamily:'Helvetica-Bold',}}>{a.ref ? a.ref : a.referencia}</Text>
                                        <Text break style={{fontWeight:'Bold',fontSize:'20px', fontFamily:'Helvetica-Bold', letterSpacing:'-2px',height:'auto', overflowWrap:'break-word'}}>{a.nombre}</Text>
                                </View>
                                <View style={{display:'flex', width:'34%'}}>
                                        <Text style={styles.view1}>${new Intl.NumberFormat('de-DE').format(a.precio)}</Text>
                                </View>
                            </View>
                                
                 );
                })
            : ""}           
            </View>
            </Page>
        </Document>)):
        (
            <Document>
            <Page style={styles.page2} size='A4'>
                <View style={(props.tienda === "TIENDA BIG JOHN MAMBO QUIMBAYA" || props.tienda === "TIENDA BIG JOHN MONTERIA") ? styles.borderMenor2 : styles.borderMenor1}>
                    <View style={{justifyContent: 'end', marginBottom:'70px'}}>
                        
                        {
                            (props.tienda && props.tienda === "TIENDA BIG JOHN MAMBO QUIMBAYA" || props.tienda === "TIENDA BIG JOHN MONTERIA") ?
                                <Image  
                                    source={logoMambo}
                                    style={styles.imageMambo}
                                />
                            :
                                <Image  
                                    source={logo}
                                    style={styles.image}
                                />
                        }
                  </View>
                {props.data
                    ? props.data.map((a, index) => {
                        return (
                            <View key={index}  style={styles.container2}>
                                <View style={{width:'68%',fontWeight:'Bold', fontFamily:'Helvetica-Bold',}}>
                                        <Text style={{width:'90%', marginBottom: '2px', fontWeight:800, fontFamily:'Helvetica-Bold',}}>{a.ref ? a.ref : a.referencia}</Text>
                                        <Text break style={{fontWeight:'Bold',fontSize:'20px', fontFamily:'Helvetica-Bold', letterSpacing:'-2px',height:'auto', overflowWrap:'break-word'}}>{a.nombre}</Text>
                                </View>
                                <View style={{display:'flex', width:'34%'}}>
                                        <Text style={styles.view1}>${new Intl.NumberFormat('de-DE').format(a.precio)}</Text>
                                </View>
                            </View>
                                
                 );
                })
            : ""}           
            </View>
            </Page>
        </Document>
        )
    );
}