import React, {useState, useEffect} from 'react';
import {TitleLayout} from '../../base/TitleLayout'
import { Upload, Badge } from 'antd';
import Spinner from '../../base/spiner';
import Call from '../../../config/Call'
import Swal from 'sweetalert2'
import DataTableComp from '../../produccion/autoriaLotes/dataTableComp'
import DataTableMovil from '../../produccion/autoriaLotes/dataTableCompMovil';


const Presupuestos =()=>{
    let datat = {
        titulo: "Presupuestos tiendas"
    }

    const [showBudget, setShowBudget] = useState(false)
    const [messageError, setMessageError ] = useState(null)
    const [showSpinner, setShowSpinner ] = useState(false)
    const [dataf, setData] = useState({fileList:[]})
    const [userList, setUserList ] = useState([])
    const [monthsList, setMonthsList] = useState([])
    const [selectedMonth, setSelectedMonth] = useState('')


    useEffect(() => {
        getUserList();
        getMonthsList();
      }, []);


      const getUserList= async()=>{

        try {
            setShowSpinner(true)
            const res= await Call('GET', '/api/store/list_budget', true, null, false)
            
            setUserList(res.data.data)
            setShowSpinner(false)

        } catch (error) {
            Swal.fire({
                title: '',
                icon: 'error',
                html: (error.response.data.message? error.response.data.message : 'Hubo un error contacte al administrador' ),
                focusConfirm: false,
                confirmButtonText:'Aceptar',
            })

            setShowSpinner(false)

        }
    }

    const getMonthsList= async()=>{

        try {
            setShowSpinner(true)
            const res= await Call('GET', '/api/store/months', true, null, false)
            
            setMonthsList(res.data.data)
            setShowSpinner(false)

        } catch (error) {
            Swal.fire({
                title: '',
                icon: 'error',
                html: (error.response.data.message? error.response.data.message : 'Hubo un error contacte al administrador' ),
                focusConfirm: false,
                confirmButtonText:'Aceptar',
            })

            setShowSpinner(false)

        }
    }

    const handleMonthChange = (event) => {
        const selectedValue = (event.target.value);
        setSelectedMonth(selectedValue);

    };

    const downloadFormat = async () => {
        try {

            setShowSpinner(true)
            const requestData = {
                selected_month: selectedMonth,
            };

            const res = await Call('POST', `/api/store/download_template`, true, requestData, true);
            setShowSpinner(false)

            if (res && res.data) {
                downloadFile('presupuestos.xlsx', res.data.excel_base64);

            } else {
                console.error('No se recibieron datos válidos del servidor');
            }
            
        } catch (error) {
            Swal.fire({
            title: '',
            icon: 'error',
            html: (error.response.data.message? error.response.data.message : 'Hubo un error contacte al administrador' ),
            focusConfirm: false,
            confirmButtonText:'Aceptar',
            })

            setShowSpinner(false)

        }
      };

    const handleDownload = async (month,param) => {
        try {
          setShowSpinner(true)
          const res = await Call('GET', `/api/store/download/${month}`, true, null, false);

          if (param === 'Excel'){
            downloadFile(res.data.file_name_excel, res.data.file_content_excel);

          }else if (param === 'Csv'){
            downloadFile(res.data.file_name_csv, res.data.file_content_csv);

          }

          setUserList(res.data.data)
          setShowSpinner(false)

        Swal.fire({
            title: '',
            icon: 'success',
            html: res.data.message,
            focusConfirm: false,
            confirmButtonText:'Aceptar',
        })
          
        } catch (error) {
          Swal.fire({
            title: '',
            icon: 'error',
            html: (error.response?.data?.message ? error.response.data.message : 'Hubo un error contacte al administrador'),
            focusConfirm: false,
            confirmButtonText: 'Aceptar',
          });

          setShowSpinner(false)
        }
      };

    
    const columns=[
        {
            name: 'Mes',
            cell: row => (
                <div>
                    {row.month}
                </div>
            ),
            filterable: true,
        },
        {
            name: 'Fecha de carga',
            cell: row => (
                <div className='text-capitalize'>
                    {row.created_at}
                </div>
            ),
            filterable: true,
        },

        {
            name: 'Comerssia',
            cell: row => (
                <div>
                   <i className="fas inpuload fas fa-file-csv fa-2xl justify-content-center m-2 icon-csv" 
                   title='Descargar archivo csv' onClick={() => handleDownload(row.pk_month, 'Csv')}/>
                </div>
            ),
            filterable: true,
        },

        {
            name: 'Editable',
            cell: row => (
                <div>
                   <i className="fas inpuload fa-file-excel fa-2xl justify-content-center m-2 icon-excel" 
                   title='Descargar archivo excel' onClick={() => handleDownload(row.pk_month, 'Excel')}/>
                </div>
            ),
            filterable: true,
        }

    ]

    const columnsMov=[
        {
            name: 'Mes',
            cell: row => (
                <div>
                    {row.month}
                </div>
            ),
            filterable: true,
            width: 50,
        },


        {
            name: 'Comerssia',
            cell: row => (
                <div>
                   <i className="fas inpuload fas fa-file-csv fa-sm justify-content-center m-2 icon-csv" 
                   title='Descargar archivos'
                   onClick={() => handleDownload(row.pk_month,'Csv')}/>
                </div>
            ),
            filterable: false,
            width: 50,

        },

        {
            name: 'Editable',
            cell: row => (
                <div>
                   <i className="fas inpuload fa-file-excel fa-sm justify-content-center m-2 icon-excel" 
                   title='Descargar archivo excel' onClick={() => handleDownload(row.pk_month, 'Excel')}/>
                </div>
            ),
            filterable: true,
            width: 50,

        }
  
    ]

    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };  
   
    const expandedComponentDetalle=(row)=>{
        return(
            userList && userList.map((a, indexa)=>(
                ( (a.month === row.data.month) && (a.created_at === row.data.created_at))&&
                    <div className='py-2 d-block text-left' key={indexa}>
                        <div><span className='fw-600'>Mes: </span> {a.month} </div>
                        <div><span className='fw-600'>Fecha de carga:</span> {a.created_at}</div>
                    </div>
            ))           
        )
    }
    

    const confirmate = async (confirm_information,archiveName, monthNumber,monthName,newFileName) => {
        try {

            setShowSpinner(true)
            const requestData = {
                confirm_information: confirm_information,
                archiveName: archiveName,
                monthNumber: monthNumber,
                monthName: monthName,
                newFileName: newFileName
            };

            const res = await Call('POST', `/api/store/confirm_information`, true, requestData, true);
            setUserList(res.data.data)
            setShowSpinner(false)
        
            if (res.data.data === null|| res.data.data === 'null'){
                Swal.fire({
                    title: '',
                    icon: 'success',
                    html: res.data.message,
                    focusConfirm: false,
                    confirmButtonText:'Aceptar',
                })
            }else{
                Swal.fire({
                    title: '',
                    icon: 'success',
                    html: res.data.message,
                    focusConfirm: false,
                    confirmButtonText:'Aceptar',
                })

                // downloadFile(res.data.file_name, res.data.file_content);

          }

        } catch (error) {
            Swal.fire({
            title: '',
            icon: 'error',
            html: (error.response.data.message? error.response.data.message : 'Hubo un error contacte al administrador' ),
            focusConfirm: false,
            confirmButtonText:'Aceptar',
            })

            setShowSpinner(false)

        }
      };

    function downloadFile(fileName, fileContent) {
        const blob = base64ToBlob(fileContent, 'application/octet-stream');
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = fileName || 'archivo.csv';
        link.click();
    }

    function base64ToBlob(base64String, contentType = '') {
        const byteCharacters = atob(base64String);
        const byteArrays = [];
    
        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            const slice = byteCharacters.slice(offset, offset + 512);
    
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
    
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
    
        return new Blob(byteArrays, { type: contentType });
    }
    
    const onChangeImg = async ({ fileList }) =>{ 
        if(((fileList[fileList.length-1] && fileList[fileList.length-1].type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') && (fileList[fileList.length-1] && fileList[fileList.length-1].type !=='application/vnd.ms-excel')) || (fileList[fileList.length-1] && fileList[fileList.length-1].type === '')){
            setMessageError('El formato del archivo no es permitido');
        }else{
            setMessageError(null)
            setData({ fileList })
            handle(fileList)
        }   
       
    }

    const beforeUpload = (file) => {
        return false;
    }

    const handle =async(e)=>{
       //UPLOAD FILE AND CHARGE IN ROUTE

        if(e.length !== 0 ){
            var file = e[0].originFileObj

            let formdata = new FormData() // CONVIRTING A FORM DATA
            formdata.append('presupuestos',file) // ADDING KEY AND FILE 
            formdata.append('selected_month', selectedMonth); // AGREGAR MES SELECCIONADO
            setMessageError(null);


            try {
                setShowSpinner(true)
                const res= await Call('POST', `/api/store/budget`, true, formdata, true)
                setShowSpinner(false)
                setShowBudget(false)
                
                const shouldProceed = await Swal.fire({
                    title: '¿Es correcta la siguiente información?',
                    icon: 'question',
                    html: `${res.data.data[0].archive}</p>
                    <p class="m-regular" >Se enviará automáticamente los presupuestos a Comerssia</p>`,
                    showCancelButton: true,
                    confirmButtonColor: '#23b991',
                    cancelButtonColor: '#ee4630',
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cancelar',
                    focusConfirm: false,
                    customClass:{
                    cancelButton:'btn-cancel-general',
                    confirmButton:'btn-cancel-general',
                        }
                });

                if (shouldProceed.isConfirmed) {
                    const confirmData = {
                        confirm_information: true,
                    };

                    await confirmate(confirmData, res.data.data[0].archive, res.data.data[0].number_month, res.data.data[0].month_name, res.data.data[0].new_file_name);


                } else {
                    const shouldAlert = await Swal.fire({
                        title:'',
                        html:'¿Estás seguro de que deseas cancelar?',
                        icon: 'info',
                        showCancelButton: true,
                        confirmButtonColor: '#23b991',
                        cancelButtonColor: '#ee4630',
                        confirmButtonText: 'Sí',
                        cancelButtonText:'No',
                        customClass:{
                        cancelButton:'btn-cancel-general',
                        confirmButton:'btn-cancel-general',
                        }
                        })

                    if (shouldAlert.isConfirmed){
                        const confirmData = {
                            confirm_information: false,
                            };

                            await confirmate(confirmData,res.data.data[0].archive);

                    }else{
                        const confirmData = {
                            confirm_information: true,
    
                            };

                            await confirmate(confirmData, res.data.data[0].archive, res.data.data[0].number_month,res.data.data[0].month_name,res.data.data[0].new_file_name);
                    }
                }
                
                setData({
                    ...dataf,
                    fileList:[]
                })
                setShowSpinner(false)

            } catch (error) {
                setData({
                    ...dataf,
                    fileList:[]
                })
                setShowSpinner(false)
                    Swal.fire({
                    title: '',
                    icon: 'error',
                    html: (error.response.data.message? error.response.data.message : 'Hubo un error contacte al administrador' ),
                    focusConfirm: false,
                    confirmButtonText:'Aceptar',
                })
            } 

        }
    } 

    const customStyles ={    
        headCells: {
            style: {
                fontSize: '14px',    
                paddingLeft: '16px',
                paddingRight: '16px',
                color:'#678098',
            },
        },
        cells: {
            style: {
                fontSize: '14px',  
            },
        },
    }
    const customStyles2 ={    
        headCells: {
            style: {
                fontSize: '14px',    
                paddingLeft: '16px',
                paddingRight: '16px',
                color:'#678098',
            },
        },
        cells: {
            style: {
                fontSize: '14px',  
                
            },
        },
    }

    return (
        <div>
            { showSpinner && <Spinner />}
            <TitleLayout data={datat} clase={"text-left"} icon={true} seccion={'presupuestos'} /> {/*-----TITULO VISTA------*/}
            {
                showBudget &&
                    <>
                
                    <div className="modal fade show" style={{display:'block'}} id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered ">
                            <div className="modal-content">
                                <div className="modal-header py-1">
                                    <h5 className="modal-title text-center" id="staticBackdropLabel">Cargar presupuestos</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>{setShowBudget(false); setSelectedMonth('')}}>
                                    <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">

                                <section className="d-flex flex-column align-items-center">
                                    <span className='lh-1'>Por favor, seleccione el mes al que desea cargar los datos</span>
                                </section>

                                <section className="d-flex flex-column align-items-center">
                                    <div className='my-4 mx-0 my-2 d-flex align-items-center justify-content-start col-6'>
                                        <select className="form-control" value={selectedMonth} onChange={handleMonthChange}>
                                            <option value=""></option>
                                            {monthsList.map((month, index) => (
                                                <option key={index} value={month}>{month}</option>
                                            ))}
                                        </select>
                                    </div>
                                </section>


                                {selectedMonth  && (
                                    <div className="d-flex justify-content-center">
                                        <span className="d-flex flex-column align-items-center mr-4">
                                            <div className='btn-sec' style={{paddingTop: '0.35rem',paddingBottom: '0.35rem'}} onClick={downloadFormat}>
                                                <i className="fas fa-download fs-17" title='Descargar plantilla'></i>
                                            
                                            </div>
                                        </span>

                                        <section className="d-flex flex-column align-items-center">
                                            <Upload 
                                                accept=".xls, .xlsx"
                                                maxCount={1}
                                                fileList={dataf.fileList}
                                                onChange={(info) => {
                                                    onChangeImg(info);
                                                    setSelectedMonth('');
                                                }}
                                                beforeUpload={beforeUpload} 
                                                className='uploadUsers'
                                            >
                                                <div className='mx-0 btn-sec d-flex align-items-center justify-content-start btn-info-outline fs-16'
                                                    style={{color: 'white', background: '#3abaf4', paddingLeft: '1rem', paddingRight: '1rem'}}>
                                                    <i className="fas fa-upload fs-16 mr-2"></i> Cargar archivo
                                                </div>
                                            </Upload>
                                        </section>
                                    </div>
                                )}
                    
                                <section className="d-flex flex-column align-items-center">
                                    <div className='justify-content-start'>
                                        { messageError && <span className='text-danger fs-12'>{messageError}</span>}
                                    </div>
                                </section>

                                
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show"></div>
                </>
            }
            <div className='bg-white col-lg-9 col-md-9 col-sm-12 mx-auto p-3' style={{minHeight:'60vh', paddingBottom:'1rem'}}>
                <div className='cursor-pointer btn-buscar der m-3 height-fit'onClick={()=>{setShowBudget(true)}}>
                    Cargar presupuestos
                </div>
                <div className='container-size mx-auto'>

                    <div className='ocultar-movil'>
                            <DataTableComp 
                                columns={columns} 
                                data={userList&& userList} 
                                paginationComponentOptions={paginationComponentOptions}
                                paginationPerPage={20} 
                                customStyles={customStyles2}
                            />
                        </div>
                        <div className='mostrar-movil'>
                            <DataTableMovil  
                                columns={columnsMov } 
                                data={userList && userList}
                                expandedComponent={expandedComponentDetalle} 
                                pagination={true}
                                paginationComponentOptions={paginationComponentOptions}
                                paginationPerPage={20}
                                customStyles={customStyles}
                            />
                        </div>
                </div>
            </div>
        </div>
        );
    
}
export default Presupuestos;