import React, {useState,useEffect, useRef} from 'react';
import uuid from 'react-uuid';
import Call from '../../config/Call'
import Spinner from '../base/spiner.js';
import { Select, DatePicker, Upload, message} from 'antd';
import moment from 'moment';
import "moment/locale/es";
import es_ES from "antd/lib/date-picker/locale/es_ES";
import {FormDinamic} from '../formularios/FormDinamic';


const  NewTask = ({parentToChild,childToParent, RecopileDataForm})=>{

    const [lUsuarios, setLusuarios] = useState([]) // LISTA DE USUARIOS
    const [lGupoUsuarios, setLgupoUsuarios] = useState([]) // LISTA DE GRUPOS
    const [lPrioridades, setLprioridades] = useState([]) // LISTA DE PRIORIDADES
    const { Option, OptGroup } = Select;
    const [selectedItems, setselectedItems] = useState([]);
    const [selectedGroups, setselectedGroups] = useState([]);
    const [sp, setSp]= useState(false) // MOSTRAR/OCULTAR LOADER
    const [generalError, setGeneralError] = useState('')
    const [showOptions, setShowOptions] = useState('') //OPCIONES DE FLUJO PARA MOSTRAR OCULTAR OPCIONES
    const [dNewTask, setNewTask] = useState({
        rpts_formulario: [],
        usuarios_destinatarios: [],
        grupos_destinatarios: [],
        asunto: '',
        prioridad: '',        
        fecha_vencimiento: "",
        mensaje: '',
        archivos:[]
    })
    const [lError, setLerror] = useState({
        destinatariosError:false,
        asuntoError: false,
        prioridadError: false,        
        fecha_vencimientoError: false,
        mensajeError: false,
    })
    
    const formularioRef = useRef(null)

    useEffect(()=>{
        listarDestinatarios()
        datosFlujo()

    }, [])// eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(()=>{

    }, [dNewTask, formularioRef.current&& formularioRef.current.RecopileDataForm()&& formularioRef.current.RecopileDataForm()])// eslint-disable-line react-hooks/exhaustive-deps

    const listarDestinatarios = async() =>{
        carga(true)
        const res = await Call('GET', '/api/usuarios-destinatatios', true, null, false)
        setLusuarios(res.data.results)
        const res2 = await Call('GET', '/api/grupos', true, null, false)
        setLgupoUsuarios(res2.data.results)
        const res3 = await Call('GET', 'api/tareas/prioridades', true, null, false)
    
        setLprioridades(res3.data.results)
    
        carga(false)
    }
    
    const datosFlujo = async() =>{
        // console.log(parentToChild.idFlujo, 'parentToChild.idFlujo')
        const res= await Call('GET', '/api/tareas/flujo/'+parentToChild.idFlujo, true, null, false)
        // console.log(res.data.data, 'res.data.data')
        setShowOptions(res.data.data)
    }
    const handleChange = (value, options) => {
        // console.log(value, options)
        let u= []
        let g=[]
        for(let i=0; i<options.length; i++ ){
            if(options[i].name === "user"){
                // console.log(options[i].name)
                u.push(options[i].value.slice(1))
            }else{
                g.push(options[i].value.slice(1) );
            }
        }
        setselectedItems(u );
        setselectedGroups(g);    
    }
    const handleChangeDatos =(e, b) =>{
        // console.log(e)
        // console.log(b)
        if(b){
            setNewTask({
                ...dNewTask,
                prioridad: e
            })
        }else{
            setNewTask({
                ...dNewTask,
                [e.target.name]:e.target.value,
            })
        }
        
    }
    const onChange = (date, dateString) => {
        // console.log(date, 'date');
        // console.log(dateString, 'dateString')
        setNewTask({
            ...dNewTask,
            fecha_vencimiento: dateString
        })
    }
    const validar = () =>{
        // console.log(formularioRef,'validar')
        let task={
            usuarios_destinatarios: selectedItems,
            grupos_destinatarios: selectedGroups,
            asunto:dNewTask.asunto,
            prioridad: dNewTask.prioridad,        
            fecha_vencimiento: dNewTask.fecha_vencimiento,
            mensaje: dNewTask.mensaje,
            archivos:dNewTask.archivos,
            rpts_formulario:formularioRef.current !== null? (formularioRef.current.RecopileDataForm()&& formularioRef.current.RecopileDataForm()) : [],
        }
        // console.log(task, 'task')
        if((showOptions.mostrar_asunto && task.asunto === '')  && (showOptions.mostrar_destinatarios && task.usuarios_destinatarios.length === 0 && task.grupos_destinatarios.length===0) 
        && (showOptions.mostrar_prioridad && task.prioridad === '') && (showOptions.mostrar_mensaje && task.mensaje === '')){
            setLerror({
                destinatariosError: true,
                asuntoError: true,
                prioridadError: true,        
                mensajeError: true,
            })
            return
        } 
        if((showOptions.mostrar_asunto && task.asunto !== '')  && (showOptions.mostrar_destinatarios && task.usuarios_destinatarios.length === 0 && task.grupos_destinatarios.length===0) && (showOptions.mostrar_prioridad && task.prioridad === '') && (showOptions.mostrar_mensaje && task.mensaje === '')){
            setLerror({
                destinatariosError: true,
                asuntoError: false,
                prioridadError: true,        
                mensajeError: true,
            })
            return
        }
        if((showOptions.mostrar_asunto && task.asunto === '') && (showOptions.mostrar_destinatarios && (task.usuarios_destinatarios.length > 0 || task.grupos_destinatarios.length >0 )) && (showOptions.mostrar_prioridad && task.prioridad === '') && (showOptions.mostrar_mensaje && task.mensaje === '')){
            setLerror({
                destinatariosError: false,
                asuntoError: true,
                prioridadError: true,        
                mensajeError: true,
            })
            return
        }
        if((showOptions.mostrar_asunto && task.asunto === '') && (showOptions.mostrar_destinatarios && (task.usuarios_destinatarios.length === 0 && task.grupos_destinatarios.length ===0) ) && (showOptions.mostrar_prioridad && task.prioridad !== '') && (showOptions.mostrar_mensaje && task.mensaje === '')){
            setLerror({
                destinatariosError: true,
                asuntoError: true,
                prioridadError: false,        
                mensajeError: true,
            })
            return
        }if((showOptions.mostrar_asunto && task.asunto === '') && (showOptions.mostrar_destinatarios && (task.usuarios_destinatarios.length === 0 && task.grupos_destinatarios.length ===0) ) && (showOptions.mostrar_prioridad && task.prioridad === '') && (showOptions.mostrar_mensaje && task.mensaje !== '')){
            setLerror({
                destinatariosError: true,
                asuntoError: true,
                prioridadError: true,        
                mensajeError: false,
            })
            return
        }
        if((showOptions.mostrar_asunto && task.asunto !== '') && (showOptions.mostrar_destinatarios && (task.usuarios_destinatarios.length !== 0 || task.grupos_destinatarios.length !==0 )) && (showOptions.mostrar_prioridad && task.prioridad === '') && (showOptions.mostrar_mensaje && task.mensaje === '')){
            setLerror({
                destinatariosError: false,
                asuntoError: false,
                prioridadError: true,        
                mensajeError: true,
            })
            return
        }
         if(showOptions.mostrar_asunto && task.asunto === ''){
            setLerror({
                asuntoError: true,
            })
            return
        }if((showOptions.mostrar_destinatarios && task.usuarios_destinatarios.length === 0) && (showOptions.mostrar_destinatarios && task.grupos_destinatarios.length===0)){
            setLerror({
                destinatariosError: true,
            })
            return
        }
         if(showOptions.mostrar_prioridad && task.prioridad === ''){
            setLerror({
                prioridadError: true,  
            })
            return
        }else if(showOptions.mostrar_mensaje && task.mensaje === ''){
            setLerror({
                mensajeError: true, 
            })
            return
        }else if(formularioRef.current !== null ){
            if(!formularioRef.current.ValidForm()){
                return
            }
           
        }
        // if (showOptions.mostrar_formulario){
        //     if (formularioRef.current.ValidForm()){
        //         setNewTask({
        //             ...dNewTask,
        //             rpts_formulario: formularioRef.current.RecopileDataForm()
        //         })
        //         addTask(false, task)
        //     }
        // }else{
            addTask(false, task)
        // }
    }
    // console.log(showOptions, 'showOptions')
    const submitTask = () =>{
        // if (showOptions.mostrar_formulario){
        //     if (formularioRef.current.ValidForm()){
        //         setNewTask({
        //             ...dNewTask,
        //             rpts_formulario: formularioRef.current.RecopileDataForm()
        //         })
        //         validar()

        //     }
        // }else{
            validar()
        // }
    }

    const getBinaryFiles = async () => {
        let arrayArchivos = []
        data.fileList && data.fileList.map(el => {
            const reader = new FileReader();
            reader.readAsDataURL(el.originFileObj)

            reader.onload = e => {
                let x=reader.result;
               
                let base= x.split('64,');
                let a = {
                    nombre:el.name,
                    data:base[1],
                }
                arrayArchivos.push(a)
            };
            return null
        })
        setNewTask({
            ...dNewTask,
            archivos: arrayArchivos
        })
    }
    const carga =(activar)=> {
        if (activar) {
          setSp(true)
        } else {
          setSp(false)
        }
      };
      const [data, setData] = useState({previewVisible: false, previewImage: '', previewTitle: '', fileList:[]})
      
    useEffect(()=>{
       getBinaryFiles()

    }, [data.fileList]) // eslint-disable-line react-hooks/exhaustive-deps
    
    const onChangeImg = async ({ fileList }) =>{ 
        // console.log(fileList[fileList.length-1], 'datos imagen')
        if(((fileList[fileList.length-1] && fileList[fileList.length-1].size) && ((fileList[fileList.length-1].size/1024/1024))> 8)){
            message.error('El archivo debe ser menor a 8M');
        }else if((fileList[fileList.length-1] && fileList[fileList.length-1].type === 'application/x-msdownload') || (fileList[fileList.length-1] && fileList[fileList.length-1].type === '')){
            message.error('El formato del archivo no es permitido');
        }else{
        setData({ fileList })
        }   
    }
     
    const getBase64 = async (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });
    }

    const iconRender = (file) => {
        // const isJPG = file.type === 'image/jpeg';
        const isTXT = file.type === 'text/plain'
        const isPDF = file.type === 'application/pdf' 
        const isCSV = file.type === 'text/csv'
        const isXLSX = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        const isPWP = file.type === 'application/pdf'
        const isWORD = file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        const isZIP = file.type === 'application/x-zip-compressed'
        const isMP4 = file.type === 'video/mp4'
        if(isTXT){
            return (<i className="fas inpuload fa-file-alt fa-2xl justify-content-center"/>)
        }
        if(isPDF){
            return (<i className="fas inpuload fa-file-pdf fa-2xl justify-content-center"/>)
        }
        if(isXLSX){
            return (<i className="fas inpuload fa-file-excel fa-2xl justify-content-center"/>)
        }
        if(isPWP){
            return (<i className="fas inpuload fa-file-powerpoint fa-2xl justify-content-center"/>)
        }
        if(isWORD){
            return (<i className="fas inpuload fa-file-word fa-2xl justify-content-center"/>)
        }
        if(isCSV){
            return (<i className="fas inpuload fa-file-csv fa-2xl justify-content-center"/>)
        }
        if(isZIP){
            return (<i className="fas inpuload fa-file-archive fa-2xl justify-content-center"/>)
        }
        if(isMP4){
            return (<i className="fas inpuload fa-file-video fa-2xl"/>)
        }
    }

    const  handlePaste = async (e) => {
        if (e.clipboardData.files.length && showOptions.mostrar_adjunto) {
            let uid = uuid()
            let fileObject = e.clipboardData.files[0];
            fileObject.uid =uid
            let url = await getBase64(e.clipboardData.files[0])
            let obj = {
                lastModified:fileObject.lastModified,
                lastModifiedDate:fileObject.lastModifiedDate,
                name:fileObject.name,
                percent:0,
                size:fileObject.size,
                thumbUrl:url,
                type:fileObject.type,
                uid:uid,
                originFileObj:fileObject
            } 
            data.fileList.push(obj)
            setData(data)
            await onChangeImg(data)
            getBinaryFiles()

        }
    };

    
    const addTask = async(validar, task)=>{
        if(!validar){
            carga(true)
            // RecopileDataForm()
            // console.log(preguntas, 'pre')
            const res = await Call('POST', '/api/tareas/flujo/'+parentToChild.idFlujo, true, task, false)
            if(res.data.code === 1){
                setGeneralError('')
                childToParent()
                // formDinamic()
            }  
            if(res.data.code === 2){
                setGeneralError(res.data.message)
            }         
            carga(false)
        }
    }
    // const preguntas = (pregunta, indextp, e) =>{
    //     console.log(pregunta, indextp, e, 'preeee')
    // }
    // const [preguntas, setPreguntas]= useState()
    // console.log(preguntas, 'preguntas new')
    const beforeUpload = (file) => {
        // const isJPG = file.type === 'image/jpeg';
        return false;
    }
    const disabledDate = (current)=> {
        // Can not select days before today and today
        // return current && current < moment().endOf(new Date()).add(-1, 'days') //ayer
        return current && current < moment().endOf('days')
    }
    return(
        <>
            { sp ? <Spinner /> : ''}  {/*LOADER*/}
            <div className="modal show new-task" tabIndex="-1" style={{display:'block'}}>
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
                    <div className="modal-content fondo-movil-new-task">
                        <div className="modal-header new-task-header">
                            <span className='tittle text-center'>Nueva tarea</span>
                            <button type="button" className="close" onClick={()=>childToParent()}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body newtask-body" style={{maxHeight:'75vh', overflowY:'auto' }}>
                            {
                                generalError !== '' && <span className="text-danger mesagge-error text-center">{generalError}</span>
                            }
                            {
                                showOptions && showOptions.mostrar_destinatarios &&
                                <>
                                    <Select
                                mode="multiple"
                                allowClear
                                onChange={(value, options)=>handleChange(value, options)}
                                style={{ width: '100%', boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'}}
                                size="large"
                                className="select-destinatario mt-2"
                                showSearch
                                placeholder="Selecciona un destinatario"
                                optionFilterProp="children"
                                filterOption={(input, option) =>{
                                    if(option.children){
                                    return option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false;
                                    }else if(option.label){
                                    return option.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ? true : false;
                                    }
                                    }}
                                filterSort={(optionA, optionB) =>
                                optionA.children?.toLowerCase().localeCompare(optionB.children?.toLowerCase())
                                }
                            >
                                <OptGroup label="Usuarios">
                                    {lUsuarios &&  lUsuarios.map((u, indexu)=>(
                                        <Option key={'u'+indexu} value={'u'+u.id} name="user">{u.apellidos_nombres}</Option>
                                    )) }
                                    
                                </OptGroup>
                                <OptGroup label="Grupos">
                                    {
                                        lGupoUsuarios && lGupoUsuarios.map((u, indexgu)=>(
                                            <Option key={'g'+indexgu} value={'g'+u.id} name="group">{u.name}</Option>
                                        ))
                                    }
                                </OptGroup>
                            </Select>
                            <span className="text-danger mesagge-error">{lError.destinatariosError ? 'Este campo es requerido' : ''}</span>
                                </>
                            }
                            <div className='my-1' >
                            <div className='my-1'>
                                {
                                    showOptions && showOptions.mostrar_asunto &&
                                    <>
                                        <input
                                            className='form-control form-color padding-form' 
                                            type="text" name="asunto" id="asunto" 
                                            placeholder='Asunto'
                                            onChange={handleChangeDatos}
                                            maxLength="70"
                                            style={{border:0, fontSize:'15px', boxShadow: 'rgb(99 99 99 / 31%) 0px 6px 11px 0px'}}
                                        />
                                        <span className="text-danger mesagge-error">{lError.asuntoError ? 'Este campo es requerido' : ''}</span>
                                    </>
                                }
                            </div>
                            <div className='d-flex my-1 flex-wrap fecha-prioridad mt-2'>
                                {
                                    showOptions && showOptions.mostrar_prioridad &&
                                        <Select 
                                            name="prioridad" 
                                            placeholder="Prioridad" 
                                            onChange={(value,option)=>handleChangeDatos(value,option)}
                                            className="select-prioridad"
                                            style={{boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px', marginBottom:'10px'}}
                                            // value={dNewTask.prioridad !== '' ? dNewTask.prioridad : placeholder }
                                        >
                                            {
                                                lPrioridades && lPrioridades.map((p, indexp)=>(
                                                    <Option key={indexp} name="prioridad" className="text-capitalize" value={p.nombre}>{p.nombre === 'alta' ? <i className="fas fa-fire-alt prioridad alta mr-2"></i> : (p.nombre === 'media' ? <i className="fas fa-exclamation-triangle fs-12 prioridad pmedia mr-2"></i> : <i className="fas fa-minus prioridad mr-2"></i> )}{p.nombre.charAt(0).toUpperCase() + p.nombre.slice(1).toLowerCase()}</Option>
                                                ))
                                            }
                                        </Select> 
                                }
                                 {
                                showOptions && showOptions.mostrar_prioridad &&
                                    <div className='w-100 posicion-prioridad-movil'>
                                        <div className='select-prioridad'>
                                            <span className="text-danger mesagge-error m-error">{lError.prioridadError ? 'Este campo es requerido' : ''}</span>
                                        </div>
                                    </div>
                                } 
                                
                                {
                                    showOptions && showOptions.mostrar_fecha_vencimiento &&
                                    <>
                                        <div className='ml-2'>
                                            <DatePicker style={{ border:0, boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' }} 
                                                disabledDate={disabledDate}
                                                className="select-fecha w-100"
                                                onChange={onChange} 
                                                placeholder="Fecha de vencimiento" 
                                                locale={es_ES}
                                                size="large"
                                            />
                                    
                                        </div>
                                    </>
                                }
                            </div>
                            {
                                showOptions && showOptions.mostrar_prioridad &&
                                    <div className='posicion-prioridad'>
                                        <div className='select-prioridad'>
                                            <span className="text-danger mesagge-error m-error">{lError.prioridadError ? 'Este campo es requerido' : ''}</span>
                                        </div>
                                    </div>
                             } 
                            {showOptions && showOptions.mostrar_adjunto &&
                            <div className='text-center'>
                                {/* <ImgCrop rotate> */}
                                    <Upload
                                        
                                        listType="picture-card"
                                        className='content-newtask'
                                        fileList={data.fileList}
                                        onChange={onChangeImg}
                                        iconRender={iconRender}  
                                        beforeUpload={beforeUpload} 
                                        // uploading={false} 
                                    >
                                        {data.fileList.length < 5 && <span><i className="fas fa-paperclip"></i> Adjuntar</span>}
                                    </Upload>
                                {/* </ImgCrop> */}
                            </div>
                            }
                            {
                                showOptions && showOptions.mostrar_mensaje &&
                                <div>
                                    <textarea  onPaste={handlePaste} className="w-100 mensaje-new-task form-color" placeholder='Digita el detalle de la tarea' onChange={handleChangeDatos} name="mensaje" id="mensaje" cols="30" rows="5"></textarea>
                                    <span className="text-danger mesagge-error">{lError.mensajeError ? 'Este campo es requerido' : ''}</span>
                                </div>
                             }
                             {
                                 showOptions&& showOptions.mostrar_formulario &&
                                 <div className='mt-4 mb-2'>
                                     <FormDinamic formularioRef={formularioRef} formData={showOptions.formulario} showButtonEnviar={false} showButtonLimpiar={false} url={'tareas'}/>
                                 </div>
                             } 
                        </div>
                        </div>
                        
                        <div className="modal-footer border-top-0">
                            <div className="button-submit btn-send-tareas" onClick={submitTask}><i className="fas fa-paper-plane fs-12 mt-1"></i><span className='submit-text ml-1'>Enviar</span> </div>
                        </div>
                    </div>
                </div>
            </div>
                
            <div className="modal-backdrop fade show"></div>
        </>
    );
}
export default NewTask
