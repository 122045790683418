import './comunidad.css';
import logo from '../../assets/base/images/cubo_rojo.ico';
import { motion } from "framer-motion";

const CardUsuario = (props) => {
    const {apellidos_nombres, centro_costos, foto, nombre_cargo, is_online } = props.props

    const capitalize= (str) =>{
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    return (
     <motion.div whileHover={{ scale: 1.1 }} className="col-12 col-sm-6 col-lg-3" >
      <div className="single_advisor_profile wow fadeInUp" data-wow-delay="0.3s" >
        <div className="advisor_thumb"><img className="img-card-profile2" src={foto} style={{width: "200px", height: "200px" ,objectFit: 'fill'}} alt=""/>
          <div className="social-info">
            {/* <a href="#"><i className="fa fa-star"></i></a> */}
            <a href="#"><img  src={logo} width="30px" height="30px" alt=""/></a>
            {/* <a href="#"><i className="fa fa-star"></i></a> */}
          </div>
        </div>

        <div className="single_advisor_details_info">
          <h6>{apellidos_nombres}</h6>
          <p className="designation">{nombre_cargo}</p>
        </div>
      </div>
    </motion.div>)
}

export default CardUsuario;

