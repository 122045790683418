import React from 'react';

import { NavBar } from '../components/base/Header/NavBar';
import { SideBar } from '../components/base/Sidebar/SideBar';
import {AdminRouters} from '../Routes/AdminRoutes.js';
// import { Colgante } from '../components/base/Colgante';



export const LayoutMain = () => {
    return (
        <>
            <div className="main-wrapper">
                <div className="navbar-bg"></div>
                {/* <Colgante></Colgante> */}
                <NavBar/>
                <SideBar/>
                <div className="main-content">
                    <section className="section aparecer animated">
                        {AdminRouters}
                    </section>
                </div>
                
            </div>
            
        </>
    );
};