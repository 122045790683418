import React, {useReducer} from 'react';
import AuthContext from './AuthContext.js';
import AuthReducer from './AuthReducer.js';
import {LOGIN_SUCCESS, LOGIN_ERROR, LOGOUT } from '../../config/types';
import Call from '../../config/Call'
import { decode } from '../../config/crypto.js';

import Swal from 'sweetalert2';

const AuthState = (props) => {
    const data = localStorage.getItem('authInfo')? decode(localStorage.getItem('authInfo')):null
    const token = (data? data.token: null);
    const isAuthenticated = (data? data.isAuthenticated: false);
    const message = (data? data.message: null);
    const user = (data? data.user: null);
    const menu = (data? data.menu: null);

    const initialState = {
        token:token,
        isAuthenticated:isAuthenticated,
        user:user,
        menu:menu,
        message:message
    }

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    const [state, dispatch] = useReducer(AuthReducer, initialState)

    const loginFunction = async (datos) => {
        try {
          const res = await Call('POST', 'token', false, datos, false);
          if (res && res.data) {
            if (res.data.data !== null) {
              dispatch({
                type: LOGIN_SUCCESS,
                payload: {
                  isAuthenticated: true,
                  token: res.data.data,
                  user: res.data.user,
                  menu: res.data.menu,
                },
              });
            } else {
              if (res.data.message === "Lo sentimos, no te encuentras activo en el sistema") {
                Toast.fire({
                  icon: 'error',
                  title: 'Lo sentimos, no te encuentras activo en el sistema, contacte con el administrador',
                });
              }
              dispatch({
                type: LOGIN_ERROR,
                payload: res.data.message,
              });
            }
          } else {
            dispatch({
              type: LOGIN_ERROR,
              payload: "No se recibieron datos del servidor",
            });
          }
        } catch (error) {
          dispatch({
            type: LOGIN_ERROR,
            payload: error.response?.data?.message || "Error al intentar iniciar sesión",
          });
        }
      };

    const logout = () => {
        dispatch({
            type: LOGOUT,
            payload: {
                token: null,
                user: null,
                menu: null,
                isAuthenticated: false,
                message:null
            }
        })
    } 

    return(
        <AuthContext.Provider
            value={{
                token: state.token,
                isAuthenticated: state.isAuthenticated,
                user: state.user,
                menu: state.menu,
                message: state.message,
                loginFunction: loginFunction,
                logout: logout
            }}
        >
            {props.children}
        </AuthContext.Provider>
    )
}

export default AuthState;