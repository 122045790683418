import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import reportWebVitals from './reportWebVitals';

import { disableReactDevTools } from '@fvilers/disable-react-devtools';

import "bootstrap/dist/css/bootstrap.min.css";

import './assets/base/css/bj_colors.css';
import './assets/base/css/components.css';
import './assets/base/css/style.scss';
// import { motion } from "framer-motion";
// import './assets/base/css/custom.css';

import * as jQuery from "jquery";

import AuthState from './contexts/auth/AuthState.js';

window.$ = window.jQuery = require('jquery')
window.jQuery = jQuery;
window.$ = jQuery;

require("jquery.nicescroll/dist/jquery.nicescroll.js");


require("sweetalert/dist/sweetalert.min.js");
require("popper.js/dist/popper.min");
require("jquery/dist/jquery.min");
require("jquery-ui-dist/jquery-ui.min.js");

require("bootstrap/dist/js/bootstrap.bundle.min.js");
require("./assets/base/js/js/stisla.js");
require("./assets/base/js/js/scripts.js");
require("./assets/base/js/js/custom.js");


if (process.env.REACT_APP_BACKEND_URL === 'production') {
  disableReactDevTools();
}



ReactDOM.render(
  <React.StrictMode>
    <AuthState>
      <App />
    </AuthState>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
