import React from 'react';
// import { Link } from "react-router-dom";
// import Call from '../../config/Call'
// import Spinner from '../../components/base/spiner';
import {TitleLayout} from '../../components/base/TitleLayout'
// import { Select } from 'antd';
import DataTableComp from '../../components/produccion/autoriaLotes/dataTableComp'
import DataTableCompM from '../../components/produccion/autoriaLotes/dataTableCompMovil'
// import $ from 'jquery';
// import Swal from 'sweetalert2'

const EnviosPendientes=(props) =>{
    let datat = {
        titulo: "Envios pendientes"
    }
    // const [sp, setSp]= useState(false) // MOSTRAR/OCULTAR LOADER

    const columns=[
        {
            name: 'Seguimiento',
            cell: row => (
                <div>
                    {row.Seguimiento}
                </div>
            ),
            filterable: true,
        },
        {
            name: 'Documento',
            cell: row => (
                <div>
                    {row.plu}
                </div>
            ),
            filterable: true,
        },
        {
            name: 'Destinatario',
            cell: row => (
                <div>
                    {row.plu}
                </div>
            ),
            filterable: true,
        },
        {
            name: 'Recoger en tienda',
            cell: row => (
                <div>
                    {row.plu}
                </div>
            ),
            filterable: true,
        },
        {
            name: 'Ciudad',
            cell: row => (
                <div>
                    {row.plu}
                </div>
            ),
            filterable: true,
        },
        {
            name: 'Departamento',
            cell: row => (
                <div>
                    {row.plu}
                </div>
            ),
            filterable: true,
        },
        {
            name: 'Almacén',
            cell: row => (
                <div>
                    {row.plu}
                </div>
            ),
            filterable: true,
        },
        {
            name: 'Origen',
            cell: row => (
                <div>
                    {row.plu}
                </div>
            ),
            filterable: true,
        },
        {
            name: 'Fecha',
            cell: row => (
                <div>
                    {row.plu}
                </div>
            ),
            filterable: true,
        },
        {
            name: 'Generar',
            cell: row => (
                <div>
                    {row.plu}
                </div>
            ),
            filterable: true,
        },
    ]
    const columnsMovil=[
        {
            name: 'Seguimiento',
            cell: row => (
                <div>
                    {row.Seguimiento}
                </div>
            ),
            filterable: true,
        },
        {
            name: 'Documento',
            cell: row => (
                <div>
                    {row.plu}
                </div>
            ),
            filterable: true,
        },
    ]

    const datoTabla=[
        {
            seguimiento:''
        }
    ]
    const expandedComponent=()=>{
        return(
            <div className='py-2 d-block'>
                <div>Destinatario:</div>
                <div>Recoger en tienda:</div>
                <div>Ciudad:</div>
                <div>Departamento:</div>
                <div>Almacén:</div>
                <div>Origen:</div>
                <div>Fecha:</div>
                <div>Generar:</div>
            </div>
        )
    }
    return(
        <div className='pb-4'>
            {/* { sp ? <Spinner /> : ''}  LOADER */}
            <TitleLayout data={datat} clase={"text-left"} />
            <div className='bg-white p-4 col-lg-12 col-md-12 col-sm-12 mx-auto' style={{height:'auto'}}>
                <div className='py-2 ocultar-movil'>
                    <DataTableComp columns={columns&& columns} data={datoTabla&& datoTabla} paginationPerPage={20} />
                </div>
                <div className='py-2 mostrar-movil'>
                    <DataTableCompM  columns={columnsMovil&& columnsMovil} data={datoTabla&& datoTabla} expandedComponent={expandedComponent} />
                </div>

            </div>
        </div>

    )
}
export default EnviosPendientes;