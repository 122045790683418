import React, {useState,useEffect} from 'react';
import './comunidad.css';
import Call from '../../config/Call'
import CardUsuario  from './CardUsuario.js';


export const Comunidad = (props) => {
    const [lUsuarios, setLusuarios] = useState([])

    const GetUserList = async() =>{
      const res = await Call('GET', '/api/usuarios/comunidad/lista', true, null, false)
      console.log(res)
      setLusuarios(res.data.data)
  }

  useEffect(()=>{
    GetUserList()
    
  }, [])

    return (
        <>
         <div classNameName="container">
         <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-sm-8 col-lg-6">
            <div className="section_heading text-center wow fadeInUp" data-wow-delay="0.2s" >
              <h3> <span> COMUNIDAD BIG JOHN</span></h3>
              Hemos creado este espacio para que conozcas un poco mas las personas que conforman de nuestra comunidad
              
            </div>
          </div>
        </div>
        
        <div className="container">
        <div className="row">
          <div className="col-12" >
          {
            lUsuarios && lUsuarios.map((area, indexxe)=>(
                <div className="col-12" >
                <div className="section_heading text-center wow fadeInUp" data-wow-delay="0.2s" >
                    <h3>{area.nombre}</h3>
                    <div className='row'>
                    {area.usuarios && area.usuarios.map((user, index)=>(
                      <CardUsuario props={user}/>
                    ))
                    }
                    </div>
                    <div className="line"></div>
                  </div>
                  </div>
            ))
          }
          </div>
        </div>
        </div>
      </div>
        </div>
           
        </>
    );
};