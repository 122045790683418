import React, { useContext} from 'react';
import {Switch, Route, BrowserRouter as Router, Redirect  } from 'react-router-dom';

// layout
import { LayoutMain } from '../layouts/LayoutMain';
import { Login } from '../layouts/Login';


// context
import AuthContext from '../contexts/auth/AuthContext';


const LayoutMainRoutes = (props) => {   
    const {isAuthenticated} = useContext(AuthContext)

    const requireAuthLayoutMain = (Component) => {
        if (isAuthenticated){
          return (Component)
        }else{
          let route = "/Login"
          if(window.location.pathname!== ''){
            route = route+"?next="+window.location.pathname
          }
          return (<Redirect  to={route}/>)
        }
    }

  return (
      <Router>
        <Switch>
          <Route exact path='/Login' component={Login}/>
          <Route path='/' render={() => requireAuthLayoutMain(<LayoutMain/>)} />
          {/* <Route path='/' component={LayoutMain} /> */}
        </Switch>
      </Router>
  )
};

export {
  LayoutMainRoutes
}
