import React, {useState , useEffect } from 'react'
import { Link } from "react-router-dom";
import Spinner from '../../base/spiner'
import { TitleLayout } from '../../base/TitleLayout'
import Call from '../../../config/Call'
import Swal from 'sweetalert2'
import { Upload,message } from "antd";

const Predistribucion = () => {

    const [sp, setSp] = useState(false)
    let datat = {
        titulo: "Predistribución" //SECTION TITLE
    }
    const [data, setData] = useState({previewVisible: false, previewImage: '', previewTitle: '', fileList:[]})
    const [showModalDetail, setShowModalDetail]= useState(false)
    

    //UPLOAD FILE

    const iconRender = (file) => {
        // const isJPG = file.type === 'image/jpeg';
        const isTXT = file.type === 'text/plain'
        const isPDF = file.type === 'application/pdf' 
        const isCSV = file.type === 'text/csv'
        const isXLSX = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        const isPWP = file.type === 'application/pdf'
        const isWORD = file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        const isZIP = file.type === 'application/x-zip-compressed'
        const isMP4 = file.type === 'video/mp4'
        if(isTXT){
            return (<i className="fas inpuload fa-file-alt fa-2xl justify-content-center"/>)
        }
        if(isPDF){
            return (<i className="fas inpuload fa-file-pdf fa-2xl justify-content-center"/>)
        }
        if(isXLSX){
            return (<i className="fas inpuload fa-file-excel fa-2xl justify-content-center"/>)
        }
        if(isPWP){
            return (<i className="fas inpuload fa-file-powerpoint fa-2xl justify-content-center"/>)
        }
        if(isWORD){
            return (<i className="fas inpuload fa-file-word fa-2xl justify-content-center"/>)
        }
        if(isCSV){
            return (<i className="fas inpuload fa-file-csv fa-2xl justify-content-center"/>)
        }
        if(isZIP){
            return (<i className="fas inpuload fa-file-archive fa-2xl justify-content-center"/>)
        }
        if(isMP4){
            return (<i className="fas inpuload fa-file-video fa-2xl"/>)
        }
    }
    const onChangeImg = async ({ fileList }) =>{ 
        
        if(((fileList[fileList.length-1] && fileList[fileList.length-1].size) && ((fileList[fileList.length-1].size/1024/1024))> 8)){
            message.error('El archivo debe ser menor a 8M');
        }else if((fileList[fileList.length-1] && fileList[fileList.length-1].type === 'application/x-msdownload') || (fileList[fileList.length-1] && fileList[fileList.length-1].type === '')){
            message.error('El formato del archivo no es permitido');
        }else{
            setData({ fileList })
        }   
    }
    const beforeUpload = (file) => {
        // const isJPG = file.type === 'image/jpeg';
        return false;
    }
    
    const confirmPresit=async()=>{
        Swal.fire({
            title: "",
            text: "¿Desea confirmar esta predistribución?",
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Aceptar",
            cancelButtonText: "Cancelar"
          }).then(async(result) => {
            if (result.isConfirmed) {
                var file = data.fileList[0].originFileObj
                let formdata = new FormData()
                formdata.append('file',file)
                try {
                    setSp(true)
                    const res= await Call('POST','/api/predistribucion/cargar', true,formdata, true)
                    
                    if(res.data.code === 1){
                        setSp(false)
                        setData({
                            ...data,
                            fileList:[]
                        })
                       
                        setShowModalDetail(false)
                        Swal.fire({
                            title: "",
                            text: `${res.data.message}`,
                            icon: "success",
                            confirmButtonText: 'Aceptar'
                          });

                    }else{
                        setSp(false) 
                        Swal.fire({
                            title: "",
                            text: `${res.data.message}`,
                            icon: "error"
                          });
                    }
                    
                } catch (error) {
                    setSp(false) 
                }
            }
          });
    }
  return (
    <div>
      { sp ? <Spinner /> : ''}  {/*LOADER*/}
      <TitleLayout data={datat} clase={"text-left"} /> {/*-----TITULO VISTA------*/}
            <div className='bg-white col-lg-8 col-md-8 col-sm-12 mx-auto p-3' style={{minHeight:'60vh', paddingBottom:'1rem'}}>
                {
                    showModalDetail &&
                    <>
                        <div className="modal fade d-block show" id="exampleModalScrollable" tabIndex="-1" role="dialog" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
                                <div className="modal-content">
                                <div className="modal-header py-1">
                                    <h5 className="modal-title" id="exampleModalScrollableTitle">Generar lote</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>{setShowModalDetail(false); setData({...data, fileList:[]})}}>
                                    <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body text-center">
                                <div>Carga un archivo para generar un lote o predistribución </div>
                                    <div className='mt-4 justify-content-center d-flex mx-auto w-100 flex-wrap gap-c5' >
                                        <div className='cursor-pointer btn-sec height-fit text-center'> 
                                            <Link to={`/assets/plantillas/predistribucion/plantilla.xlsx`} 
                                                target="_blank" 
                                                className="text-decoration-n c-sec"
                                                download>
                                                    <i className="fas fa-download m-0"></i>
                                                </Link>
                                        </div>
                                        <div className='text-center'>
                                            <Upload 
                                                accept=".xls, .xlsx"
                                                maxCount={1}
                                                fileList={data.fileList}
                                                iconRender={iconRender}
                                                onChange={onChangeImg}
                                                beforeUpload={beforeUpload} 
                                                className='fit-content'
                                            >
                                                <div className='mx-0 btn-sec d-flex align-items-center justify-content-start btn-info-outline fs-16 fit-content'
                                                    style={{color: 'white', background: '#3abaf4', paddingLeft: '1rem', paddingRight: '1rem'}}>
                                                    <i className="fas fa-upload fs-16 mr-2"></i> Cargar archivo
                                                </div>
                                                
                                            </Upload>
                                        </div>
                                    </div>
                                    {   data.fileList&& data.fileList.length>0 &&
                                        <div className='mt-4 text-center'>
                                            <span onClick={()=>{confirmPresit()}} className='cursor-pointer rounded btn-new-concep py-2 px-3'>Confirmar</span>
                                        </div>
                                    }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-backdrop fade show" style={{zIndex:'1000'}}></div>
                    </>
                }
               
                <section className='text-center mt-4 mb-4'>                    
                    <div className='cursor-pointer btn-general-red' onClick={()=>{setShowModalDetail(true)}}>Cargar</div>    
                </section>
            </div>

    </div>
  )
}

export default Predistribucion
