import './App.css';
// import "antd/dist/antd.css";

import { LayoutMainRoutes } from './Routes/LayoutMainRoutes.js'

function App() {
  return (
    <LayoutMainRoutes/>
  );
}

export default App;
