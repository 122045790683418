import React, {useState,useEffect,useContext} from 'react';
import {TitleLayout} from '../../base/TitleLayout'
import Spinner from '../../base/spiner';
import Call from '../../../config/Call'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2';
import { PDFViewer, PDFDownloadLink   } from '@react-pdf/renderer'
import { PdfSpeaker } from "./pdfSpeaker";
import AuthContext from '../../../contexts/auth/AuthContext';

import $ from 'jquery';

const Habladores = (props)=>{

    let datat = {
        titulo: "Crear habladores"
    }
    const { user } = useContext(AuthContext) // INFORMACION DE USUARIO
    const [sp, setSp] = useState(false)// SPINNER
    const [referencia, setReferencia] = useState('')
    const [lRef, SetLRef] = useState({ref:[]})
    const [habilitar, sethabilitar] = useState(true)
    const [infoModal, setInfoModal] =useState([])
    const [showModalPrint, setShowModalPrint] = useState(false)
    let list = []
    let idSpeaker= parseInt(props.match.params.id)

    useEffect(()=>{
        if(idSpeaker){
            verSpeaker()
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(()=>{
        
    }, [lRef, infoModal]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleText = (e)=>{
        setReferencia(e.target.value)
    }

    const consultarUbicaciones = async(dato)=>{
        
        let result= lRef.ref.filter(item => (item.codigo === dato.target.value || item.referencia === dato.target.value))
        
        if(dato.target.value.trim() !== ''){
            if(result.length === 0){
                setSp(true)
                const res = await Call('GET', '/api/habladores/buscar/'+dato.target.value.trim(), true, null, false)
                if(res.data.code === 1){
                    if(res.data.data.length > 0){
                        let result2= lRef.ref.filter(item => (item.referencia === res.data.data[0].ref))
                        if(result2.length> 0){
                            setSp(false)
                            Swal.fire({
                                icon: 'info',
                                title: '',
                                text: `La referencia ${dato.target.value} ya fue ingresa, por favor ingresa otra`,
                                confirmButtonText:'Aceptar',
                            })
                            setReferencia('')
                        }else{
                            list= lRef.ref.concat(list)
                            sethabilitar(false)
                            let d= {
                            referencia: res.data.data[0].ref,
                            nombre: res.data.data[0].nombre,
                            precio:  res.data.data[0].precio,
                            orden: res.data.data[0].orden,
                            codigo: referencia
                            }
                            list.push(d)        
                            SetLRef({...lRef,ref:list})
                            setReferencia('')
                            if(list.length <7){
                                sethabilitar(true)
                                setTimeout(function(){
                                    $('#codigo').focus();
                                }, 100);
                            }else{
                                sethabilitar(false)
                            }
                            
                            setSp(false)
                        }
                       
                    }else{
                        setSp(false)
                        Swal.fire({
                            icon: 'info',
                            title: '',
                            text: 'No se encontró la referencia',
                            confirmButtonText:'Aceptar',
                        })
                        setReferencia('')
                        if(lRef.ref.length < 7){
                            setTimeout(function(){
                                $('#codigo').focus();
                            }, 100);
                        }
                    }
                }
            }else{
                Swal.fire({
                    icon: 'info',
                    title: '',
                    text: `La referencia ${dato.target.value} ya fue ingresa, por favor ingresa otra`,
                    confirmButtonText:'Aceptar',
                })
                setReferencia('')
                if(lRef.ref.length < 7){
                    setTimeout(function(){
                        $('#codigo').focus();
                    }, 100);
                }
            }
        }

    }
    const deleteSpeaker= async(row, data)=>{
        
        let lista = lRef.ref
        lista.splice(data, 1)
        if(lista.length < 7){
            sethabilitar(true)
        }
        SetLRef({...lRef, ref:lista})
    }
    
    const generateSpeaker = async()=>{
       
     
        setSp(true)
        if(idSpeaker){
            let arr=[]
            for(let i=0; i<lRef.ref.length; i++){
                let d={
                    nombre:lRef.ref[i].nombre,
                    orden: lRef.ref[i].orden,
                    precio:lRef.ref[i].precio,
                    referencia:lRef.ref[i].referencia,
                    tienda: user.centro_costos
                }
                arr.push(d)
            }
            let datos={
                editar:{
                    hablador:{
                        id: idSpeaker,
                        cantidad: lRef.ref.length
                    },
                    detalles:arr
                }
            }
            const res = await Call('POST', '/api/habladores/editar', true, datos, false)
            
            if(res.data.code === 1){
                setSp(false)
                setInfoModal(res.data.data)
                setShowModalPrint(true)
                
            }else{
                setSp(false)
                Swal.fire({
                    icon: 'error',
                    title: '',
                    text: `Hubo un error: '${res.data.message}'`,
                    confirmButtonText:'Aceptar',
                })
            }
        }else{
            let arr=[]
            for(let i=0; i<lRef.ref.length; i++){
                let d={
                    nombre:lRef.ref[i].nombre,
                    orden: lRef.ref[i].orden,
                    precio:lRef.ref[i].precio,
                    referencia:lRef.ref[i].referencia,
                    tienda: user.centro_costos
                }
                arr.push(d)
            }
            let datos={
                guardar:{
                    hablador:{
                        cantidad: lRef.ref.length
                    },
                    detalles:arr
                }
            }
            const res = await Call('POST', '/api/habladores/guardar', true, datos, false)
            
            if(res.data.code === 1){
                setSp(false)
                setInfoModal(res.data.data)
                setShowModalPrint(true)
            }else{
                setSp(false)
                Swal.fire({
                    icon: 'error',
                    title: '',
                    text: `Hubo un error: '${res.data.message}'`,
                    confirmButtonText:'Aceptar',
                })
            }
        }
        
        
        
    }      
    const closedModal =()=>{
        setShowModalPrint(false)
        props.history.push(`/tiendas/habladores`)

    }
    //  --------- EDIT SPEAKER --------------
    const verSpeaker= async ()=>{
        const res = await Call('GET','/api/habladores/imprimir/'+idSpeaker, true, null, false )
        if(res.data.data.length === 7){
            sethabilitar(false)
        }
        SetLRef({...lRef, ref: res.data.data})

    }
    
    return(
        <div>
             { sp ? <Spinner /> : ''}  {/*LOADER*/}
             <TitleLayout data={datat} clase={"text-left"} icon={true} seccion={'habladores'}/> {/*-----TITULO VISTA------*/}
             <div className='bg-white col-lg-8 col-md-8 col-sm-12 mx-auto p-3' style={{minHeight:'60vh', paddingBottom:'1rem'}}>
                {/* <div className='btn-buscar cursor-pointer fs-16 mr-0' onClick={createTalkers}>Crear habladores</div> */}
                { showModalPrint &&
                    <>
                        <div className="modal show" tabIndex="-1" style={{display:'block'}}>
                            <div className="modal-dialog modal-dialog-centered modal-lg ">
                                <div className="modal-content">
                                <div className="modal-header py-2">
                                    <span className="fs-18 fw-600">Hablador</span>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={closedModal}>
                                    <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className='ocultar-movil'>
                                        <PDFViewer style={{width:'100%', height:'60vh'}}> <PdfSpeaker data={infoModal&& infoModal} tienda={user.centro_costos} /></PDFViewer>
                                    </div>
                                    <div className='mostrar-movil'>
                                        <PDFDownloadLink document={<PdfSpeaker data={infoModal&& infoModal}/>} fileName='hablador' style={{textDecoration:'none'}}>
                                            <div className='btn-buscar cursor-pointer fs-16' style={{textDecoration:'none'}}>
                                                Descargar Hablador
                                            </div>
                                        </PDFDownloadLink>
                                    </div>
                                        
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-backdrop fade show"></div> 
                    </>
                }
                {/* ----- INICIO LISTADO HABLADORES -------- */}
                <div>
                    <div className='text-danger'><Link to='/tiendas/habladores' className='fs-14 pb-3 text-danger'><i className="fas fa-long-arrow-alt-left fs-18"></i>Atrás</Link></div>
                    <div className='label-decoracion mt-4'>
                        <span>Leer código</span>
                    </div>
                    {
                        // lRef.ref.length < 7 &&
                        <input type="text" 
                        className='form-control form-control-sm form-color form-colord' 
                            id="codigo"
                            name="codigo"
                            value={referencia}
                            placeholder="Leer código" 
                            onChange={(e)=>(handleText(e))}
                            onKeyUp={(e)=>(e.keyCode === 13 ?consultarUbicaciones(e): '')}
                            disabled={!habilitar}
                        />
                        
                    }
                     { 
                            lRef.ref.length > 0 &&
                            <div className='m-auto fit-content table-responsive'>
                                <table className="table mt-4">
                                        <thead>
                                            <tr>
                                                <th scope="col" className='c-sec'>Referencia</th>
                                                <th scope="col" className='c-sec'>Nombre</th>
                                                <th scope="col" className='c-sec'>Precio</th>
                                                <th scope="col" className='c-sec'>Eliminar</th>
                                            </tr>
                                        </thead>
                                    <tbody>
                                {

                                        lRef.ref.map((a, indexa)=>(
                                            <tr key={indexa}>
                                                <td><span>{a.referencia}</span></td>
                                                <td>{a.nombre}</td>
                                                <td>${new Intl.NumberFormat('de-DE').format(a.precio)}</td>
                                                <td><div className='ml-2 cursor-pointer' onClick={()=>deleteSpeaker(a, indexa) }><i className="fas fa-times fs-16 text-danger"></i></div></td>
                                            </tr>
                                        ))
                                }
                                
                            
                            </tbody>
                        </table>
                    </div>
                    }
                    {
                        lRef.ref.length > 0 &&
                        <div className='text-center m-auto'>
                            <div className='btn-buscar cursor-pointer fs-16' onClick={generateSpeaker}>{idSpeaker? 'Editar': 'Generar'}</div>
                        </div>
                    }
                </div>
                {/* ----- FIN LISTADO HABLADORES -------- */}
             </div>
        </div>
    )
}
export default Habladores;