import React, {useState/*useContext,useRef,useEffect*/} from 'react';
import {TitleLayout} from '../../base/TitleLayout.js'
// import axios from 'axios';
// import Call from '../../../config/Call'
// import $ from 'jquery';
// import Swal from 'sweetalert2'
import Spinner from '../../base/spiner';
import { Select, Upload, Button } from 'antd';
import DataTableComp from '../../produccion/autoriaLotes/dataTableComp.js';

const { Option } = Select;

const Abastecimiento = (props) =>{

    const [sp, setSp] = useState(false)
    const [tipoPreditribucion, setTipoPresdistribucion] = useState(null)
    const [showModalCedi, setShowModalCedi] = useState(false)
    const [showModalActualizar, setShowModalActualizar] = useState(false)
    const [showDetallePred, setShowDetallePred] = useState(false)
    let datat = {
        titulo: "Predistribución"
    }

    const selectPredistribucion = (tipo) =>{
        // if(tipo !== tipoPreditribucion){
            setTipoPresdistribucion(tipo)
            setShowModalCedi(true)
        // }
        
    }
    const detallePred =(detalle)=>{
        // console.log('detallePred', detalle)
        setShowDetallePred(true)
            // <div className="modal-backdrop fade show"></div> 
            // </>
    }
    const verActualizar=(detalle)=>{
        console.log('ver actualizar', detalle )
        setShowModalActualizar(true)
    }

    const columns=[
        {
            name: 'Modelo',
            cell: row => (
                <div>
                    {row.modelo}
                </div>
            ),
            filterable: true,
        },
        {
            name: 'Cantidad',
            cell: row => (
                <div>
                    {row.cantidad}
                </div>
            ),
            filterable: true,
        },
        {
            name: 'Detalle',
            cell: row => (
                <div>
                    <span className='det btn btn-outline-bj btn-block' data-toggle="modal" data-target="#exampleModalDetalle" onClick={()=>{detallePred(row.modelo)}}>
                        Detalle
                    </span>
                    
                </div>
            ),
            filterable: true,
        },
    ]
    const columnsH=[
        {
            name: 'Id predistribución',
            cell: row => (
                <div>
                    {row.id_predistribucion}
                </div>
            ),
            filterable: true,
        },
        {
            name: 'Fecha',
            cell: row => (
                <div>
                    {row.fecha}
                </div>
            ),
            filterable: true,
        },
        {
            name: 'Acción',
            cell: row => (
                <div className='d-flex flex-wrap'>
                    <div className='btn btn-outline-success cursor-pointer m-1'>Descargar</div>
                    <div className='btn btn-outline-bj cursor-pointer m-1' data-toggle="modal" data-target="#exampleModalActualizar" onClick={()=>{verActualizar(row.id_predistribucion)}}>Actualizar</div>
                </div>
            ),
            filterable: true,
        },
    ]
    const columnsL=[
        {
            name: 'Id predistribución',
            // cell: row => (
            //     <div>
            //         {row.ubicacion.cod_ubicacion}
            //     </div>
            // ),
            filterable: true,
        },
        {
            name: 'Fecha',
            // cell: row => (
            //     <div>
            //         {row.ubicacion.cod_ubicacion}
            //     </div>
            // ),
            filterable: true,
        },
        
    ]
    const columnsDetalle=[
        {
            name: 'Código',
            // cell: row => (
            //     <div>
            //         {row.ubicacion.cod_ubicacion}
            //     </div>
            // ),
            filterable: true,
        },
        {
            name: 'Tienda',
            // cell: row => (
            //     <div>
            //         {row.ubicacion.cod_ubicacion}
            //     </div>
            // ),
            filterable: true,
        },
        {
            name: 'Referencia',
            // cell: row => (
            //     <div>
            //         {row.ubicacion.cod_ubicacion}
            //     </div>
            // ),
            filterable: true,
        },
        {
            name: 'Cantidad',
            // cell: row => (
            //     <div>
            //         {row.ubicacion.cod_ubicacion}
            //     </div>
            // ),
            filterable: true,
        },
    ]
    const datacomp=[
        {
            modelo: '311272',
            cantidad: 69,
        }
    ]
    const datacompH=[
        {
            id_predistribucion: '57971',
            fecha: '2022-10-07 11:23:28.727000',
            accion: '',
        }
    ]
    const ModalCedis=()=>{
        return (
        <>
        <div className="modal fade show" id="exampleModal" tabndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{display:'block'}}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                <div className="modal-header py-2">
                    <h5 className="modal-title" id="exampleModalLabel">{(tipoPreditribucion === 'basicos' || tipoPreditribucion === 'accesorios' )? 'Seleccionar CEDIS': (tipoPreditribucion === 'linea'? 'Seleccionar linea': 'Cargar documento')}</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close fs-18" onClick={()=>setShowModalCedi(false) }>
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className='w-80 mt-2 mx-auto'>
                        {
                            (tipoPreditribucion === 'basicos' || tipoPreditribucion === 'accesorios' ) &&
                            <div>
                                <div className='list-bodegas'>
                                    <div className="form-group">
                                        <label className='fs-13'>Origen</label>
                                        <Select
                                            allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Please select"
                                            // defaultValue={['a10', 'c12']}
                                            // onChange={handleChange}
                                            >
                                                <Option value="jack">Jack</Option>
                                                <Option value="lucy">Lucy</Option>
                                                <Option value="disabled" disabled>
                                                    Disabled
                                                </Option>
                                                <Option value="Yiminghe">yiminghe</Option>
                                            </Select>
                                    </div>
                                    <div className="form-group">
                                        <label className='fs-13'>Destino</label>
                                        <Select
                                            mode="multiple"
                                            allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Please select"
                                            defaultValue={['a10', 'c12']}
                                            // onChange={handleChange}
                                            >
                                                <Option value="jack">Jack</Option>
                                                <Option value="lucy">Lucy</Option>
                                                <Option value="disabled" disabled>
                                                    Disabled
                                                </Option>
                                                <Option value="Yiminghe">yiminghe</Option>
                                            </Select>
                                    </div>  
                            </div>
                            </div>
                            
                        }
                        {
                            tipoPreditribucion === 'linea' &&
                            <div>
                                <div className="form-group d-block">
                                        <label className='fs-13'>Tipo</label>
                                        <div>
                                        <Select
                                            allowClear
                                            style={{ width: '100%' }}
                                            className="select-pred"
                                            placeholder="Please select"
                                            // defaultValue={['a10', 'c12']}
                                            // onChange={handleChange}
                                            >
                                                <Option value="jack">Normal</Option>
                                                <Option value="jack">Extendida</Option>
                                            </Select>
                                        </div>
                                    </div>  

                            </div>
                        }
                        {
                            tipoPreditribucion === 'carga' &&
                            <div className='w-80 mx-auto'>
                                <Upload className='select-pred w-100'>
                                    <Button className='select-pred w-100' >
                                    Select File</Button>
                                </Upload>
                            </div>
                        }
                    <div className='text-center mt-5 pb-3'>
                            <span className='px-4 btn-buscar cursor-pointer'>Generar</span>
                    </div>
                </div>
                </div>
                {/* <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary">Save changes</button>
                </div> */}
                </div>
            </div>
            </div>
            <div className="modal-backdrop fade show"></div> 
            </>
        )
    }
    return(
        <div className='pb-4'>
            { sp ? <Spinner /> : ''}  {/*LOADER*/}
            {showModalCedi? <ModalCedis /> : ''}
            <TitleLayout data={datat} clase={"text-left"} />
            {
                showDetallePred &&
                <>
                    <div className="modal fade" id="exampleModalDetalle" tabndex="-1" aria-labelledby="exampleModalDetalle" aria-hidden="true" style={{display:'block',background:'transparent'}}>
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                            <div className="modal-header py-2">
                                <h5 className="modal-title" id="exampleModalDetalle">{(tipoPreditribucion === 'basicos' || tipoPreditribucion === 'accesorios' )? 'Seleccionar CEDIS': (tipoPreditribucion === 'linea'? 'Seleccionar linea': 'Cargar documento')}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close fs-18" onClick={()=>setShowModalCedi(false) }>
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                            <div className='mt-2 mx-auto mercarTable'>
                                <DataTableComp  columns={columnsDetalle} data={datacomp} className="mercarTable"/>
                               </div>
                               </div>
                    </div>
                </div>
                </div>
                </>
            }
            {
                showModalActualizar &&
                <>
                    <div className="modal fade" id="exampleModalActualizar" tabndex="-1" aria-labelledby="exampleModalActualizar" aria-hidden="true" style={{display:'block', background:'transparent'}}>
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                            <div className="modal-header py-2">
                                <h5 className="modal-title" id="exampleModalActualizar">Actualización</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close fs-18" onClick={()=>setShowModalCedi(false) }>
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                            <div className='mt-2 mx-auto mercarTable'>
                                <DataTableComp  columns={columnsDetalle} data={datacomp} className="mercarTable" />
                               </div>
                               </div>
                    </div>
                </div>
                </div>
                </>
            }
            <div className='bg-white p-4'>
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true" >Generar predistribución</a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Editar predistribución</a>
                    </li>
                    <li className="nav-item" role="presentation">
                        <a className="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">Generar lote</a>
                    </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                    {/* ---- INICIO GENERAR PREDISTRIBUCION ---- */}
                    <div className="tab-pane fade show active w-90" id="home" role="tabpanel" aria-labelledby="home-tab">
                        <div className='tipo-predistribucion row px-4'>
                            <div className={`btn-predistribucion col-3-lg col-10-sm ${tipoPreditribucion === 'basicos' ? 'active': ''}`} onClick={()=>{selectPredistribucion('basicos')}}>
                                <i className="fas fa-tshirt"></i> Básicos
                            </div>
                            <div className={`btn-predistribucion col-3-lg col-10-sm ${tipoPreditribucion === 'linea' ? 'active': ''}`} onClick={()=>{selectPredistribucion('linea')}}>
                                <i className="fab fa-shirtsinbulk"></i> Línea
                            </div>
                            <div className={`btn-predistribucion col-3-lg col-10-sm ${tipoPreditribucion === 'accesorios' ? 'active': ''}`} onClick={()=>{selectPredistribucion('accesorios')}}>
                                <i className="fas fa-shopping-bag"></i> Accesorios
                            </div>
                            <div className={`btn-predistribucion col-3-lg col-10-sm ${tipoPreditribucion === 'carga' ? 'active': ''}`} onClick={()=>{selectPredistribucion('carga')}}>
                                <i className="fas fa-file"></i> Carga
                            </div>
                        </div>
                        <div className='w-80 mx-auto'>
                            <hr className='w-100 mt-4' />
                            <div className='mercarTable'>
                                <DataTableComp  columns={columns} data={datacomp} className="mercarTable" />
                            </div>
                        </div>

                    </div>
                    {/* ------ FIN GENERAR PREDISTRIBUCION ----- */}
                    {/* ------- INICIO EDITAR PREDISTRIBUCOIN ---- */}
                    <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                        <div className=' mx-auto'>
                            <div>
                                <div className="form-group d-block">
                                    <label className='fs-13'>Seleccionar Cedi</label>
                                    <div>
                                        <Select
                                        className='select-pred'
                                        placeholder="Please select"
                                        style={{width: '35%'}}
                                        // defaultValue={['a10', 'c12']}
                                        // onChange={handleChange}
                                        >
                                            <Option value="jack">Jack</Option>
                                            <Option value="lucy">Lucy</Option>
                                            <Option value="disabled" disabled>
                                                Disabled
                                            </Option>
                                            <Option value="Yiminghe">yiminghe</Option>
                                        </Select>
                                    </div>
                                </div>
                                <div className='row mx-auto'>
                                    <div className='col-lg-10 col-md-10 col-sm-12 px-0 mx-auto'>
                                        <div className='title-pred'>Historico</div>
                                        <div className='mx-auto'>
                                            <div  className='mx-auto mercarTable '>
                                                <DataTableComp  columns={columnsH} data={datacompH}  className="mercarTable"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                    {/* ------- FIN EDITAR PREDISTRIBUCION ------- */}
                    {/* ------- INICIO GENERAR LOTE ------------- */}
                    <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                        <div className='w-85 mx-auto'>
                            <div className="form-group d-block">
                                <label className='fs-13'>Seleccionar Cedi</label>
                                <div>
                                    <Select
                                    className='select-pred'
                                    placeholder="Please select"
                                    style={{width:'35%'}}
                                    // defaultValue={['a10', 'c12']}
                                    // onChange={handleChange}
                                    >
                                        <Option value="jack">Jack</Option>
                                        <Option value="lucy">Lucy</Option>
                                        <Option value="disabled" disabled>
                                            Disabled
                                        </Option>
                                        <Option value="Yiminghe">yiminghe</Option>
                                    </Select>
                                </div>
                            </div>
                            <div className='row mx-auto'>
                                <div className='col-lg-10 col-md-10 col-sm-12 px-0'>
                                    <div className='title-pred'>Predistribuciones disponibles</div>
                                    <div>
                                        <div className='mercarTable'>
                                            <DataTableComp  columns={columnsL} data={datacomp} className="mercarTable"/>
                                        </div>
                                        <div className='text-center mt-5'>
                                            <span className='px-4 btn-buscar cursor-pointer'>Generar</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* -------- FIN GENERAR LOTE --------------- */}
                </div>
            </div> 
        </div>
    )
}
export default Abastecimiento;