import React from 'react';
import {FormViewResponseDetail} from '../formularios/FormViewResponseDetail';
import $ from 'jquery';

const ViewResponse =({modalDetail, datosForm})=>{
    
    const infoTareas =()=>{
        $('#staticBackdrop').modal('hide')
    }
    return (
        <>
        <div className="modal" id="staticBackdrop" data-backdrop="static" style={{display:'block'}}>
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg m-respuestas" >
                <div className="modal-content">
                    <div className="outline-n">
                        <button type="button" className="close p-3 cursor-pointer" data-dismiss="modal" aria-label="Close" onClick={()=>{infoTareas();modalDetail()}}>
                        <span className='fs-30 outline-n' aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body modal-form" style={{height: '80vh', overflowY:'auto'}}>
                        <FormViewResponseDetail  respuestas_formulario={datosForm&& datosForm} modalDetail={modalDetail} />                       
                    </div>
                </div>
            </div>
            
        </div> 
        <div className="modal-backdrop show"></div>
        </>
    )
}

export default ViewResponse;