import React, {useState,useEffect, useContext} from 'react';
import { Link } from "react-router-dom";
import {TitleLayout} from '../base/TitleLayout'
import Spinner from '../base/spiner';
import { Select, Progress  } from 'antd';
import Swal from 'sweetalert2'
import Call from '../../config/Call'
import AuthContext from '../../contexts/auth/AuthContext';
import { Line  } from '@ant-design/charts';
import { Gauge } from '@ant-design/plots';
import moment from 'moment';
import 'moment/locale/es'
import DataTableComp from '../produccion/autoriaLotes/dataTableComp';


const Dashboard =()=>{
    const { user } = useContext(AuthContext) // USER INFORMATION
    const [sp, setSp] = useState(false) //SPINER
    const [listNews, setListNews] = useState([]) // LIST NEWS
    const [listAccess, setlistAccess] = useState([])
    const [userType, setUserType] = useState(null)
    const [viewReady, setViewReady ] = useState(false)
    let [data, setData] = useState([]); // GRAPH DATA
    const [ dataDashboard, setDataDashboard ] = useState(null) // DASHBOARD OVERVIEW
    let [filtro, setFiltro]= useState('mes') // GRAPH FILTER
    const [showErrorEmpty, setShowErrorEmpty] = useState(false)
    const [ typeCompliance, setTypeCompliance ] = useState('day')
    const [ typeComplianceStore, setTypeComplianceStore ] = useState('day')
    
    let datat = {
        titulo: `¡Hola ${user&& user.apellidos_nombres.toLowerCase()}!` // USER NAME IN MESSAGE WELCOME
    }
    useEffect(() => {
        //INIT DATA
      initialData();
    }, []);
  
const handleChange=(e)=>{
    // CHANGE GRAPH DATA
    if(e === 'anio'){
        setFiltro(e)
        let actual={}
        let last={}
        let dataT=[]
        //REBUILDING GRAPH DATA

        for(let i=0; i<dataDashboard.growth.length; i++){
            
            if((dataDashboard.growth[i].current_year) &&  (i <= moment().month())){
                actual={
                    type: dataDashboard.growth[i].current_year.toString(),
                    month: dataDashboard.growth[i].month,
                    value: dataDashboard.growth[i].growth_current_year
                }
            }
            if((dataDashboard.growth[i].last_year) &&  (i <= moment().month())){
                last={
                    type: dataDashboard.growth[i].last_year.toString(),
                    month: dataDashboard.growth[i].month,
                    value: dataDashboard.growth[i].growth_last_year
                }
            }
            dataT.push(actual, last)
        }
        setData(dataT)
    }
    if(e==='mes'){
        setFiltro(e)
        //REBUILDING GRAPH DATA
        let dataN=[]
            
            let a={}
            let b={}
            for(let i=0; i< dataDashboard.month_sales.length; i++){
                if(dataDashboard.month_sales[i].sales_current_year){
                     a ={
                        day:dataDashboard.month_sales[i].day,
                        value:dataDashboard.month_sales[i].sales_current_year,
                        type:dataDashboard.month_sales[i].current_year.toString()
                    }
                }
                if(dataDashboard.month_sales[i].sales_last_year){
                     b ={
                        day:dataDashboard.month_sales[i].day,
                        value:dataDashboard.month_sales[i].sales_last_year,
                        type: dataDashboard.month_sales[i].last_year.toString()
                    }
                }
                dataN.push(a,b)
            }
            
            setData(dataN)
    }
    if(e=== 'trimester'){
        setFiltro('trimester')
        const fechaActual = moment().month() // ACTUAL DATE
        const mesInicial=moment().month()-2 // LAST THREE MONTHS
        
        //REBUILDING GRAPH DATA
        let a={}
        let b={}
        let monthTrimester=[]
        for (let i = mesInicial; i <= fechaActual; i++) {
            if (dataDashboard.growth[i] && dataDashboard.growth[i].current_year) {
                a = {
                    type: dataDashboard.growth[i].current_year.toString(),
                    month: dataDashboard.growth[i].month,
                    value: dataDashboard.growth[i].growth_current_year
                };
            }
            if (dataDashboard.growth[i] && dataDashboard.growth[i].last_year) {
                b = {
                    type: dataDashboard.growth[i].last_year.toString(),
                    month: dataDashboard.growth[i].month,
                    value: dataDashboard.growth[i].growth_last_year
                };
            }
            if (a && b) {
                monthTrimester.push(a, b);
            }
        }
        
        setData(monthTrimester)
    }
}

    const initialData = async()=> {
        //CHARGING INITIAL DATA
        try {
            setSp(true)
            const res2 = await Call('GET', '/api/homedashboard', true, null, false)
            
            if(res2.data.code === 1){
                setShowErrorEmpty(false)
                setDataDashboard(res2.data && res2.data.data)
                if(res2.data.data.type_user === "administrativo"){
                    setListNews(res2.data.data.news.splice(0,3))
                }else{
                    if(typeof(res2.data.data.month_sales) !== 'string'){

                    
                    let dataN=[]
                    let a={}
                    let b={}
                    for(let i=0; i<res2.data.data.month_sales.length; i++){
                        if(res2.data.data.month_sales[i].sales_current_year){
                            a ={
                                day:res2.data.data.month_sales[i].day,
                                value:res2.data.data.month_sales[i].sales_current_year,
                                type:res2.data.data.month_sales[i].current_year.toString()
                            }
                        }
                        if(res2.data.data.month_sales[i].sales_last_year){
                            b ={
                                day:res2.data.data.month_sales[i].day,
                                value:res2.data.data.month_sales[i].sales_last_year,
                                type: res2.data.data.month_sales[i].last_year.toString()
                            }
                        }
                    dataN.push(a,b)
                    }
                    setData(dataN)
                }
                setUserType(res2.data.data.type_user)
                setUserType('tienda')
                }
                setViewReady(true)
                setSp(false)
                if (res2.data.data.type_user == 'tienda') {
                    const accesosDirectoData = await Call('GET', '/api/acceso_directo', true, null, false)
                    setlistAccess(accesosDirectoData.data.results)
                }

            }else{
                setSp(false)
                setShowErrorEmpty(true)
                Swal.fire({
                    title: '',
                    icon: 'error',
                    html: res2.data.message,
                    focusConfirm: false,
                    confirmButtonText:'Aceptar',
                })
            }
            
        } catch (error) {
            setSp(false)
            Swal.fire({
                title: '',
                icon: 'error',
                html: error,
                focusConfirm: false,
                confirmButtonText:'Aceptar',
            })
        }
    }
    //DRAWING GRAPHS MULTILINE
    const config = {
        data,
        xField: (filtro !== 'mes' ? 'month': 'day'),
        yField: 'value',
        yAxis: {
          label: {
            formatter: (v) =>`${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
          },
        },
        tooltip: {
        formatter: (datum) => {
            return { name: datum.type, value: datum.value + '%' };
        },
        showTitle: false,
        },
        seriesField: 'type',
        color: ['#1979C9', '#D62A0D', '#FAA219'],
    };   

    // Graph gauge DRAWING GRAPHS MULTILINE STORE COMPLIANCE
    
    const config2 = {
        percent: (dataDashboard && (dataDashboard.compliance/100)),
        height:150,
        width:150,
        range: {
          color: 'l(0) 0:#23b99170 1:#23b991',
        },
        
        startAngle: Math.PI,
        endAngle: 2 * Math.PI,
        indicator: null,
        axis: {
            label: {
              formatter(v) {
                return (Number(v) * 100);
              },
            },
            subTickLine: {
              count: 3,
            },
          },
          
        statistic: {
          title: {
            offsetY: -36,
            style: {
              fontSize: '25px',
              color: '#4B535E',
            },
            formatter: () => (dataDashboard&& ((dataDashboard.compliance.substring(0,5)))+'%'),
          },
          content: {
            style: {
              fontSize: '24px',
              lineHeight: '44px',
              color: '#4B535E',
              maxWidth:'100%!important'
            },
            formatter: () => '',
          },
        },
      };
      const config3 = {
        percent: (dataDashboard && (dataDashboard.month_compliance/100)),
        height:150,
        width:150,
        range: {
          color: 'l(0) 0:#23b99170 1:#23b991',
        },
        
        startAngle: Math.PI,
        endAngle: 2 * Math.PI,
        indicator: null,
        axis: {
            label: {
              formatter(v) {
                return (Number(v) * 100);
              },
            },
            subTickLine: {
              count: 3,
            },
          },
          
        statistic: {
          title: {
            offsetY: -36,
            style: {
              fontSize: '25px',
              color: '#4B535E',
            },
            formatter: () => (dataDashboard&& ((dataDashboard.month_compliance.substring(0,5)))+'%'),
          },
          content: {
            style: {
              fontSize: '24px',
              lineHeight: '44px',
              color: '#4B535E',
              maxWidth:'100%!important'
            },
            formatter: () => '',
          },
        },
      };
      // DATA DATATABLE
      const columns =[
        {
            name: 'Nombre',
            cell: row => (
                <div className='text-capitalize text-left'>
                    {row.seller_name.toLowerCase()}
                </div>
            ),
            // width: '170px',
            filterable: true,
            left:true,
        },
        {
            name: '% cumplimiento',
            cell: row => (
                <div style={{width:80}}>
                    <Progress className="dash-saler" percent={parseFloat((typeCompliance ==='day' ?row.seller_compliance : row.month_seller_compliance)).toFixed(2)} status="active" style={{width:'180px'}} />
                </div>
            ),
            // width: '140px',
        }
      ]
      
      const columnMovil=[
        {
            name: 'Nombre',
            cell: row => (
                <div className='text-capitalize text-left'>
                    {row.seller_name.toLowerCase()}
                </div>
            ),
            filterable: true,
        },
        {
            name: '% cumplimiento',
            cell: row => (
                <div style={{width:50}}>
                    <Progress className="dash-saler" percent={typeCompliance === 'day'? row.seller_compliance.substring(0,4) : row.month_seller_compliance.substring(0,4)} status="active" style={{width:'180px'}} />
                </div>
            ),
            // width: '120px',
        }
      ]
      const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };   
    
    return(
        <div className='pb-4'>
            { sp ? <Spinner /> : ''}  {/*LOADER*/}
             <TitleLayout data={datat} clase={"text-left text-capitalize"} icon={false} seccion={'dashboard'} />
            <div className='bg-white p-4 col-lg-12 col-md-12 col-sm-12 mx-auto' style={{height:'auto'}}>
                { showErrorEmpty ? 
                <>
                    <div className='my-5 text-center'>
                        <i className="fas fa-box-open fs-40"></i>
                        <div>En estos momentos no hay datos disponibles</div>
                    </div>
                </>
                
                :
                    <>
                        {
                            viewReady && userType === 'tienda' ?
                            <>
                            {/* //////////////////////////// desktop /////////////// */}
                            
                            {/* init RANKING how are we doing */}
                            <div className='card-general dash1 mb-4'>
                                <h5>Accesos rápidos</h5>
                                <div className='text-left quick-access'>
                                    <Link className="link-formater fs-18 my-3" to='/tareas'><i className="fas fa-tasks fs-18"></i> Tareas</Link>
                                    <Link className="link-formater fs-18 my-3" to='/formularios'><i className="fas fa-check-square fs-18"></i> Formularios</Link>
                                    <a className="link-formater fs-18 my-3" href={`${process.env.REACT_APP_BACKEND_URL}/tiendas/comovamos`}><i className="fas fa-chart-bar fs-18"></i> Cómo vamos</a>

                                    {listAccess.map((access, index) => (
                                        <a key={index} className="link-formater fs-18 my-3" href={access.enlace}>
                                            <i className={`fas ${access.icono} fs-18`}></i> {access.nombre}
                                        </a>
                                    ))}
                                </div>
                            </div>

                                
                                {/* init RANKING how are we doing */}
                                {/*  //////////////// statistics how are we doing ///////////////////////*/}
                                <div className='d-flex flex-wrap dash1 justify-content-between wrapper'>
                                    <div className='card-general'>
                                        {
                                            dataDashboard.compliance !== 'No hay cumplimiento de presupuesto del dia disponible' &&
                                            <>
                                             { typeComplianceStore === 'day'  ? 
                                                    <div
                                                        className='btn-info2 fit-content ml-auto px-2 mb-2' 
                                                        onClick={()=>{setTypeComplianceStore('month')}}
                                                    >
                                                        Ver por mes
                                                    </div> 
                                                    :
                                                    <div
                                                        className='btn-info2 fit-content ml-auto px-2 mb-2' 
                                                        onClick={()=>{setTypeComplianceStore('day')}}
                                                    >
                                                        Ver por día
                                                    </div> 
                                                }
                                            </>
                                        }
                                       
                                            <div>
                                                <h5>Presupuesto <span className='text-capitalize'>{dataDashboard && dataDashboard.store.toLowerCase()}</span></h5>
                                                {
                                                    dataDashboard.compliance !== 'No hay cumplimiento de presupuesto del dia disponible' ?
                                                    <>
                                                        <span className='text-capitalize'>{ typeComplianceStore === 'day' ? moment().format('L') : moment().format("MMMM") }</span>
                                                        {
                                                            typeComplianceStore === 'day' ?
                                                            <Gauge 
                                                                {...config2} 
                                                            />
                                                            :
                                                            <Gauge 
                                                                {...config3} 
                                                            />
                                                        }
                                               
                                                    </>

                                                    : 
                                                    <div className='mx-auto d-block mt-5'>
                                                        <div><i className="fas fa-box-open fs-40 c-sec"></i></div>
                                                        <div className='c-sec mt-2'>En estos momentos no hay datos disponibles</div>
                                                    </div>
                                                }
                                               
                                            </div>
                                        
                                       
                                    </div>
                                    <div className='card-general'>
                                        <h5>Posición Cómo vamos</h5>
                                        <div className={`${typeof(dataDashboard['como_vamos']) !== 'string' ? 'ranking-pos': ''} mt-0`}>
                                            {
                                                 typeof(dataDashboard['como_vamos']) !== 'string' ?
                                                 <>
                                                     <div>
                                                        <img src="/assets/images/iwinner.png" alt="" height={60}/>
                                                    </div>
                                                    <div>
                                                        <h1 className='mb-0'>{dataDashboard&& dataDashboard['como_vamos'].store_position}</h1>
                                                    </div>
                                                 </>
                                                 :
                                                    <div className='mx-auto d-block mt-5'>
                                                        <div><i className="fas fa-box-open fs-40 c-sec"></i></div>
                                                        <div className='c-sec mt-2'>En estos momentos no hay datos disponibles</div>
                                                    </div>
                                            }
                                           
                                        </div>
                                    </div>
                                    <div className='card-general'>
                                        <h5 className='text-center'><i className="fas fa-chart-line fs-19"></i>  Ranking Cómo Vamos </h5>
                                        {
                                            typeof(dataDashboard['como_vamos']) !== 'string' ?
                                            <>
                                                <div className='mb-3'>
                                                    <h6 className='text-danger fw-700 fs-18'>Categoria {dataDashboard && dataDashboard['como_vamos'].store_category}</h6>
                                                </div>
                                                <div className='text-center m-auto'>
                                                <div className='text-capitalize flex-wrap ranking-general steps'>
                                                    <div className='text-capitalize'>
                                                        <div className='row' style={{maxWidth:'95%'}}>
                                                            <div className='col-lg-8 col-md-8 col-sm-12'>
                                                                <div><span className='fw-600 '>1. </span>{dataDashboard && dataDashboard.como_vamos && dataDashboard.como_vamos.ranking&& dataDashboard.como_vamos.ranking[0].rank_1.toLowerCase() }</div><span className='fs-13'></span>
                                                            </div>
                                                            <div className='col-lg-4 col-md-4 col-sm-12'>
                                                                <Progress className="dash-saler store" percent={dataDashboard && dataDashboard.como_vamos && dataDashboard.como_vamos.ranking&& dataDashboard.como_vamos.ranking[0].percentage } status="active" style={{width:'95px'}} /> 
                                                            </div>
                                                        </div>
                                                        <div className='row my-3'  style={{maxWidth:'95%'}}>
                                                            <div className='col-lg-8 col-md-8 col-sm-12'>
                                                                <div>
                                                                    <span className='fw-600'>2. </span>
                                                                    {dataDashboard && dataDashboard.como_vamos && dataDashboard.como_vamos.ranking&& dataDashboard.como_vamos.ranking[1].rank_2.toLowerCase() }
                                                                </div><span className='fs-13'></span>
                                                            </div>
                                                            <div className='col-lg-4 col-md-4 col-sm-12'>
                                                                <Progress className="dash-saler store" percent={dataDashboard && dataDashboard.como_vamos && dataDashboard.como_vamos.ranking&& dataDashboard.como_vamos.ranking[1].percentage } status="active" style={{width:'100px'}} />
                                                            </div>
                                                        </div>
                                                        <div className='row my-3'  style={{maxWidth:'95%'}}>
                                                            <div className='col-lg-8 col-md-8 col-sm-12'>
                                                                <div>
                                                                    <span className='fw-600'>3. </span>
                                                                    {dataDashboard && dataDashboard.como_vamos && dataDashboard.como_vamos.ranking&& dataDashboard.como_vamos.ranking[2].rank_3.toLowerCase() }
                                                                </div><span className='fs-13'></span>
                                                            </div>
                                                            <div className='col-lg-4 col-md-4 col-sm-12'>
                                                                <Progress className="dash-saler store" percent={dataDashboard && dataDashboard.como_vamos && dataDashboard.como_vamos.ranking&& dataDashboard.como_vamos.ranking[2].percentage } status="active" style={{width:'100px'}} />
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <div className='mx-auto d-block mt-5'>
                                                <div><i className="fas fa-box-open fs-40 c-sec"></i></div>
                                                <div className='c-sec mt-2'>En estos momentos el presupuesto no está disponible</div>
                                            </div>
                                        }
                                        
                                        
                                    </div>
                                </div>
                                {/*  //////////////// end statistics how are we doing ///////////////////////*/}
                                
                                {/* ///////////// init compliance statistics */}
                                <div className='d-flex flex-wrap dash1 justify-content-between wrapper mt-4'>
                                    <div className='dash1 card-general'>
                                        <div className='my-3'>
                                            <h5>Cumplimiento <span className='text-capitalize'>{dataDashboard && dataDashboard.store.toLowerCase()}</span></h5>
                                        </div>
                                        {
                                           data.length !== 0 ?
                                            <>
                                                <div className='my-4'>
                                                    <Select
                                                        defaultValue="mes"
                                                        className='align'
                                                        style={{ width: 200 }}
                                                        onChange={handleChange}
                                                        options={[
                                                            { value: 'mes', label: 'Mes actual' },
                                                            { value: 'trimester', label: 'Trimestre' },
                                                            { value: 'anio', label: 'Año' },
                                                        ]}
                                                    />
                                                </div>
                                                { dataDashboard &&
                                                    <Line {...config} />
                                                }
                                            </>
                                            : 
                                            <div className='mx-auto d-block mt-5'>
                                                <div><i className="fas fa-box-open fs-40 c-sec mt-4 pt-3"></i></div>
                                                <div className='c-sec mt-2'>En estos momentos el presupuesto no está disponible</div>
                                            </div>
                                        }
                                                
                                        
                                    </div>
                                    <div className='dash1 card-general'>
                                        { typeof(dataDashboard.sellers_compliance) !== 'string' &&
                                            <>
                                            {
                                                typeCompliance === 'day' ? 
                                                    <div
                                                        className='btn-info2 fit-content ml-auto px-2' 
                                                        onClick={()=>{setTypeCompliance('month')}}
                                                    >
                                                        Ver por mes
                                                    </div>
                                                :
                                                    <div
                                                        className='btn-info2 fit-content ml-auto px-2' 
                                                        onClick={()=>{setTypeCompliance('day')}}
                                                    >
                                                        Ver por día
                                                    </div>
                                            }
                                            </>

                                        }
                                                
                                                    
                                        <div className='mt-2'>
                                            <h5>{ typeCompliance === 'day' ? 'Cumplimiento diario' : 'Cumplimiento mensual' } </h5>
                                            <span className='text-capitalize'>{ typeCompliance === 'day' ? moment().format('L') : moment().format("MMMM") } </span>
                                            <div className='ocultar-movil'>
                                                {   (dataDashboard && dataDashboard.sellers_compliance && typeof(dataDashboard.sellers_compliance) !== 'string')
                                                    ?
                                                        <DataTableComp
                                                        columns={columns} 
                                                        data={dataDashboard && dataDashboard.sellers_compliance}
                                                        paginationComponentOptions={paginationComponentOptions}
                                                        paginationPerPage={5}
                                                        paginationRowsPerPageOptions={[5,10, 15]}
                                                    />
                                                    :
                                                        <div className='mx-auto d-block mt-5'>
                                                                <div><i className="fas fa-coins fs-50 c-sec pt-2"></i></div>
                                                                <div className='c-sec mt-2'>En estos momentos el presupuesto no está disponible</div>
                                                        </div>
                                                }
                                            </div>
                                            <div className='mostrar-movil'>
                                                {
                                                    (dataDashboard && dataDashboard.sellers_compliance&& typeof(dataDashboard.sellers_compliance) !== 'string')
                                                    ?
                                                        <DataTableComp  
                                                            columns={columnMovil} 
                                                            data={dataDashboard && dataDashboard.sellers_compliance}
                                                            paginationComponentOptions={paginationComponentOptions}
                                                            paginationPerPage={5}
                                                            paginationRowsPerPageOptions={[5,10, 15]}
                                                        />
                                                    :
                                                        <div className='mx-auto d-block mt-5'>
                                                            <div><i className="fas fa-coins fs-50 c-sec pt-3"></i></div>
                                                            <div className='c-sec mt-2 mb-4'>En estos momentos el presupuesto no está disponible</div>
                                                        </div>
                                                }
                                            </div>
                                        </div>   
                                    </div>
                                </div>
                                
                                {/* ///////////// end compliance statistics */}
                        
                            {/*  //////////ENF DESKTOP ////////*/}

                            {/* //////////// END MOVIL ////// */}
                            </>
                            : 
                            ( viewReady && userType !== 'tienda' &&
                                <>
                                    <div className='ocultar-movil'>
                                        <div className='card-general'>
                                            <h5>Accesos rápidos</h5>
                                            <hr/>
                                            <div className='text-left quick-access'>
                                                <Link className="link-formater text-decoration-n fs-18 my-3 text-danger" to='/tareas'><i className="fas fa-tasks fs-18"></i> Tareas</Link>
                                                <Link className="link-formater text-decoration-n fs-18 my-3 text-danger" to='/formularios'><i className="fas fa-check-square fs-18"></i> Formularios</Link>
                                                <a className="link-formater text-decoration-n fs-18 my-3 text-danger" href={`${process.env.REACT_APP_BACKEND_URL}/tiendas/comovamos`}><i className="fas fa-chart-bar fs-18"></i> Cómo vamos</a>
                                            </div>
                                        </div>
                                        <div className='card-general mt-5'>
                                            <h5 className='text-left'>Últimas noticias</h5>
                                            <hr/>
                                            <div className='d-flex mt-5  justify-content-around'>
                                                {listNews && listNews.map((n, index)=>(

                                                    <div className="card" key={index} style={{width: '18rem', height:'18rem' }}>
                                                        <a href={`${process.env.REACT_APP_BACKEND_URL+n.adjunto}`} className='link-news'>
                                                            <img src={`${process.env.REACT_APP_BACKEND_URL+n.cover}`} className="card-img-top object-fit-cover" alt="" height={150} />
                                                            <div className="mt-3 p-2">
                                                                <h6 className="card-title text-danger text-capitalize fonts-20">{n.titulo.toLowerCase()}</h6>
                                                                <p className="card-text text-secondary descripcion-news fs-16">{n.descripcion}</p>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    ))
                                                }
                                            </div>
                                            <div className='mb-5'>
                                                <span className='btn-general-accept'>
                                                    <a href={`${process.env.REACT_APP_BACKEND_URL}/noticias`}>Ver más</a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='mostrar-movil'>
                                        <div className='card-general w-100'>
                                            <h5>Accesos rápidos</h5>
                                            <hr/>
                                            <div className='text-left quick-access'>
                                                <Link className="link-formater fs-18 my-3" to='/tareas'><i className="fas fa-tasks fs-18"></i> Tareas</Link>
                                                <Link className="link-formater fs-18 my-3" to='/formularios'><i className="fas fa-check-square fs-18"></i> Formularios</Link>
                                                <a className="link-formater fs-18 my-3" href={`${process.env.REACT_APP_BACKEND_URL}/tiendas/comovamos`}><i className="fas fa-chart-bar fs-18"></i> Cómo vamos</a>
                                            </div>
                                        </div>
                                        <div className='mt-5'>
                                            {/* //////////////// ACCORDEON //////// */}
                                            <div className="accordion" id="accordionExample">
                                                <div className="card">
                                                    <div className="p-2" id="headingOne">
                                                    <h5 className="mb-0">
                                                        <span className="text-left w-100 py-2" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                        Últimas noticias
                                                        </span>
                                                    </h5>
                                                    </div>

                                                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                                                        { listNews && 
                                                            listNews.map((n, indexn)=>(
                                                                <div className="" key={indexn}>
                                                                    <div className="p-3">
                                                                        <a href={`${process.env.REACT_APP_BACKEND_URL+n.adjunto}`} className='link-news'>
                                                                            <img src={`${process.env.REACT_APP_BACKEND_URL+n.cover}`} className="card-img-top img-fluid" alt="" />
                                                                            <div className="mt-3">
                                                                                <h5 className="card-title text-danger text-capitalize">{n.titulo.toLowerCase()}</h5>
                                                                                <p className="card-text text-secondary descripcion-news">{n.descripcion}</p>
                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                    <hr className='w-80'/>
                                                                </div>
                                                            ))
                                                            
                                                        }
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                            {/* END ACCORDEON */}
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    </>
                }
            </div>
          
        </div>
    )
}
export default Dashboard