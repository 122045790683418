import React, {useEffect, useState} from 'react';
import {TitleLayout} from '../base/TitleLayout.js'
import Call from '../../config/Call'
import {saveAs} from "file-saver";
import { decode } from '../../config/crypto'
import Swal from 'sweetalert2'
import Spinner from '../base/spiner.js';
import { Tooltip, Space, DatePicker, ConfigProvider } from 'antd';
import es_ES from 'antd/es/locale/es_ES';
import ViewResponse from '../formularios/viewResponse'
import $ from 'jquery';
import moment from 'moment';
import axios from 'axios';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

const { RangePicker } = DatePicker;




const ListFormularios =(props)=>{

    let datat = { titulo: "Formularios"}
    const [sp, setSp]= useState(false) // MOSTRAR/OCULTAR LOADER
    // const [lForm, setLForm] = useState([]) //LISTADO FORMULARIOS
    const [lCategory, setlcategory ] = useState([]) //LISTADO CATEGORIAS
    const [datosForm, setDatosForm] = useState()
    const [selectCategoria, setselectCategoria] = useState('administrados')
    const [searchText, setSearchText] = useState('')
    const [time, setTime] =useState('')
    const [showAnswer, setShowAnswer ] = useState(false)
    let [ detalle, setDetalle ] = useState([])
    const [ showModalReport, setShowModalReport ] = useState(false)
    const [ messageError, setMessageError] = useState(false)
    const [ errorDays, setErrorDays ] = useState(false)
    const [ exportData, setExportData]= useState({
        id: '',
        nombre:'',
        id_report:'',
        count_reply:'',
        type: ''
    })
    const [ filtros, setFiltros ]= useState({
        fecha:null
    })
    
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();    

    useEffect(()=>{
       listForm()    
    
    }, []) 

    useEffect(()=>{
       
     }, [detalle])

    const listForm = async() =>{
        setSp(true)
        const res = await Call('GET', '/api/formularios/', true, null, false)
        
        let arrayCategory =[]
        
        
        let nuevoarray=[]
        for(let i in res.data.data){
            
            let h= res.data.data[i]
            nuevoarray.push({
                categoria:i,
                datos:h
            })
        }
        
        let arrform=[]
        let a= {}
        // let form=[]
        for(let u=0; u<nuevoarray.length; u++){
            
            for(let w=0; w<nuevoarray[u].datos.length; w++ ){
                
                for(let t in nuevoarray[u].datos[w]){
                         a={
                        id:  t.replace(/ /g, "-").toLocaleLowerCase(),
                        nombre: t, 
                        formularios: nuevoarray[u].datos[w][t]
                    }  
                    arrform.push({
                        categoria: nuevoarray[u].categoria,
                        datos: [a]
                    }) 
                }              
            }
        }
        
        for(let i in res.data.data){
            
            arrayCategory.push({
                categoria: i,
            
            })
        }
        setlcategory(arrayCategory)
        setDatosForm(nuevoarray)
        setSp(false)
    }
    
    const verForm=(id)=>{
        props.history.push('/formularios/'+id)
    }
    const addFavorites = async(id)=>{
        setSp(true)
        try {
            const res= await Call('PUT','/api/formularios/favoritos/'+id, true, null, false)
            if(res.data.code === 1){
                listForm()
                
            }else{
                alert('hubo un error')
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: '',
                text: error,
                confirmButtonText:'Aceptar',
            })
        }
        
       
    }
    const exportarFile= async(id, nombre, id_report, count_reply, type)=>{
        setSp(true)
        //1- download answer
        //2 - download task report
            setMessageError(false)
            if (id_report===1){
                // console.log('entro1')
                let token= decode(localStorage.getItem('authInfo')).token
                let url=process.env.REACT_APP_BACKEND_URL+'/api/formularios/respuesta/'+id+(type ? ('?fecha_inicial='+filtros.fecha[0]+'&fecha_final='+filtros.fecha[1]+'&'):'?')+'export=xls'
                const timeout = 60000; // 1 minuto
                let res = null
                let res2 = null
                const verificar =()=>{
                    const timeoutId = setTimeout(async() => {
                    
                        if(res === null){
                            source.cancel('La solicitud excedió el tiempo límite de espera');
                            // console.log('respuesta nula')
                        try {
                            // console.log('aca2')
                            res2 = await axios.get(`api/formularios/respuesta/${id}?fecha_inicial=${filtros.fecha[0]}&fecha_final=${filtros.fecha[1]}&export=xls`, {
                                cancelToken: source.token
                            });
                            clearTimeout(timeoutId);
                            // Manejar la respuesta exitosa aquí
                            } catch (error) {
                                if (axios.isCancel(error)) {
                                
                                setSp(false)
                                alert('La solicitud fue cancelada')
                                } else {
                                    console.log('Ocurrió un error', error.message);
                                }
                            }
                        }
                    }, timeout);
                }
                verificar()
                
                try {
                    res= await Call('GET','api/formularios/respuesta/'+id+(type ? ('?fecha_inicial='+filtros.fecha[0]+'&fecha_final='+filtros.fecha[1]+'&'): '?')+'export=xls', true, null, false, 60000 )            
                    if(res.data.code === 2){
                            Swal.fire({
                                icon: 'info',
                                title: '',
                                text: res.data.message,
                                confirmButtonText:'Aceptar',
                            })
                            setSp(false)
                           }else{
                                fetch(url, {
                                    method: 'GET',
                                    headers: {
                                        'Authorization': `Bearer ${token}`
                                    },
                                })
                                .then(function (response) {
                                        return response.blob();
                                    }
                                )
                                .then(function(blob) {
                                    saveAs(blob, `respuestas${nombre}.xlsx`);
                                })
                                .then(function(blob) {
                                    setSp(false)
                                })
                                .catch(error => {
                                    //whatever
                                })
                            }
                    
                } catch (error) {
                    console.log(error)
                }
                
                // HIDDING MODAL WHEN THERE IS ONE ANSWER
                // if(parseInt(count_reply) === 1){
                //     const res2= await Call('GET','api/formularios/respuesta/'+id, true, null, false )
                //     setDetalle(res2.data.data)
                //     setShowAnswer(true)
                // }else{
                //   
                        
                // }
                // }
                setShowModalReport(false)
                setFiltros({fecha:null})
            }else if (id_report === 2) {
                setSp(true)
                let token = decode(localStorage.getItem('authInfo')).token;
                let url = `${process.env.REACT_APP_BACKEND_URL}/api/formularios/reporte/trazabilidad/tareas/${id}${type ? ('?fecha_inicial=' + filtros.fecha[0] + '&fecha_final=' + filtros.fecha[1] + '&') : '?'}export=xls`;
    
                try {
                    
                    const res2 = await Call('GET', url, true, null, false);
                    
                    if (res2.data.code === 2) {
                        setSp(false)
                        setFiltros({fecha:null})
                        Swal.fire({
                            icon: 'info',
                            title: '',
                            text: 'No hay respuestas en este formulario',
                            confirmButtonText: 'Aceptar',
                        });
                    } else {
                        setSp(true)

                        fetch(url, {
                            method: 'GET',
                            headers: {
                                'Authorization': `Bearer ${token}`
                            },
                        })
                        .then(response => response.blob())
                        .then(blob => {
                            saveAs(blob, `respuestas${nombre}.xlsx`);
                        })
                        .then(blob => {
                            setSp(false)
                        })
                        .catch(error => {
                            // Gestiona el error aquí si lo deseas
                        });
                        
                    }
                    setShowModalReport(false);
                   
                    setFiltros({fecha:null})
                } catch (error) {
                    // Si se lanza un error durante la petición HTTP, puedes gestionarlo aquí.
                    console.error("Ocurrió un error al obtener los datos:", error)
                }
            }
        }
    
    //BUSQUEDAS
    const set = async (e) =>{
        setSearchText(e.target.value)
          if(e.target.value === ''){
                listForm()
                setSp(false)
          }
        
          clearTimeout(time) 
          setTime(setTimeout(async() => 
          {
            setSp(true)
            let res=  await Call('GET','/api/formularios/?search='+e.target.value, true, null, false)
            
            let arrayCategory =[]            
            let nuevoarray=[]
            for(let i in res.data.data){
                
                let h= res.data.data[i]
                nuevoarray.push({
                    categoria:i,
                    datos:h
                })
            }
            
            let arrform=[]
            let a= {}
            // let form=[]
            for(let u=0; u<nuevoarray.length; u++){
                
                for(let w=0; w<nuevoarray[u].datos.length; w++ ){
                    
                    for(let t in nuevoarray[u].datos[w]){
                             a={
                            id:  t.replace(/ /g, "-").toLocaleLowerCase(),
                            nombre: t, 
                            formularios: nuevoarray[u].datos[w][t]
                        }  
                        arrform.push({
                            categoria: nuevoarray[u].categoria,
                            datos: [a]
                        }) 
                    }         
                }
            }
            for(let i in res.data.data){
                arrayCategory.push({
                    categoria: i,
                })
            }
            setlcategory(arrayCategory)
            setDatosForm(nuevoarray)
            setSp(false)
            
          }, 800))   
          
        
      }
      const modalDetail =()=>{
        $('#staticBackdrop').modal('hide')
        setShowAnswer(false)
      }
    const validations =(id, nombre, id_report, count_reply, respuestas_usuario_ilimitadas)=>{
        if(filtros.fecha !== null && filtros.fecha[0]!=="" && filtros.fecha[1] !== "" && !errorDays){
            exportarFile(id, nombre, id_report, count_reply, respuestas_usuario_ilimitadas)
        }else{
            setSp(false)
            if(!errorDays){
                setMessageError(true)
            }
        }   
    }
    const modalReport = (id, nombre, id_report, count_reply, respuestas_usuario_ilimitadas)=>{
        setShowModalReport(true)
        setExportData({
            id: id,
            nombre:nombre,
            id_report:id_report,
            count_reply:count_reply,
            type: respuestas_usuario_ilimitadas
        })
    }
    const handleDate =(value, mode)=>{
       
            setFiltros({
                ...filtros,
                fecha: mode,
            })
            setMessageError(false)
            var fecha1 = moment(mode[0]);
            var fecha2 = moment(mode[1]);
            let numberDays= fecha2.diff(fecha1, 'days')
            if(numberDays > 180){
                setErrorDays(true)
                filtros.fecha=null
                setFiltros({
                    ...filtros,
                    fecha: null,
                })
            }else{
                setErrorDays(false)
            }
            
    }
    return(
        <>
        { sp ? <Spinner /> : ''}  {/*LOADER*/}
        {
            showAnswer ? 
                <ViewResponse modalDetail={modalDetail} display={'block'} datosForm={detalle && detalle[0]} respuestas_formulario={detalle && detalle[0]} />
            : ''
        }
        {
            showModalReport &&
            <>
            <div className='modal show' tabIndex="-1" style={{display:'block'}}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header py-2">
                            <span className="fs-18 fw-600">Reporte</span>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={()=>{setShowModalReport(false); setMessageError(false); setFiltros({fecha:null}); setErrorDays(false)}}>
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body text-center">
                            <span className='m-3 pb-3'>Selecciona las fechas que deseas visualizar en el reporte, con un mínimo de 15 días y un máximo de 6 meses.</span>
                            <div className='py-3'>
                                <ConfigProvider locale={es_ES}>
                                    <RangePicker
                                         name='fecha'
                                         allowClear
                                         showToday
                                         onChange={(value, mode)=>{handleDate(value, mode)}}
                                         defaultValue={((filtros.fecha&& filtros.fecha.length>0) ? [dayjs(filtros.fecha[0]), dayjs(filtros.fecha[1])]: undefined)}
                                        // format={dateFormat}
                                    />
                                </ConfigProvider>
                            </div>
                            {messageError && <span className='text-danger fs-11'>Por favor escoge una fecha</span>}
                            {errorDays && <span className='text-danger fs-11'>La fecha escogida excede los 6 meses permitidos, escoge otra fecha</span> }
                            <div className='py-3'>
                                <span className='mx-2 fs-16 btn-info2' onClick={()=>{validations(exportData.id, exportData.nombre, exportData.id_report, exportData.count_reply, exportData.type)}}>Exportar</span>
                            </div>
                        </div>
                    </div>       
                </div>
            </div>
            <div className="modal-backdrop fade show"></div> 
            </>
        }
        <div className='bg-form'>
            <TitleLayout data={datat} clase={"text-left"} />
            
            <div className='bg-white p-4 bg-form' style={{minHeight:'80vh', overflow:'auto'}}>
            
                <div className='mb-3'>
                <Tooltip color={'#aba7a7c2'} placement="rightTop" title="Administrados: Formularios he creado &#10;
                    Pendientes: Formularios que debo responder
                    Disponibles: Solicitudes a enviar"> <i className="fas fa-info-circle fs-15 c-sec"></i></Tooltip>
                </div>
                <div className='mb-3'>
                    <input type="text" className='form-control form-control-sm input-buscar-listform' placeholder="&#xF002;  Buscar" onChange={set} value={searchText} />
                </div>
                {/* ********TABS***** */}
                <ul className="nav nav-tabs nav-tabs-form" id="myTab" role="tablist">
                    {
                         lCategory && lCategory.map((lc, indexlc)=>(
                            <li className={`tab-form ${selectCategoria === lc.categoria ? 'selected' : ''}`} role="presentation" key={indexlc} onClick={()=>{setselectCategoria(lc.categoria)}}>
                                <a className={`nav-link active text-capitalize`} id="home-tab" data-toggle="tab" href={"#"+lc.categoria} role="tab" aria-controls="home" aria-selected="true">{lc.categoria}</a>
                            </li>
                         ))
                    }                  
                </ul>
                <div className="tab-content" id="myTabContent">
                    {
                        lCategory && lCategory.map((lc, indexlc)=>(
                            <div className={`tab-pane fade show ${selectCategoria === lc.categoria ? 'active' : '' }`} id={lc.categoria} role="tabpanel" aria-labelledby="home-tab" key={indexlc}>
                                {datosForm&& datosForm.find(w=>(w.categoria ===lc.categoria && w.datos.length> 1)) ? 
                                    // {
                                        datosForm &&
                                        datosForm.map((a, indexa)=>(
                                        lc.categoria === a.categoria &&

                                        <div className="accordion" id={"accordionExample"+a.categoria} key={indexlc}>
                                            <div id={indexa}>
                                                <div className="p-2">
                                                    { a.datos.map((d, indexd)=>{
                                                        
                                                        let nombre= Object.keys(d)
                                                        let nombre2= nombre.toString()
                                                        let f = d[Object.keys(d)] 
                                                        return(
                                                    
                                                        <>
                                                        {  (f.length > 0 && a.datos.length) ?
                                                            <>
                                                            <div key={indexd} className="accordion-header" id="headingOne"  type="button" data-toggle="collapse" data-target={"#"+nombre2.replace(/ /g, "-").toLocaleLowerCase()+"-"+a.categoria} aria-expanded="false" aria-controls="collapseOne">
                                                                <h2 className="mb-0">
                                                                    <span className="btn btn-block text-left form-accordeon" >
                                                                        {   
                                                                            Object.keys(d).toString().charAt(0).toUpperCase() +Object.keys(d).toString().slice(1).toLowerCase() 
                                                                        }
                                                                       
                                                                    </span>
                                                                </h2>
                                                            </div>
                                                            <div id={nombre2.replace(/ /g, "-").toLocaleLowerCase()+"-"+a.categoria} className={`collapse`} aria-labelledby="headingOne" data-parent={"#accordionExample"+a.categoria}>
                                                                <div className="card-body d-flex flex-wrap px-0">
                                                                    { 
                                                                    f.map((n, indxn)=>(
                                                                        <div className='card m-2' key={indxn} style={{maxWidth:'300px'}}>
                                                                            {!n.respuestas_usuario_ilimitadas && n.is_reply ?
                                                                                <div className='ribbon'></div>
                                                                                :
                                                                                ''
                                                                            }
                                                                            <div className='favoritos'>{n.is_fav ? <i className="fas fa-heart fs-18 cursor-pointer" style={{color:'#fe9393',}} onClick={()=>{addFavorites(n.id)}}></i>: <i className="far fa-heart fs-18 cursor-pointer" style={{color:'#5255589e',}} onClick={()=>{addFavorites(n.id)}}></i>}</div>
                                                                                <img src="assets/images/form.jpg" className="" alt="..."/>
                                                                                <div className='p-2 title-form'>
                                                                                    <div className="card-title t-form"> {n.titulo}</div>
                                                                                    { (a.categoria === 'disponibles' ||  a.categoria === 'pendientes') &&
                                                                                        <div className='text'>Responsable: <span className='text-capitalize'>{n.usuario_creador && n.usuario_creador.apellidos_nombres.toLowerCase() }</span></div>
                                                                                    }
                                                                                    {
                                                                                        a.categoria === 'administrados' &&
                                                                                        <div>
                                                                                           { n.count_reply}{n.respuestas_usuario_ilimitadas ? '/' : '' }{n.respuestas_usuario_ilimitadas ? <i className="fas fa-infinity"></i> : '' }
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                                <div className='btn-cards-form pb-3'>
                                                                                    {!n.respuestas_usuario_ilimitadas && n.is_reply ?
                                                                                        ''
                                                                                        :
                                                                                        <div className='px-3  btn-outline-bj bj-form fs-12 my-3' onClick={()=>verForm(n.id)}>Responder</div>

                                                                                    }
                                                                                    
                                                                                    {
                                                                                        (a.categoria !== 'administrados' && !n.respuestas_usuario_ilimitadas) ?
                                                                                        <div className='px-3 cursor-pointer bj-form b-radius3 btn-info-guardar p-0  my-3' 
                                                                                            onClick={()=>exportarFile(n.id, n.titulo,1, n.count_reply, n.respuestas_usuario_ilimitadas)}>
                                                                                            Ver respuestas
                                                                                        </div>
                                                                                        :
                                                                                        ((a.categoria !== 'administrados' && n.respuestas_usuario_ilimitadas) ?
                                                                                        <div className='px-3 cursor-pointer bj-form b-radius3 btn-info-guardar p-0  my-3' 
                                                                                            onClick={()=>modalReport(n.id, n.titulo,1, n.count_reply, n.respuestas_usuario_ilimitadas)}>
                                                                                            Ver respuestas
                                                                                        </div>: '')
                                                                                    }
                                                                                    {
                                                                                        a.categoria === 'administrados' && !n.respuestas_usuario_ilimitadas && n.is_reply?
                                                                                        <Space>
                                                                                            <div className='btn btn-info-guardar' 
                                                                                                onClick={()=>exportarFile(n.id, n.titulo,1, n.count_reply, n.respuestas_usuario_ilimitadas)}
                                                                                            >
                                                                                                Ver respuestas
                                                                                            </div>
                                                                                            <div className='btn btn-info-guardar' 
                                                                                                onClick={()=>exportarFile(n.id, n.titulo,2,n.count_reply, n.respuestas_usuario_ilimitadas)}
                                                                                            >
                                                                                                Ver reporte de tareas
                                                                                            </div>
                                                                                    </Space>
                                                                                    :
                                                                                        a.categoria === 'administrados'?
                                                                                    <Space>
                                                                                        <div className="dropdown d-inline mr-2 show">
                                                                                            <button className="btn btn-info-guardar dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false">
                                                                                               Acciones
                                                                                            </button>
                                                                                            <span className="dropdown-menu" x-placement="bottom-start" >
                                                                                                {   n.respuestas_usuario_ilimitadas ?
                                                                                                        <>
                                                                                                            <a className="dropdown-item" 
                                                                                                            onClick={()=>modalReport(n.id, n.titulo, 1,  n.count_reply, n.respuestas_usuario_ilimitadas)}
                                                                                                            >
                                                                                                                Ver respuestas
                                                                                                            </a>
                                                                                                            <a className="dropdown-item" 
                                                                                                                onClick={()=>modalReport(n.id, n.titulo, 2, n.count_reply,n.respuestas_usuario_ilimitadas)}>
                                                                                                                    Ver reporte de tareas
                                                                                                            </a>
                                                                                                        </>
                                                                                                    :
                                                                                                        <>
                                                                                                        <a className="dropdown-item" 
                                                                                                        onClick={()=>exportarFile(n.id, n.titulo, 1,  n.count_reply, n.respuestas_usuario_ilimitadas)}
                                                                                                        >
                                                                                                            Ver respuestas
                                                                                                        </a>
                                                                                                        <a className="dropdown-item" 
                                                                                                            onClick={()=>exportarFile(n.id, n.titulo, 2, n.count_reply,n.respuestas_usuario_ilimitadas)}>
                                                                                                                Ver reporte de tareas
                                                                                                        </a>
                                                                                                        </>

                                                                                                }
                                                                                                
                                                                                           </span>
                                                                                           </div>
                                                                                    </Space>
                                                                                    :''
                                                                                }        
                                                                            </div>
                                                                       </div>
                                                                    )) 
                                                                    }
                                                                </div>    
                                                            </div>
                                                            </>
                                                            :
                                                            ''
                                                    }
                                                            </>
                                                            )
                                                            
                                                    })
                                                    
                                                    }
                                                </div>
                                            </div>
                                            </div> 
                                        ))
                                    // }
                                
                                :
                                    <div className="ticket-empty-modal">
                                        <div><i className="fas fa-box-open fs-20"></i></div>
                                        <div className="text-empty">En este momento no hay nada para mostrar</div>
                                    </div>
                                }
                            </div>
                         ))   
                    }
                </div>                
            </div>
        </div>
        </>
    )
}
export default ListFormularios;